import React, { Component , Fragment} from 'react';
import Axios from 'axios';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Footer from './Footer';
import Header from './Header'
import { withCookies } from 'react-cookie';
import AppsIcon from '@mui/icons-material/Apps';

import CircularProgress from '@mui/material/CircularProgress';


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import Input from '@mui/material/Input';


import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

import serverURL from '../serverURL.json';



const styles = theme =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  HeadLine : {
    fontSize: 'calc(1vw + 1.5vh)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace : 'pre-wrap'
  },
  labelPlacementStart: {
    display: "flex",
    justifyContent: 'space-between',
  },
  input: {
    width: 150,
  },

});

const licenseTypes = [
  {
    value: 'Floating',
    label: 'Floating',
  },
  {
    value: 'Node-Locked',
    label: 'Node-Locked',
  },
  
];



class MyApp extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {

        user : cookies.get('user') ? cookies.get('user') : {_id: "", refToken :"", accToken:"", auth: false} ,
        appID : "",
        myAppList : null,

        copy : 1,
        useDay : 1,
        licenseType : "Floating",
        mac : "",
        note : "",

        // showDlgBuy : false,
        dlgBuy : false,
        
        dlgDownload : false,
        isAPI : false,
        launcher_mail : [],
        launcher_api : []


      }

    this.handleValueChange = this.handleValueChange.bind(this);

    this.dlgBuyEvent = this.dlgBuyEvent.bind(this);
    this.dlgBuyCancel = this.dlgBuyCancel.bind(this);

    this.dlgDownloadEvent = this.dlgDownloadEvent.bind(this);
    this.dlgDownloadCancel = this.dlgDownloadCancel.bind(this);

    this.copyNumChange = this.copyNumChange.bind(this);
    this.useDayChange = this.useDayChange.bind(this);
    this.licenseChange = this.licenseChange.bind(this);
    this.macChange = this.macChange.bind(this);
    this.noteChange = this.noteChange.bind(this);
    this.addMessenger = this.addMessenger.bind(this);
    
    this.clickLicense = this.clickLicense.bind(this);

    

    }

    clickLicense(){

        alert('Preparing service')
    }


    addMessenger(_appID){
      if(window.confirm("Would you like to ask a provider for a consultation?")){

        let params = new URLSearchParams();
        params.append('userID', this.state.user._id);
        params.append('refToken', this.state.user.refToken);
        params.append('accToken', this.state.user.accToken);
        params.append('appID', _appID);
        Axios.post(serverURL.api + '/addMessenger', params).then(
            response =>{
            if(response.status === 200)
            {
                const returnResult = response.data;
    
                if(returnResult.result)
                {
                  if(window.confirm("Your request for consultation has been registered. Would you like to go to Messenger?")){
                      window.location.href = '/Messenger';
                  } 
                }else
                {
                    alert(returnResult.alert)
                }
            }else
            {
                alert('engResponseStatusNot200');
            }
            }
        ).catch((error) =>{
                alert('Unexpected Access, Need to refresh page addAppViewCount');

            }
        );

      }
    }



    noteChange(e){
      this.setState({ note: e.target.value});
    }
    macChange(e){
      this.setState({ mac: e.target.value});
    }

    licenseChange(e){
      this.setState({ licenseType: e.target.value});
    }
    useDayChange(e) {
      let _useDay = e.target.value;
      if(_useDay<1){
        _useDay = 1;
    }
      _useDay = Math.floor(_useDay)
      this.setState({useDay : _useDay})

  }

    copyNumChange(e) {
        let _copyNum = e.target.value;
        if(_copyNum<1){
          _copyNum = 1;
            }
        _copyNum = Math.floor(_copyNum)
        this.setState({copy : _copyNum})

    }
   


    dlgBuyCancel(e)
    {
      if(e.currentTarget.name === 'agree'){


          let params = new URLSearchParams();
          params.append('userID', this.state.user._id);
          params.append('refToken', this.state.user.refToken);
          params.append('accToken', this.state.user.accToken);
          params.append('appID', this.state.appID);
          params.append('copy', this.state.copy);
          params.append('useDay', this.state.useDay);
          params.append('requestDate', (new Date()).toUTCString());
          params.append('licenseType',  this.state.licenseType);
          params.append('mac',  this.state.mac);
          params.append('note',  this.state.note);
          
          // console.log(this.state)
          Axios.post(serverURL.api + '/setBuyApp', params).then(
              response =>{

              if(response.status === 200)
              {
                  const returnResult = response.data;

                  if(returnResult.result)
                  {
                      alert("Your purchase application has been submitted.");
                      window.location.reload(true);
                  }else
                  {
                  alert(returnResult.alert)
                  }
              }else
              {
                  alert('engResponseStatusNot200');
              }
              }
          ).catch((error) =>{
                  alert('Unexpected Access, Need to refresh page setBuyApp');

              }
          );








       }

      this.setState({dlgBuy: false});
    }

    dlgBuyEvent(_appID)
    {
      this.setState({dlgBuy: true, appID:_appID});
    }


    dlgDownloadEvent(_appID, _isAPI, _launcher_mail, _launcher_api)
    {
      this.setState({dlgDownload: true,
        appID:_appID,
        isAPI : _isAPI,
        launcher_mail : _launcher_mail,
        launcher_api : _launcher_api
      
      });
    }

    dlgDownloadCancel()
    {
      this.setState({dlgDownload: false});
    }



    handleValueChange(e){
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    }

    componentWillMount(){
      let params = {};
      let key = 'params';
      let value = {
        userID : this.state.user._id,
      }
      params[key] = value;
      Axios.get(serverURL.api + '/getMyAppList', params).then(
      response =>{
          if(response.status === 200)
          {
          const returnResult = response.data;

          if(returnResult.result)
          {           

              this.setState({
                myAppList : returnResult.myAppList,
              });


          }else
          {
              alert(returnResult.alert)
          }
          }else
          {
          alert('engResponseStatusNot200');
          }
      }
      ).catch((error) =>{
      alert('Unexpected Access, Need to refresh page getAppInfo');
      }
      );

  }

  

   

  render(){
    const {classes} = this.props;
    // const imgKRCI = '/img/krCI.jpg';

    

    const dlgDownloadQuestion = (
      <Dialog
      open={this.state.dlgDownload}
      onClose={this.dlgDownloadCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">Download Appㅤㅤㅤㅤㅤㅤㅤㅤ</DialogTitle>
      <Divider/>

      <DialogContent>
      <Typography component="h6" variant="h6">
          ㅁ List
      </Typography>

      {
        this.state.isAPI
        ?
        this.state.launcher_api.map((dictLauncher,index)=>{
          return <Paper style={{margin : 10}}>
          <a href = {dictLauncher.file} style={{'color' : 'black', 'text-decoration' : 'none' }}>
          <Button>
            {dictLauncher.name}
          </Button>
          </a>
          </Paper>
          })
        :
        this.state.launcher_mail.map((dictLauncher,index)=>{
          return <Paper style={{margin : 10}}>
          <a href = {serverURL.api + '/getAppDownload?appID='+ this.state.appID + '&index=' + index} style={{'color' : 'black', 'text-decoration' : 'none' }}>
          <Button>
            {dictLauncher.name}
          </Button>
          </a>
          </Paper>
          })

      }





      </DialogContent>
      <DialogActions>
      <Button name= 'disagree' onClick={this.dlgDownloadCancel} color="primary">
         Close
          </Button>
      </DialogActions>
      </Dialog>
  );



    const dlgBuyQuestion = (
      <Dialog
      open={this.state.dlgBuy}
      onClose={this.dlgBuyCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">Purchase applicationㅤㅤㅤㅤㅤㅤㅤㅤ</DialogTitle>
      <Divider/>

      <DialogContent>
      {/* <FormControlLabel
          labelPlacement="start"
          classes = {{
              labelPlacementStart : classes.labelPlacementStart
          }}
          control={
              <TextField
              aria-readonly
              className={classes.input}
              value={this.state.point + " dep"}
              margin="dense"
          />
          }
          label={localString.yourPoint}
      /> */}
      <FormControlLabel
          labelPlacement="start"
          classes = {{
              labelPlacementStart : classes.labelPlacementStart
          }}
          control={
              <Input
              className={classes.input}
              value={this.state.useDay}
              // margin="dense"
              onChange={this.useDayChange }
              inputProps={{
              step: 1,
              min: 1,
              type: 'number',
              'aria-labelledby': 'input-slider',
              }}
          />
          }
          label= "Use Day"
      />
      <FormControlLabel
          labelPlacement="start"
          classes = {{
              labelPlacementStart : classes.labelPlacementStart
          }}
          control={
              <Input
              className={classes.input}
              value={this.state.copy}
              // margin="dense"
              onChange={this.copyNumChange }
              inputProps={{
                step: 1,
                min: 1,
                type: 'number',
                'aria-labelledby': 'input-slider',
                }}
          />
          }
          label= "Copy"
          
      />
      <FormControlLabel
          labelPlacement="start"
          classes = {{
              labelPlacementStart : classes.labelPlacementStart
          }}
          control={
            <TextField
            className={classes.input}
            id="standard-select-currency"
            select
            // label="Select"
            value={this.state.licenseType}
            onChange={this.licenseChange}
            // helperText="Please select your currency"
          >
            {licenseTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          }
          label= "License Type"
      />
      <FormControlLabel
          labelPlacement="start"
          classes = {{
              labelPlacementStart : classes.labelPlacementStart
          }}
          control={
              <Input
              className={classes.input}
              value={this.state.mac}
              // margin="dense"
              onChange={this.macChange }
          />
          }
          label= "Mac"
      />
      <FormControlLabel
          labelPlacement="start"
          classes = {{
              labelPlacementStart : classes.labelPlacementStart
          }}
          control={
              <Input
              className={classes.input}
              value={this.state.note}
              onChange={this.noteChange }
              // margin="dense"
          />
          }
          label= "Note"
      />
      </DialogContent>
      <DialogActions>
      <Button name= 'disagree' onClick={this.dlgBuyCancel} color="primary">
      Close
          </Button>
          <Button name ='agree' onClick={this.dlgBuyCancel} color="primary" autoFocus>
          Submit
          </Button>
      </DialogActions>
      </Dialog>
  );













    function App_Card(app , _user, _this){
    
      // function downlaodApp(){
      //   //   window.open(news.url)
      //   // document.location.href = '/App/' + app._id;

      //   let params = {};
      //   let key = 'params';
      //   let value = {
      //     appID : app._id,
      //   }
      //   params[key] = value;
      //   Axios.get('http://localhost:5000/api' + '/getAppDownload',
      //   params
      //   ).then(
      //   response =>{
      //       if(response.status === 200)
      //       {
      //         // console.log(response.data)
      //         const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      //         const link = document.createElement('a');
      //         link.href = url;
      //         link.setAttribute('download', app.name + '.jpg');
      //         // link.setAttribute('download');

      //         document.body.appendChild(link);
      //         link.click();
      //         // const returnResult = response.data;
    
      //         // if(returnResult.result)
      //         // {
                
    
    
      //         // }else
      //         // {
      //         //   alert(returnResult.alert)
      //         // }
      //       }else
      //       {
      //         alert('engResponseStatusNot200');
      //       }
      //   }
      //   ).catch((error) =>{
      //   alert('Unexpected Access, Need to refresh page getAppDownload');
      //   }
      //   );
      // }

      function alertApply(){

        alert('The purchase application has been completed. If you have any questions, please use Messenger.')
    }

    function goAppInfo(){
        document.location.href = '/App/' + app._id;

    }

    function goAppWeb(){
      window.open(app.webURL)

  }

      return (
        <Grid item xs={12}>
          <Card >
            <CardActionArea >
            <CardHeader
            onClick ={goAppInfo}
            avatar={
                <Avatar style={{ height: '35px', width: '35px' }} src={app.icon} />
            }

            title= {app.name}
            subheader= {app.company}
            action={
                <Fragment>
                    <IconButton aria-label="settings" size="large">
                    <img src= {app.companyCI} alt="Logo"   width = "50" height = "23.2"/>
                    </IconButton>
                </Fragment>
                }
            />
            <CardActions disableSpacing>
            <Button name = 'addApp' variant="contained" color= "primary" onClick = {()=>_this.addMessenger(app._id)} style={{margin : 5}}>
                Contact
              </Button>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: "auto",}}>
                  {
                    app.isOnpremise
                    ?
                    app.status === 0 //구매
                    ?
                    <Button variant="contained" color="secondary" style = {{margin : 5}} onClick ={()=>_this.dlgBuyEvent(app._id)}>
                      Buy
                    </Button>
                    :
                    app.status === 1 // 신청중
                    ?
                    <Button variant="contained" style = {{margin : 5}} onClick ={alertApply}>
                      Applying
                    </Button>
                    :
                    app.status === 2 // 라이선스
                    ?
                    <div>

                    <Button variant="outlined" color="primary" style = {{margin : 5}} onClick ={() => _this.dlgDownloadEvent(app._id, app.isAPI, app.launcher_mail, app.launcher_api ) }>
                      {/* <a href = {serverURL.api + '/getAppDownload?appID='+ app._id} style={{'color' : 'black', 'text-decoration' : 'none' }}> */}
                      Download
                      {/* </a> */}
                    </Button>
                  <Button variant="contained" color="primary" style = {{margin : 5}} onClick ={_this.clickLicense}>
                    License
                    {/* <a href = {'http://localhost:5000/api/getAppDownload?appID='+ app._id + '&userID=' +_user._id
                     + '&refToken=' +_user.refToken + '&accToken=' +_user.accToken
                  } style={{'color' : 'white', 'text-decoration' : 'none' }}>
                    License
                    </a> */}
                  </Button>
                  </div>
                    :
                    null
                    :
                    <Button variant="outlined" color="primary" style = {{margin : 5}} onClick ={goAppWeb}>
                     Service Web
                    </Button>
                  }
                </div>
              </CardActions>
            </CardActionArea>
          
            </Card>
            <br/>
        </Grid>
      );
  
    };






  return (
      <div>
    <Header user = {this.state.user}/>
    <br/>
    <Container component="main">

        <div style={{width:'100%'}} align = 'center'  >
            <span className = {classes.HeadLine} >
                <AppsIcon color = "primary" fontSize = "large"/>My App
            </span>
            <span >
            You can download app and check the status of the app here.
            </span>
            <br/>
            <br/>
        </div>
            {
              this.state.myAppList === null
              ?
            <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
                  <CircularProgress />
                </div>
              :
              
              this.state.myAppList.length !== 0 
              ?
          <Grid container spacing={0}>
              {this.state.myAppList.map(app=>{
                return App_Card(app, this.state.user, this);
                })}
          </Grid>

              :
              <span >
                No List
              </span>
            }



    </Container>
    <Footer/>
    {dlgBuyQuestion}
    {dlgDownloadQuestion}
    </div>
  );
}

}

export default withStyles(styles)(withCookies(MyApp));
