import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import HomePage from '../View/Homepage';
import Signin from '../View/Signin';
import SignUpParent from'../View/SignUp/SignUpParent';
import ContactSupport from '../View/ContactSupport';
import Store from '../View/Store';
import AppInfo from '../View/AppInfo';
import ShowArticle from '../View/ShowArticle';
import MyApp from '../View/MyApp';
// import Messenger from '../View/Messenger/App';
import Messenger from '../View/Messenger';
import UserSetting from '../View/UserSetting';
import AppManagement from '../View/AppManagement';
import SeaTrustManagement from '../View/Management/SeaTrustManagement';
import userTerm1 from '../View/SignUp/Terms/userTerms/userTerm1';
import privacyTerm1 from '../View/SignUp/Terms/privacyTerms/privacyTerm1'
import SignUp from '../View/SignUp/Signup';
import ConfirmationCode from '../View/SignUp/ComfirmationCode';
import FindPassword from '../View/FindPassword'
class ViewRouter extends Component{
  render(){
    return (
        <div>
        <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/Signin" component={Signin} />
          <Route exact path="/SignUp" component={SignUpParent} />
          <Route exact path= "/SignUp/profile" component = {SignUp}/>
          <Route exact path="/ContactSupport" component={ContactSupport} />
          <Route exact path="/Store" component={Store} />
          <Route exact path="/App/:_appID/" component={AppInfo}/>
          <Route exact path="/MyApp" component={MyApp} />
          <Route exact path="/Messenger" component={Messenger} />
          <Route exact path="/UserSetting" component={UserSetting} />
          <Route exact path="/AppManagement" component={AppManagement} />
          <Route exact path="/SeaTrustManagement" component={SeaTrustManagement} />
          <Route exact path="/Article/:_articleID/" component={ShowArticle}/>
          <Route exact path = "/policy/terms-of-service" component = {userTerm1}/>
          <Route exact path = "/policy/terms-privacy" component = {privacyTerm1}/>
          <Route exact path = "/signUp/Auth/:userId" component = {ConfirmationCode}/>
          <Route exact path = "/findPassword" component = {FindPassword}/>

          {/* <Route component={NotFoundPage} /> */}
        </Switch> 
        </Router>
       </div>
    );
  }
}

export default ViewRouter;
