import React from 'react'
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {makeStyles} from '@mui/styles';
import { Button, Divider, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import TermDetail from './TermDetail';
import List from '@mui/material/List';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const useStyles = makeStyles((theme)=>({ 
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(8) 
    },
    checkLabel: {
        marginTop: theme.spacing(5) 
    },
    submit: {
        margin: theme.spacing(0, 0, 0),
    },
    rightArrow: { 
        float: 'right'
    },
    FormControlLabel: { 
        width: '80%',
        height: '60px' ,
    },
    agreeAll: { 
        fontSize: '15px',
    },
    err:{ 
        color: 'red'
    }
}));

function Terms(props) {
    const { ChangeView,terms, setTerms} = props;
    const classes = useStyles();

    const [agreeAll, setAgreeAll] = React.useState(false);
    const [isErr, setIsErr] = React.useState(false); 

    const handleAgreeAll = (e) =>{ 

        let termCopied = [...terms];       
        termCopied.forEach(term=>{
            term.checked = e.target.checked;
        });
        setTerms(termCopied)
        setAgreeAll(e.target.checked); 
    }

    const handleChange = (e) => { 
        let termCopied = [...terms]; 

        if(!e.target.checked){ 
            setAgreeAll(false);
        }

        termCopied[e.target.value].checked = e.target.checked
        setTerms(termCopied);

    }

    const onClickNext = (e)=> { 
        let termCopied = [...terms]; 
        let params = new URLSearchParams();
        let allChkd = false
        termCopied.forEach(element=> { 

            if(element.required){
                if(!element.checked)
                {
                    allChkd = true;
                }
            }
            params.append(element.url, element.checked);
        });       

        if(!allChkd) {
            setIsErr(false);
            ChangeView()
        }else {setIsErr(allChkd)}
     }

    const onClickArrow = (e,index)=> { 

        window.open(`/policy/${terms[index].url}`);
    }

    return (
        <div>
        <Grid container spacing={2} >
            <Grid item xs ={12}>
                <Typography component="h2" variant="inherit">
                    Please agree to the Terms of Service.
                </Typography>
            </Grid>
            <Grid  item xs = {12}>
            
            <FormControl component="fieldset" variant="standard" fullWidth = {true} required = {true}>
                <FormGroup>
                    <FormControlLabel className = {classes.FormControlLabel } 
                           
                           control={
                            <TermDetail state = {agreeAll} propName = {"agreeAll"} handleChange ={handleAgreeAll} propId = {-1} required = {false}/>
                           }
                           label= {     
                                    <Typography component="h4" variant="inherit" >
                                        Agree to All terms.
                                    </Typography>}
                    />
                    <Divider orientation="horizontal"></Divider>
                    <List
                        sx={{
                            width: '100%',
                            '& ul': { padding: 0 }
                        }}            
                    subheader={<li/>}>
                    {
                     terms.map((term, index)=>(
                        <li key={term.term}>
                        <ul>
   
                        <FormControlLabel className = {classes.FormControlLabel}
                           
                            control={
                                <TermDetail state = {term.checked} propName = {term.term} handleChange ={handleChange} propId = {index} required = {term.required}/>
                            }
                            label={<>{term.required?<>[Required]</>:<></>} {term.term}</>}
                        />
                        
                        <IconButton onClick = {(e)=> onClickArrow(e,index)} className = {classes.rightArrow}>
                            <ArrowForwardIosSharpIcon sx={{marginTop: '8px'}}/>
                        </IconButton>
                        </ul></li>))}
                    </List>     
                </FormGroup>
            </FormControl>
            </Grid>
            <Grid item xs ={12}> 
            {isErr? <span className={classes.err}>Please agree to required terms</span>:<span></span>}
            </Grid>
            <Grid  item xs = {12}>
                <Button type = "submit" color="primary" variant="contained" fullWidth onClick = {onClickNext}> Next </Button>  
            </Grid>
        </Grid>
        </div>
    )
}

export default Terms
