import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import Axios from 'axios';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';

import CircularProgress from '@mui/material/CircularProgress';

import serverURL from '../../serverURL.json';


const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      active_tabStyle:{
        width: '100%',
        color: 'white',
        backgroundColor: 'red',
        },
        labelPlacementStart: {
            display: "flex",
            justifyContent: 'space-between',
          },
  });



class ManageCSTab extends Component{
    constructor(props) {
        super(props);

        this.state = {
          id : this.props.id,
          password : this.props.password,

            isHandled_False : true,
            isHandled_True : true,

            csList : [],
            csList_filterd : null,

            tempCSID : "",
            tempName : "",
            tempEmail : "",
            tempIsHandled : false,
            tempTitle : "",
            tempContents : "",

            dlgShowEdit : false,
        }


    this.handleValueChange_Check = this.handleValueChange_Check.bind(this);
    this.editClick = this.editClick.bind(this);
    
    this.setFiltered = this.setFiltered.bind(this);
    this.getCSList_Axios = this.getCSList_Axios.bind(this);

    this.csInfoHandleChange =this.csInfoHandleChange.bind(this)

    this.closeShowEdit =this.closeShowEdit.bind(this)
    
    
    }  


    closeShowEdit(e){
        if(e.currentTarget.name ==="ok"){
          let params = new URLSearchParams();
          params.append('id', this.state.id);
          params.append('password', this.state.password);
          params.append('csID', this.state.tempCSID);
          params.append('isHandled', this.state.tempIsHandled);
        //   params.append('fullName', this.state.tempName);
        //   params.append('email', this.state.tempEmail);
        //   params.append('title', this.state.tempTitle);
        //   params.append('contents', this.state.tempContents);
          Axios.post(serverURL.api+ '/editCSInfo_Manager', params).then(
              response =>{
                  if(response.status === 200)
                  {
                  const returnResult = response.data;
                  if(returnResult.result)
                  {
                      alert('The CS info is changed')
                      this.getCSList_Axios();
                      // window.location.reload(true);
                  }else
                  {
                      alert(returnResult.alert)
                  }
                  }else
                  {
                  alert('engResponseStatusNot200');
                  }
              }
              ).catch((error) =>{
                  alert('Unexpected Access, Need to refresh page addApp');
              }
          );
  
  
        }
        this.setState({dlgShowEdit : false})
      }

    
    csInfoHandleChange(e, _handleName)
    {
      let nextState = {};
      nextState[_handleName] = e.target.value;
      this.setState(nextState);
    }

    editClick(_dicCS)
    {

        
      let _csID = _dicCS['_id'];
      let _name = _dicCS['fullName'];
      let _email = _dicCS['email'];
      let _isHandled = _dicCS['isHandled'];
      let _title = _dicCS['title'];
      let _contents = _dicCS['contents'];

      this.setState({
        dlgShowEdit :true,
        tempCSID : _csID,
        tempName : _name,
        tempEmail : _email,
        tempIsHandled : String(_isHandled),
        tempTitle : _title,
        tempContents : _contents,
      })
     



    }


    handleValueChange_Check(e){
        let nextState = {};
        nextState[e.target.name] = !this.state[e.target.name];
        this.setState(nextState);
        let _isHandled_False = this.state.isHandled_False;
        let _isHandled_True = this.state.isHandled_True;
       
        if(e.target.name === 'isHandled_False')
        {
            _isHandled_False = !_isHandled_False;
        }

        if(e.target.name === 'isHandled_True')
        {
            _isHandled_True = !_isHandled_True;
        }

       
       
        this.setFiltered(this.state.csList, _isHandled_False, _isHandled_True )
  
      }

    componentWillMount(){

        this.getCSList_Axios();

    }

    getCSList_Axios(){

        let params = {};
        let key = 'params';
        let value = {
          id : this.state.id,
          password : this.state.password,
        }
        params[key] = value;
        Axios.get(serverURL.api + '/getCSList', params).then(
            response =>{
                if(response.status === 200)
                {
                const returnResult = response.data;

                if(returnResult.result)
                {
                    const _csList = returnResult.csList;
                    this.setState({csList : _csList});
                    this.setFiltered(_csList, this.state.isHandled_False, this.state.isHandled_True);
                }else
                {
                    alert(returnResult.alert)
                }
                }else
                {
                alert('engResponseStatusNot200');
                }
            }
        ).catch((error) =>{
        alert('Unexpected Access, Need to refresh page getAppPermission');
        });
      }

      setFiltered(_csList, _isHandled_False, _isHandled_True)
        {
          // let _isJustAdd = this.state.isJustAdd;
          // let _isBuyApply = this.state.isBuyApply;
          // let _isRealUser = this.state.isRealUser;
          
          let filterList = [];
          if(_isHandled_False)
          {
            filterList.push(false);
          }

          if(_isHandled_True)
          {
            filterList.push(true);
          }

          
          let tempList = []
          for(let num =0; num < _csList.length; num ++)
          {
           let _status = _csList[num]['isHandled'];
           if(filterList.includes(_status)){
            tempList.push(_csList[num]);
           }
          }
          this.setState({ csList_filterd :tempList })


        }

   

    render(){
        const {classes} =  this.props;

        const ShowEditDialog = (
            <Dialog
            open={this.state.dlgShowEdit}
            onClose={this.closeShowEdit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit CS Info</DialogTitle>
          <DialogContent>
                   <div>
            <TextField
                 id="fullName"
                 name="fullName"
                 value ={this.state.tempName}
                 label ={"Name"}
                 style = {{margin : 10, width : '40%'}}
                 inputProps = {"aria-readonly"}
               />
                <TextField
                 id="email"
                 name="email"
                 value ={this.state.tempEmail}
                 label ={"E-Mail"}
                 style = {{margin : 10, width : '40%'}}
                 inputProps = {"aria-readonly"}
               />
               </div>
               <FormControl component="fieldset" style = {{margin : 10, width : '80%'}}>
               <FormLabel component="legend">Status</FormLabel>
               <RadioGroup row aria-label="status" name="status1" value={String(this.state.tempIsHandled)} onChange={(e) =>this.csInfoHandleChange(e,"tempIsHandled")}>
                 <FormControlLabel value={'false'} control={<Radio />} label="Not Handled" />
                 <FormControlLabel value={'true'} control={<Radio />} label="Handled Done" />
               </RadioGroup>
               </FormControl>
               <TextField
                 variant="outlined"
                 id="title"
                 name="title"
                 value ={this.state.tempTitle}
                 label ={"Title"}
                 style = {{margin : 10, width : '80%'}}
                 inputProps = {"aria-readonly"}
               /> 
               <TextField
                    name="contents"
                    variant="outlined"
                    id="contents"
                    label="Contents"
                    multiline={true}
                    rows={10}
                    value = {this.state.tempContents}
                     style = {{margin : 10, width : '80%'}}
                    inputProps = {"aria-readonly"}
                />
          </DialogContent>
            <DialogActions>
            <Button name ='ok' onClick={this.closeShowEdit} color="primary" >
                  Edit
              </Button>
              <Button name ='close' onClick={this.closeShowEdit} color="primary" >
                  Close
              </Button>
              </DialogActions>
      
          </Dialog>
          );

          
     
        function ShowCS(_dicCS, _index, _this){

            function expandClick(){
             _dicCS.isExpand = !_dicCS.isExpand;
               _this.forceUpdate();
             }

                 return  <Paper style = {{margin : 10}}>
                    <div>
               <Divider  component="li" />
             <br/>
             <TextField
                 id="fullName"
                 name="fullName"
                 value ={_dicCS['fullName']}
                 label ={"Name"}
                 style = {{margin : 10, width : '40%'}}
                 inputProps = {"aria-readonly"}
               />
                <TextField
                 id="email"
                 name="email"
                 value ={_dicCS['email']}
                 label ={"E-Mail"}
                 style = {{margin : 10, width : '40%'}}
                 inputProps = {"aria-readonly"}
               />
               </div>
               <FormControl component="fieldset" style = {{margin : 10, width : '80%'}}>
               <FormLabel component="legend">Status</FormLabel>
               <RadioGroup row aria-label="status" name="status1" value={(_dicCS['isHandled'])}>
                 <FormControlLabel value={false} control={<Radio />} label="Not Handled" />
                 <FormControlLabel value={true} control={<Radio />} label="Handled Done" />
               </RadioGroup>
               </FormControl>
               {
                 _dicCS.isExpand
                 ?
                 <div>
               <TextField
                 variant="outlined"
                 id="title"
                 name="title"
                 value ={_dicCS['title']}
                 label ={"Title"}
                 style = {{margin : 10, width : '80%'}}
                 inputProps = {"aria-readonly"}
               /> 
               <TextField
                    name="contents"
                    variant="outlined"
                    id="contents"
                    label="Contents"
                    multiline={true}
                    rows={10}
                    value = {_dicCS['contents']}
                     style = {{margin : 10, width : '80%'}}
                    inputProps = {"aria-readonly"}
                />
               </div>

                 :
                 null
               }
               
                 
                <Button size="small" variant ="outlined"  style = {{margin : 10, width : '80%'}} onClick = {expandClick} >
                   {
                     _dicCS.isExpand
                   ?
                     "<Briefly>"
                   :
                     "<More>"
                   }
                   </Button>
               <div  style={{display: 'flex', justifyContent: 'flex-end', marginLeft: "auto"}}>
               <Button
               variant="contained"
               style ={{margin: 20}}
               color= {"primary"} 
               onClick ={() => _this.editClick(_dicCS)}
               >
               Edit 
               </Button>
               </div>    

              
            </Paper>
 
         }



        return(
           

            <div className={classes.root}>
                <FormGroup row>
                <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.state.isHandled_False}
                            onChange={this.handleValueChange_Check}
                            name="isHandled_False"
                            color="primary"
                        />
                        }
                        label="Not Handled"
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.state.isHandled_True}
                            onChange={this.handleValueChange_Check}
                            name="isHandled_True"
                            color="primary"
                        />
                        }
                        label="Handled Done"
                    />
                   
                </FormGroup>




           {
            
            this.state.csList_filterd === null 
            ?
            <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
              <CircularProgress />
            </div>
            :
            this.state.csList_filterd.length ===0
            ?
            <div style = {{ display: 'flex',  alignItems: 'center', justifyContent: 'center'}}>
                No List
            </div>
            :
            this.state.csList_filterd.map((dicCS,index)=>{

            return ShowCS(dicCS, index, this);
            })
            
            }
            {ShowEditDialog}
            </div>
        )
    }
}

export default withStyles(useStyles)(ManageCSTab);




