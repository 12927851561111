import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';


function CompanyCard(props) {
    const {company,SelectCompany,close} = props

    const SelectCompanyNClose = function(e){
      SelectCompany(company)
      close()
    }
    return (
      <>
        <ListItem key = {company.crmId} alignItems="flex-start" onClick = {SelectCompanyNClose} sx={{'&:hover': {  backgroundColor: 'primary.main'}}} >
          <ListItemText
            primary={company.company}
            secondary={<React.Fragment>
            <Typography
              sx={{
                display: 'inline',
                top: '1px'
              }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {company.tel}
            </Typography>
          </React.Fragment>} />
      </ListItem><Divider />
      </>
    )
}

export default CompanyCard
