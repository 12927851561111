import React,{useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Axios from 'axios';
import Grid from '@mui/material/Grid'
import CompanyCardContainer from './CompanyCardContainer';
import serverURL from '../../serverURL.json';

const columns = [
  {
    field: 'company',
    headerName: 'company',
    flex:1,
    editable: false,
  },
];




const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CustomizedInputBase(props) {
  const classes = useStyles();
  const {isOpen, close, SelectCompany} = props

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  let [comapnyName, setcomPanyName] = useState("")

  let [companies, setCompanies] = useState([]);

  const FindCompany = (e)=>{ 
      Axios.get( `api/company?name=${comapnyName}`).then(res=>{ 
        setCompanies(res.data); 
      }).catch(err=> { 
      }) 
  }
  const TextFieldSearchHandler =(e)=> { 
    if(e.key==='Enter') {
      e.preventDefault();
      FindCompany(e) }
  }
  
  
  return <>
   <Dialog
      fullScreen={fullScreen}
      maxWidth = "sm"
      open={isOpen}
      aria-labelledby="Search Company"
      sx={{
      }}
    >
      <DialogTitle id="Search Company" >
        <div variant="h3" sx ={{color:'primary'}}> </div>
        {isOpen ? (
        <IconButton
          aria-label="close"
          onClick={close}
          className={classes.closeButton}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          >
         <CloseIcon />
        </IconButton>
      ) : null}</DialogTitle>      
      <DialogContent>
     
      <Grid container spacing={2}>
        <Grid item xs = {12}>  
          <DialogContentText>
          </DialogContentText>
        </Grid>
      
        <Grid item xs = {12}>  
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%'}}
          square = {true}
          outlined = "true"
        >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Companies"
        onChange = {e=>{setcomPanyName(e.target.value)}}
        inputProps={{ 'aria-label': 'Search Companies' }}
        onKeyPress ={TextFieldSearchHandler}
      />
      <IconButton sx={{ p: '10px' }} aria-label="search" onClick={FindCompany}>
        <SearchIcon />
      </IconButton>
    </Paper>
        </Grid>
        <Grid item xs ={12}>
          <div style={{ height: 300}}>{
            companies.length==0? <span>No Result</span>:
            <CompanyCardContainer companyInfo ={companies} SelectCompany = {SelectCompany} close = {close} />
          }
            
          </div>
        </Grid>
      </Grid>
      </DialogContent>
    </Dialog>

  </>;
}