import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import withStyles from '@mui/styles/withStyles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { withCookies } from 'react-cookie';

import serverURL from '../serverURL.json';



function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `scrollable-force-tab-${index}`,
//     'aria-controls': `scrollable-force-tabpanel-${index}`,
//   };
// }

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      active_tabStyle:{
        width: '100%',
        color: 'white',
        backgroundColor: 'red',
        }
  });

//appPermissionList에서
//Status가 0이면 나의 앱에 추가한사람
//Status가 1이면 신청 중인사람
//Status가 2이면 사용 가능한 사람

class AppManageDetailPermissionTab extends Component{
    constructor(props) {
        super(props);
        const { cookies } = props;
          this.state = {
              user : cookies.get('user') ? cookies.get('user') : {_id: "", refToken :"", accToken:"", auth: false} ,
              appID : this.props.appID,
              appPermissionList : [],
              appPermissionList_filterd : [],
              isJustAdd : true,
              isBuyApply : true,
              isRealUser : true,





             tempUserID : "",
             tempName : "",
             tempCompany : "",
             tempActivationDate : "",
             tempExpirationDate : "",
             tempStatus : 0,
             tempCopy : 0,
             tempUseDay : 0,
             tempLicenseType : "",
             tempMAC : "",
             tempPrice : 0,
             tempPIC : "",
             tempNote : "",





          }
          
          this.getAppPermission_Axios = this.getAppPermission_Axios.bind(this);
          this.handleValueChange_Check = this.handleValueChange_Check.bind(this);
          this.handleValueChange = this.handleValueChange.bind(this);
          this.setFiltered = this.setFiltered.bind(this);

          this.perInfoHandleChange = this.perInfoHandleChange.bind(this);
          this.editClick = this.editClick.bind(this);


          this.closeShowEdit = this.closeShowEdit.bind(this);


          

        }

        closeShowEdit(e){
          if(e.currentTarget.name ==="ok"){
            // tempUserID : "",
            // tempName : "",
            // tempCompany : "",
            // tempActivationDate : "",
            // tempExpirationDate : "",
            // tempStatus : 0,
            // tempCopy : 0,
            // tempUseDay : 0,
            // tempLicenseType : "",
            // tempMAC : "",
            // tempPrice : 0,
            // tempPIC : "",
            // tempNote : "",
            let params = new URLSearchParams();
            params.append('userID', this.state.user._id);
            params.append('refToken', this.state.user.refToken);
            params.append('accToken', this.state.user.accToken);
            params.append('appID', this.state.appID);
            params.append('perUserID', this.state.tempUserID);
            // params.append('name', this.state.tempName);
            // params.append('company', this.state.tempCompany);
            // params.append('activationDate', this.state.tempActivationDate);
            // params.append('expirationDate', this.state.tempExpirationDate);
            params.append('status', this.state.tempStatus);
            params.append('copy', this.state.tempCopy);
            params.append('useDay', this.state.tempUseDay);
            params.append('licenseType', this.state.tempLicenseType);
            params.append('mac', this.state.tempMAC);
            params.append('price', this.state.tempPrice);
            params.append('pic', this.state.tempPIC);
            params.append('note', this.state.tempNote);
            Axios.post(serverURL.api+'/editAppPermission', params).then(
                response =>{
                    if(response.status === 200)
                    {
                    const returnResult = response.data;
                    if(returnResult.result)
                    {
                        alert('The permission info is changed')
                        this.getAppPermission_Axios();
                        // window.location.reload(true);
                    }else
                    {
                        alert(returnResult.alert)
                    }
                    }else
                    {
                    alert('engResponseStatusNot200');
                    }
                }
                ).catch((error) =>{
                    alert('Unexpected Access, Need to refresh page addApp');
                }
            );
    
    
          }
          this.setState({dlgShowEdit : false})
        }


        editClick(_dicPer)
        {
          let _userID = _dicPer['userID'];
          let _name = _dicPer['name'];
          let _company = _dicPer['company'];
          let _activationDate = _dicPer['activationDate'];
          let _expirationDate = _dicPer['expirationDate'];
          let _status = _dicPer['status'];
          let _copy = _dicPer['copy'];
          let _useDay = _dicPer['useDay'];
          let _licenseType = _dicPer['licenseType'];
          let _mac = _dicPer['mac'];
          let _price = _dicPer['price'];
          let _pic = _dicPer['pic'];
          let _note = _dicPer['note'];

          this.setState({
            dlgShowEdit :true,
            tempUserID : _userID,
            tempName : _name,
            tempCompany : _company,
            tempActivationDate : _activationDate,
            tempExpirationDate : _expirationDate,
            tempStatus : _status,
            tempCopy : _copy,
            tempUseDay : _useDay,
            tempLicenseType : _licenseType,
            tempMAC : _mac,
            tempPrice : _price,
            tempPIC : _pic,
            tempNote : _note,
          })
         



        }

        perInfoHandleChange(e, _handleName)
        {
          let nextState = {};
          nextState[_handleName] = e.target.value;
          this.setState(nextState);
        }

        handleValueChange_Check(e){
          let nextState = {};
          nextState[e.target.name] = !this.state[e.target.name];
          this.setState(nextState);
          let _isJustAdd = this.state.isJustAdd;
          let _isBuyApply = this.state.isBuyApply;
          let _isRealUser = this.state.isRealUser;
          if(e.target.name === 'isJustAdd')
          {
            _isJustAdd = !_isJustAdd;
          }

          if(e.target.name === 'isBuyApply')
          {
            _isBuyApply = !_isBuyApply;
          }

          if(e.target.name === 'isRealUser')
          {
            _isRealUser = !_isRealUser;
          }
          this.setFiltered(this.state.appPermissionList, _isJustAdd, _isBuyApply, _isRealUser )
    
        }


        handleValueChange(e){
          let nextState = {};
          nextState[e.target.name] = e.target.value;
          this.setState(nextState);
          // if(e.target.name === "isJustAdd"
          // || e.target.name === "isBuyApply"
          // || e.target.name === "isRealUser"){
          //   this.setFiltered(this.state.appPermissionList)
          // }
      }
  

        // setFiltered(_isJustAdd, _isBuyApply, _isRealUser)
        setFiltered(_appPermissionList, _isJustAdd, _isBuyApply, _isRealUser)
        {
          // let _isJustAdd = this.state.isJustAdd;
          // let _isBuyApply = this.state.isBuyApply;
          // let _isRealUser = this.state.isRealUser;
          
          let filterList = [];
          if(_isJustAdd)
          {
            filterList.push(0);
          }

          if(_isBuyApply)
          {
            filterList.push(1);
          }

          if(_isRealUser)
          {
            filterList.push(2);
          }
          let tempList = []
          for(let num =0; num < _appPermissionList.length; num ++)
          {
           let _status = _appPermissionList[num]['status'];
           if(filterList.includes(_status)){
            tempList.push(_appPermissionList[num]);
           }
          }
          this.setState({ appPermissionList_filterd :tempList })


        }

        componentWillMount()
        {

          this.getAppPermission_Axios();
          

        }

        getAppPermission_Axios(){
          if(this.state.appID === undefined
            || this.state.appID === "")
          {

          
          }else
          {


            let params = {};
            let key = 'params';
            let value = {
                userID : this.state.user._id,
                refToken : this.state.user.refToken,
                accToken : this.state.user.accToken,
                appID : this.state.appID
            }
            params[key] = value;
            Axios.get(serverURL.api+'/getAppPermission', params).then(
                response =>{
                    if(response.status === 200)
                    {
                    const returnResult = response.data;
  
                    if(returnResult.result)
                    {
                        const _appPermissionList = returnResult.appPermissionList;
                        // console.log(_appPermissionList)
                        this.setState({appPermissionList : _appPermissionList});
                        this.setFiltered(_appPermissionList, true, true, true);
                    }else
                    {
                        alert(returnResult.alert)
                    }
                    }else
                    {
                    alert('engResponseStatusNot200');
                    }
                }
            ).catch((error) =>{
            alert('Unexpected Access, Need to refresh page getAppPermission');
            });





          }
         
          
        }

       

        render(){
            const {classes} =  this.props;

            const ShowEditDialog = (
              <Dialog
              open={this.state.dlgShowEdit}
              onClose={this.closeShowEdit}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Edit Permission Info</DialogTitle>
            <DialogContent>
            <Paper style = {{margin : 10}}>
                     <div>
                <Divider  component="li" />
              <br/>
              <TextField
                  id="name"
                  name="name"
                  value ={this.state.tempName}
                  label ={"User Name"}
                  style = {{margin : 10, width : '40%'}}
                  inputProps = {"aria-readonly"}
                />
                 <TextField
                  id="company"
                  name="company"
                  value ={this.state.tempCompany}
                  label ={"Company"}
                  style = {{margin : 10, width : '40%'}}
                  inputProps = {"aria-readonly"}
                />
                <TextField
                  id="activationDate"
                  name="activationDate"
                  value ={this.state.tempActivationDate}
                  label ={"Activation Date"}
                  style = {{margin : 10, width : '40%'}}
                  inputProps = {"aria-readonly"}
                />
                <TextField
                  id="expirationDate"
                  name="expirationDate"
                  value ={this.state.tempExpirationDate}
                  label ={"Expiration Date"}
                  style = {{margin : 10, width : '40%'}}
                  inputProps = {"aria-readonly"}
                />  
                </div>
                <FormControl component="fieldset" style = {{margin : 10, width : '80%'}}>
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup row aria-label="status" name="status1" value={String(this.state.tempStatus)}  onChange={(e) =>this.perInfoHandleChange(e,"tempStatus")}>
                  <FormControlLabel value="0" control={<Radio />} label="Basket" />
                  <FormControlLabel value="1" control={<Radio />} label="Buy Apply" />
                  <FormControlLabel value="2" control={<Radio />} label="Users" />
                </RadioGroup>
                </FormControl>
                <TextField
                  onChange={(e) =>this.perInfoHandleChange(e,"tempCopy")}
                  variant="outlined"
                  id="copy"
                  name="copy"
                  value ={this.state.tempCopy}
                  label ={"Copy"}
                  // fullWidth
                  style = {{margin : 10, width : '80%'}}
                  type="number"
                  inputProps={{ min: "1",  step: "1" }}
                /> 
                <TextField
                  onChange={(e) =>this.perInfoHandleChange(e,"tempUseDay")}
                  variant="outlined"
                  id="useDay"
                  name="useDay"
                  value ={this.state.tempUseDay}
                  label ={"Use Day"}
                  style = {{margin : 10, width : '80%'}}
                  type="number"
                  inputProps={{ min: "0",  step: "1" }}
                /> 
                
                <TextField
                  onChange={(e) =>this.perInfoHandleChange(e,"tempLicenseType")}
                  variant="outlined"
                  id="licenseType"
                  name="licenseType"
                  value ={this.state.tempLicenseType}
                  label ={"License Type"}
                  style = {{margin : 10, width : '80%'}}
                />
                <TextField
                  onChange={(e) =>this.perInfoHandleChange(e,"tempMAC")}
                  variant="outlined"
                  id="mac"
                  name="mac"
                  value ={this.state.tempMAC}
                  label ={"MAC"}
                  style = {{margin : 10, width : '80%'}}
                />
                 <TextField
                  onChange={(e) =>this.perInfoHandleChange(e,"tempPrice")}
                  variant="outlined"
                  id="price"
                  name="price"
                  value ={this.state.tempPrice}
                  label ={"Price"}
                  style = {{margin : 10, width : '80%'}}
                  type="number"
                  inputProps={{ min: "1",  step: "1" }}
                /> 
                <TextField
                  onChange={(e) =>this.perInfoHandleChange(e,"tempPIC")}
                  variant="outlined"
                  id="pic"
                  name="pic"
                  value ={this.state.tempPIC}
                  label ={"P.I.C."}
                  // fullWidth
                  style = {{margin : 10, width : '80%'}}
                /> 
                  <TextField
                  onChange={(e) =>this.perInfoHandleChange(e,"tempNote")}
                  variant="outlined"
                  id="note"
                  name="note"
                  value ={this.state.tempNote}
                  label ={"Note"}
                  style = {{margin : 10, width : '80%'}}
                /> 
             </Paper>
            </DialogContent>
              <DialogActions>
              <Button name ='ok' onClick={this.closeShowEdit} color="primary" >
                    Edit
                </Button>
                <Button name ='close' onClick={this.closeShowEdit} color="primary" >
                    Close
                </Button>
                </DialogActions>
        
            </Dialog>
            );


          



            function ShowPermission(_dicPer, _index, _this){

             function expandClick(){
              _dicPer.isExpand = !_dicPer.isExpand;
                _this.forceUpdate();
              }

                  return  <Paper style = {{margin : 10}}>
                     <div>
                <Divider  component="li" />
              <br/>
              <TextField
                  id="name"
                  name="name"
                  value ={_dicPer['name']}
                  label ={"User Name"}
                  style = {{margin : 10, width : '40%'}}
                  inputProps = {"aria-readonly"}
                />
                 <TextField
                  id="company"
                  name="company"
                  value ={_dicPer['company']}
                  label ={"Company"}
                  style = {{margin : 10, width : '40%'}}
                  inputProps = {"aria-readonly"}
                />
                <TextField
                  id="activationDate"
                  name="activationDate"
                  value ={_dicPer['activationDate']}
                  label ={"Activation Date"}
                  style = {{margin : 10, width : '40%'}}
                  inputProps = {"aria-readonly"}
                />
                <TextField
                  id="expirationDate"
                  name="expirationDate"
                  value ={_dicPer['expirationDate']}
                  label ={"Expiration Date"}
                  style = {{margin : 10, width : '40%'}}
                  inputProps = {"aria-readonly"}
                />  
                </div>
                <FormControl component="fieldset" style = {{margin : 10, width : '80%'}}>
                <FormLabel component="legend">Status</FormLabel>
                {/* onChange={(e) =>_this.perInfoHandleChange(e,"tempStatus")} */}
                <RadioGroup row aria-label="status" name="status1" value={String(_dicPer['status'])} >
                  <FormControlLabel value="0" control={<Radio />} label="Basket" />
                  <FormControlLabel value="1" control={<Radio />} label="Buy Apply" />
                  <FormControlLabel value="2" control={<Radio />} label="Users" />
                </RadioGroup>
                </FormControl>
                {
                  _dicPer.isExpand
                  ?
                  <div>
                <TextField
                  // onChange={(e) =>_this.perInfoHandleChange(e,"tempCopy")}
                  variant="outlined"
                  id="copy"
                  name="copy"
                  value ={_dicPer['copy']}
                  label ={"Copy"}
                  // fullWidth
                  style = {{margin : 10, width : '80%'}}
                  type="number"
                  inputProps={{ min: "1",  step: "1" }}
                /> 
                <TextField
                  // onChange={(e) =>_this.perInfoHandleChange(e,"tempUseDay")}
                  variant="outlined"
                  id="useDay"
                  name="useDay"
                  value ={_dicPer['useDay']}
                  label ={"Use Day"}
                  style = {{margin : 10, width : '80%'}}
                  type="number"
                  inputProps={{ min: "1",  step: "1" }}
                /> 
                
                <TextField
                  // onChange={(e) =>_this.perInfoHandleChange(e,"tempLicenseType")}
                  variant="outlined"
                  id="licenseType"
                  name="licenseType"
                  value ={_dicPer['licenseType']}
                  label ={"License Type"}
                  style = {{margin : 10, width : '80%'}}
                />
                <TextField
                  // onChange={(e) =>_this.perInfoHandleChange(e,"tempMAC")}
                  variant="outlined"
                  id="mac"
                  name="mac"
                  value ={_dicPer['mac']}
                  label ={"MAC"}
                  style = {{margin : 10, width : '80%'}}
                />
                 <TextField
                  // onChange={(e) =>_this.perInfoHandleChange(e,"tempPrice")}
                  variant="outlined"
                  id="price"
                  name="price"
                  value ={_dicPer['price']}
                  label ={"Price"}
                  style = {{margin : 10, width : '80%'}}
                  type="number"
                  inputProps={{ min: "1",  step: "1" }}
                /> 
                <TextField
                  // onChange={(e) =>_this.perInfoHandleChange(e,"tempPIC")}
                  variant="outlined"
                  id="pic"
                  name="pic"
                  value ={_dicPer['pic']}
                  label ={"P.I.C."}
                  // fullWidth
                  style = {{margin : 10, width : '80%'}}
                /> 
                  <TextField
                  // onChange={(e) =>_this.perInfoHandleChange(e,"tempNote")}
                  variant="outlined"
                  id="note"
                  name="note"
                  value ={_dicPer['note']}
                  label ={"Note"}
                  style = {{margin : 10, width : '80%'}}
                /> 
                </div>

                  :
                  null
                }
                
                  
                 <Button size="small" variant ="outlined"  style = {{margin : 10, width : '80%'}} onClick = {expandClick} >
                    {
                      _dicPer.isExpand
                    ?
                      "<Briefly>"
                    :
                      "<More>"
                    }
                    </Button>
                <div  style={{display: 'flex', justifyContent: 'flex-end', marginLeft: "auto"}}>
                <Button
                variant="contained"
                style ={{margin: 20}}
                color= {"primary"} 
                onClick ={() => _this.editClick(_dicPer)}
                >
                Edit 
                </Button>
                </div>    

               
             </Paper>
  
          }



            return(

                <div className={classes.root}>
                <FormGroup row>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={this.state.isJustAdd}
                                onChange={this.handleValueChange_Check}
                                name="isJustAdd"
                                color="primary"
                            />
                            }
                            label="Basket"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={this.state.isBuyApply}
                                onChange={this.handleValueChange_Check}
                                name="isBuyApply"
                                color="primary"
                            />
                            }
                            label="Buy Apply"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={this.state.isRealUser}
                                onChange={this.handleValueChange_Check}
                                name="isRealUser"
                                color="primary"
                            />
                            }
                            label="Users"
                        />
                    </FormGroup>

                    {
                    this.state.appPermissionList_filterd
                    ?
                    this.state.appPermissionList_filterd.length ===0
                    ?
                    <div style = {{ display: 'flex',  alignItems: 'center', justifyContent: 'center'}}>
                      No List
                    </div>
                    :
                    this.state.appPermissionList_filterd.map((dicPer,index)=>{

                    return ShowPermission(dicPer, index, this);
                    })
                    :
                    <div>

                    </div>
                    }
              {ShowEditDialog}
              </div>
            )
        }
}

export default withStyles(useStyles)(withCookies(AppManageDetailPermissionTab));




