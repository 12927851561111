import React, {Component} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import Axios from 'axios';

import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Container from '@mui/material/Container';


import CircularProgress from '@mui/material/CircularProgress';

import serverURL from '../../serverURL.json';


const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      active_tabStyle:{
        width: '100%',
        color: 'white',
        backgroundColor: 'red',
        },
        labelPlacementStart: {
            display: "flex",
            justifyContent: 'space-between',
            wrap: 'nowrap',
            fullWidth : true
          },
  });



class ManageNewsTab extends Component{
    constructor(props) {
        super(props);

        this.state = {

            id : this.props.id,
            password : this.props.password,

            newsList : null,

            tempTitle : "",
            tempDate : "",
            tempImg : "",
            tempUrl : "",

            dlgShowEdit : false,

            dlgShowIMG : false,
            imgBase64 : "",

            isDoing : false,
            

        }
    //   this.handleValueChange = this.handleValueChange.bind(this);
      this.getNewsList_Axios = this.getNewsList_Axios.bind(this);
      

      this.deleteNews = this.deleteNews.bind(this);
    //   this.newsTitleChange = this.newsTitleChange.bind(this);
    //   this.newsDateChange = this.newsDateChange.bind(this);
    //   this.newsURLChange = this.newsURLChange.bind(this);
    //   this.newsIMGChange = this.newsURLChange.bind(this);
      this.newsChange = this.newsChange.bind(this);

    this.handleShowIMGDialog = this.handleShowIMGDialog.bind(this);
    this.closeShowIMG = this.closeShowIMG.bind(this);
      
    this.handleUploadClick_IMG = this.handleUploadClick_IMG.bind(this);
      
    this.addNews = this.addNews.bind(this);
    this.saveClick = this.saveClick.bind(this);
    
    
    }  

    saveClick(){
        if(window.confirm("Would you like to save?"))
        {
            
            let dicList = {};
            dicList['id'] =this.state.id;
            dicList['password'] =this.state.password;
            dicList['newsList'] =this.state.newsList;
            this.setState({isDoing : true})
            Axios.post(serverURL.api + '/saveNews_Manager', dicList).then(
            response =>{
            this.setState({isDoing : false})

                if(response.status === 200)
                {
                const returnResult = response.data;
                if(returnResult.result)
                {
                    alert('The News List is changed.')
                }else
                {
                    alert(returnResult.alert)
                }
                }else
                {
                alert('engResponseStatusNot200');
                }
            }
            ).catch((error) =>{
                alert('Unexpected Access, Need to refresh page User Setting 4');
            }
            );
        }
    }


    addNews(){
        let _newsList = this.state.newsList;
        _newsList.unshift({
            title : "",
            date : "",
            img : "",
            url : ""
        });
        // _newsList.push({});
        this.setState({newsList : _newsList});
    }

    
    handleUploadClick_IMG(event, _index)
    {
        var file = event.target.files[0];
        if(file !== undefined)
        {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // const format_All = ((file.type).split("/"))
            // const foramtName = format_All[format_All.length-1];
    
            reader.onloadend = function(e){
                let picBase64 = [reader.result][0];
                let _newsList = this.state.newsList;
                _newsList[_index]['img'] = picBase64;
                this.setState({newsList : _newsList});
    
            }.bind(this)
        }
    }


    closeShowIMG(){
        this.setState({dlgShowIMG : false})
      }

    handleShowIMGDialog(_imgBase64){
        this.setState({dlgShowIMG : true, imgBase64 :_imgBase64 })
      }


    newsChange(e, _index,_name){
        let _newsList = this.state.newsList;
        _newsList[_index][_name] = e.target.value;
        this.setState({newsList : _newsList});
    }

    
    // newsURLChange(e){
    //     let _newsList = this.state.newsList;
    //     _newsList['url'] = e.target.value;
    //     this.setState({newsList : _newsList});
    // }
    

    // newsDateChange(e){
    //     let _newsList = this.state.newsList;
    //     _newsList['date'] = e.target.value;
    //     this.setState({newsList : _newsList});
    // }

    // newsTitleChange(e){
    //     let _newsList = this.state.newsList;
    //     _newsList['title'] = e.target.value;
    //     this.setState({newsList : _newsList});
    // }


    deleteNews(_index){

        let _newsList = this.state.newsList;
        _newsList.splice(_index, 1)
        this.setState({newsList : _newsList});
    }


    componentWillMount(){

        this.getNewsList_Axios();

    }

    getNewsList_Axios(){

        let params = {};
        let key = 'params';
        let value = {
            // id : this.state.id,
            // password : this.state.password,
        }
        params[key] = value;
        Axios.get(serverURL.api + '/getNews', params).then(
            response =>{
                if(response.status === 200)
                {
                const returnResult = response.data;

                if(returnResult.result)
                {
                    const _newsList = returnResult.newsList;
                    this.setState({newsList : _newsList});
                }else
                {
                    alert(returnResult.alert)
                }
                }else
                {
                alert('engResponseStatusNot200');
                }
            }
        ).catch((error) =>{
        alert('Unexpected Access, Need to refresh page getAppPermission');
        });
      }


   

    render(){
        const {classes} =  this.props;

        

        const ShowIMGDialog = (
            <Dialog
            open={this.state.dlgShowIMG}
            onClose={this.closeShowIMG}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <img src={this.state.imgBase64} alt="" />
      
           <DialogActions>
                 
            <Button name ='close' onClick={this.closeShowIMG} color="primary" >
                Close
            </Button>
            </DialogActions>
      
        </Dialog>
        );



         function ShowNews(_dicNews, _index, _this){
            // return  <Grid container  style = {{margin : 1}} key ={'news' + _index}>
            return (
                <Grid container  style = {{margin : 1}}>
                      <Grid container>
                    <div>
                    <Divider  component="li" />

                    </div>
                </Grid>
                <div  style ={{width : "100%"}}>
                {/* <div  style ={{width : "10%"}}> */}
                <IconButton color="secondary" onClick = {()=>_this.deleteNews(_index)} size="large">
                    <RemoveCircleIcon fontSize= 'small'/>
                </IconButton>
                </div>
                    <Container>
                    <FormControlLabel
                fullWidth = {true}
                labelPlacement="start"
                classes = {{
                  labelPlacementStart : classes.labelPlacementStart
                }}
                    control={
                    <TextField
                      onChange={(e)=>_this.newsChange(e, _index, 'title')}
                      color="primary"
                      id="title"
                      name="title"
                      value ={_dicNews['title']}
                      style={{ width : '100%', paddingLeft: '20px',justify : "flex-end" ,  display: "flex"}}
                    />    
                    }
                    label={"Title"}
                />
                </Container>
                <Container>
                <FormControlLabel
                fullWidth = {true}
                labelPlacement="start"
                classes = {{
                  labelPlacementStart : classes.labelPlacementStart
                }}
                    control={
                    <TextField
                    onChange={(e)=>_this.newsChange(e, _index, 'date')}
                      color="primary"
                      id="date"
                      name="date"
                      value ={_dicNews['date']}
                      style={{ width : '100%', paddingLeft: '20px',justify : "flex-end" ,  display: "flex"}}
                    //   style={{width : '350px', paddingLeft: '20px',justify : "flex-end" ,  display: "flex"}}
                    />    
                    }
                    label={"Date"}
                />
                </Container>
                <Container>
                <FormControlLabel
                fullWidth = {true}
                labelPlacement="start"
                classes = {{
                  labelPlacementStart : classes.labelPlacementStart
                }}
                    control={
                    <TextField
                    onChange={(e)=>_this.newsChange(e, _index, 'img')}
                      color="primary"
                      id="img"
                      name="img"
                      value ={_dicNews['img']}
                      style={{ width : '100%', paddingLeft: '20px',justify : "flex-end" ,  display: "flex"}}
                    />    
                    }
                    label={"IMG"}
                />
                </Container>
                <Container>
                <FormControlLabel
                fullWidth = {true}
                labelPlacement="start"
                classes = {{
                  labelPlacementStart : classes.labelPlacementStart
                }}
                    control={
                    <TextField
                    onChange={(e)=>_this.newsChange(e, _index, 'url')}
                      color="primary"
                      id="url"
                      name="url"
                      value ={_dicNews['url']}
                      style={{ width : '100%', paddingLeft: '20px',justify : "flex-end" ,  display: "flex"}}
                    />    
                    }
                    label={"URL"}
                />
                </Container>
              


               </Grid>
            ); 

        }

     


        return (
            <div className={classes.root}>
            <Typography component="h6" variant="h6">
            ㅁ News
                <IconButton color="primary" onClick = {this.addNews} size="large">
                    <AddCircleIcon fontSize= 'small'/>
                </IconButton>
            </Typography>
            {
          
            this.state.newsList === null 
            ?
            <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
              <CircularProgress />
            </div>
            :
            this.state.newsList.length ===0
            ?
            <div style = {{ display: 'flex',  alignItems: 'center', justifyContent: 'center'}}>
                No List
            </div>
            :
            this.state.newsList.map((dicNews,index)=>{

            return ShowNews(dicNews, index, this);
            })
           
            }
            {
                this.state.isDoing
                ?
                <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
                  <CircularProgress />
                </div>
                :
                <div style={{display: 'flex', justifyContent: 'flex-end', marginLeft: "auto"}}>
                <Button
                variant="contained"
                style ={{margin: 20}}
                color= {'primary'} 
                onClick ={this.saveClick}
                >
                Save 
                </Button>
            </div>
              }


           

             {ShowIMGDialog}
            </div>
        );
    }
}

export default withStyles(useStyles)(ManageNewsTab);




