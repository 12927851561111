import React, { Component } from 'react';
import Axios from 'axios';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Footer from './Footer';
import Header from './Header'

import serverURL from '../serverURL.json';



const styles = theme =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  HeadLine : {
    fontSize: 'calc(1vw + 1.5vh)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace : 'pre-wrap'
  }

});


class ContactSupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
        title : "",
        contents : "",
        fullName : "",
        email : "",


        csNum : 0,


      }
    this.handleValueChange = this.handleValueChange.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.csMan = this.csMan.bind(this);
    
    }

    csMan(){
      this.setState({csNum : this.state.csNum +1});
      if(this.state.csNum > 5)
      {
        document.location.href = '/SeaTrustManagement';
        
      }

    }

    handleValueChange(e){
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    }



    sendMessage(){
        if(this.state.title === ""){
            alert('Please enter Title');
            return;
        }else{
            if(this.state.contents === ""){
                alert('Please enter Contents');
                return;
            }else{
                if(this.state.fullName ===""){
                  alert('Please enter Full Name');
                    return;
                }else{
                    if(this.state.email === ""){
                        alert('Please enter E-mail');
                        return;
                    }else{

                        let params = new URLSearchParams();
                        params.append('title', this.state.title);
                        params.append('contents', this.state.contents);
                        params.append('fullName', this.state.fullName);
                        params.append('email', this.state.email);
                        Axios.post(serverURL.api + '/sendMessage_ContactSupport', params).then(
                            response =>{
                              if(response.status === 200)
                              {
                                const returnResult = response.data;
        
                                if(returnResult.result)
                                {
                                    alert('Your inquiry has been successfully received.')
                                    window.location.reload()
                                }else
                                {
                                    alert(returnResult.alert)
                                }
                              }else
                              {
                               alert('responseStatusNot200');
                              }
                            }
                          ).catch((error) =>{
                            alert('Unexpected Access, Need to refresh page Contact Support 1');
                            }
                          );




                    }
                    




                }
            }
        }
    }
   

  render(){
    const {classes} = this.props;

  return (
      <div>
    <Header user = {this.state.user}/>
    <br/>
    <Container component="main">

        <div style={{width:'100%'}} align = 'center'  >
            <span className = {classes.HeadLine} >
                <ContactSupportIcon color = "primary" fontSize = "large" onClick={this.csMan}/>Support
            </span>
            <span >
            You will receive an e-mail response to your request. The information you submit will be saved for support, and you agree to it.
            </span>
            <br/>
            <br/>
        </div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="fullName"
                        label="Full Name"
                        name="fullName"
                        onChange = {this.handleValueChange}
                        value = {this.state.fullName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        onChange = {this.handleValueChange}
                        value = {this.state.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="title"
                        label="Title"
                        name="title"
                        onChange = {this.handleValueChange}
                        value = {this.state.title}
                    />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    name="contents"
                    variant="outlined"
                    required
                    fullWidth
                    id="contents"
                    label="Contents"
                    multiline={true}
                    rows={10}
                    value = {this.state.contents}
                    onChange = {this.handleValueChange}
                />
                </Grid>
            </Grid>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick ={this.sendMessage}
            >
                Send
            </Button>
    </Container>
    <Footer/>
    </div>
  );
}

}


export default withStyles(styles)(ContactSupport);
