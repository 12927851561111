import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import withStyles from '@mui/styles/withStyles';
import InfoIcon from '@mui/icons-material/Info';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import serverURL from '../../serverURL.json';
import Axios from 'axios';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MakeCore from './MakeCore';
import CircularProgress from '@mui/material/CircularProgress';


import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
// import EditIcon from '@mui/icons-material/Edit';


const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      active_tabStyle:{
        width: '100%',
        color: 'white',
        backgroundColor: 'red',
        }
  });



class MakeArticleTab extends Component{
    constructor(props) {
        super(props);
        this.state = {
            id : this.props.id,
            password : this.props.password,

            articleList : null,
          showEditor : false,


        }
        this.showEditorClick = this.showEditorClick.bind(this);
        this.hideEditorClick = this.hideEditorClick.bind(this);
        
    }

    componentWillMount(){

        this.getArticleList();

    }

    getArticleList(){

        let params = {};
        let key = 'params';
        let value = {
          id : this.state.id,
          password : this.state.password,
        }
        params[key] = value;
        Axios.get(serverURL.api+'/getArticleList', params).then(
            response =>{
                if(response.status === 200)
                {
                const returnResult = response.data;

                if(returnResult.result)
                {
                    const _articleList = returnResult.articleList;
                    this.setState({articleList : _articleList});
                }else
                {
                    alert(returnResult.alert)
                }
                }else
                {
                alert('engResponseStatusNot200');
                }
            }
        ).catch((error) =>{
        alert('Unexpected Access, Need to refresh page getAppPermission');
        });
      }


    hideEditorClick(){
        this.setState({showEditor : false})

}

    showEditorClick(){
            this.setState({showEditor : true})

    }


    
      

        render(){
            const {classes} =  this.props;

            function ShowArticle(dicArticle, _this){

                function clickDelete(){

                    if(window.confirm("Would you like to delete this article?"))
                    {
                        let params = new URLSearchParams();
                        params.append('id', _this.state.id);
                        params.append('password', _this.state.password);
                        params.append('articleID',dicArticle._id);
                        _this.setState({articleList : null})
                        Axios.post(serverURL.api+'/deleteArticle', params).then(
                            response =>{
                                if(response.status === 200)
                                {
                                const returnResult = response.data;
                                if(returnResult.result)
                                {
                                    alert('The Article is Deleted')
                                    _this.getArticleList();
                                    // let params2 = {};
                                    // let key = 'params';
                                    // let value = {
                                    //   id : _this.state.id,
                                    //   password : _this.state.password,
                                    // }
                                    // params[key] = value;
                                    // Axios.get(serverURL.api + '/getArticleList', params2).then(
                                    //     response =>{
                                    //         if(response.status === 200)
                                    //         {
                                    //         const returnResult = response.data;
                            
                                    //         if(returnResult.result)
                                    //         {
                                    //             const _articleList = returnResult.articleList;
                                    //             _this.setState({articleList : _articleList});
                                    //         }else
                                    //         {
                                    //             alert(returnResult.alert)
                                    //         }
                                    //         }else
                                    //         {
                                    //         alert('engResponseStatusNot200');
                                    //         }
                                    //     }
                                    // ).catch((error) =>{
                                    // alert('Unexpected Access, Need to refresh page getAppPermission');
                                    // });



                                }else
                                {
                                    alert(returnResult.alert)
                                }
                                }else
                                {
                                alert('engResponseStatusNot200');
                                }
                            }
                            ).catch((error) =>{
                                alert('Unexpected Access, Need to refresh page addApp');
                            }
                        );
                
                
                        }
                   


                    }

                    function goArticle(){
                        window.open('/Article/' + dicArticle._id)
                        // window.location.href = '/Article/' + dicArticle._id
                    }

                return (
                    <TableRow key={dicArticle._id}>
                    <TableCell align = 'center'>
                        <IconButton onClick={clickDelete} size="large">
                            <DeleteIcon/>
                        </IconButton>
                            </TableCell>
                            <TableCell align = 'center'>
                        <IconButton onClick={goArticle} size="large">
                            <PlayForWorkIcon/>
                        </IconButton>
                    </TableCell>
                    {/* <TableCell component="th" scope="row"><EditIcon/></TableCell> */}
                    <TableCell align = 'center'>
                    {dicArticle._id}
                    </TableCell>
                    <TableCell align = 'center'>
                    {dicArticle.title}
                    </TableCell>
                    

                    {/* <TableCell align="right">{dicArticle.charge}</TableCell> */}
                  </TableRow>
                );
            }


            return(

                <div className={classes.root}>
                 
                    {
                        this.state.showEditor
                        ?
                        <div>
                        <div  style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <Button variant="contained" color="primary" onClick = {this.hideEditorClick}>Hide Editor</Button>
                        </div>
                        <div>
                            <MakeCore id ={this.state.id} password = {this.state.password}/>
                            </div>
                            </div>

                        :
                        <div  style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <Button variant="contained" color="primary" onClick = {this.showEditorClick}>Show Editor</Button>
                        </div>
                    }
                    <br/>

                    <Grid container  style = {{margin : 5}}>
                        <Typography component="h6" variant="h6">
                            ㅁ Article List
                        </Typography>
                    </Grid>
                <TableContainer component={Paper} style = {{margin : 10}}>
                    <Table className={classes.table} aria-label="simple table">
                        <colgroup>
                            <col style = {{width : '50px'}}/>
                            <col style = {{width : '50px'}}/>
                        </colgroup>
                    <TableHead>
                    <TableRow style={{backgroundColor:'lightgrey', color: 'white',}}>
                    <TableCell align = 'center'>Delete</TableCell>
                    <TableCell align = 'center'>View</TableCell>
                    {/* <TableCell>Edit</TableCell> */}
                    <TableCell align = 'center'>_id</TableCell>
                    <TableCell align = 'center'>Title</TableCell>
                    </TableRow>
                </TableHead>
                    <TableBody>

                    {
            
                    this.state.articleList === null 
                    ?
                    null
                    :
                    this.state.articleList.length ===0
                    ?
                    <div style = {{ display: 'flex',  alignItems: 'center', justifyContent: 'center'}}>
                        No List
                    </div>
                    :
                    this.state.articleList.map(dicArticle=>{

                        return ShowArticle(dicArticle, this);
                        })
                    
                    }


                    </TableBody>
                    </Table>
                </TableContainer>
                {
                this.state.articleList === null 
                ?
                <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
                <CircularProgress />
                </div>
                :
                null
                }

              </div>
            )
        }
}

export default withStyles(useStyles)((MakeArticleTab));




