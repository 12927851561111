import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { TextField,List,IconButton,Grid,ListItem, DialogActions, Dialog, Button} from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';

function checkIMG(strValue){
    if(strValue.includes('data:image/'))
    {
        return true
    }else
    {
        return false
    }
}

const useStyles = makeStyles((theme) => ({
    TextField:{ 
        display: "flex", 
        float: 'right',
        width: '90%'
    }
}));

function SubTextField(props) {
    const classes = useStyles(); 
    const [dlgShowIMG, setDlgShowIMG] = React.useState("false");
    const [imgBase64, setImgBase64] = React.useState("")
    const {label, properties,propName, onChangeHandler, onClickDeleteHandler} = props; 

    const onTextFieldChangeHandler =(e,index) =>{ 
        console.log("typed~")
        onChangeHandler(index,e.target.name,e.target.value);
    }
    const handleShowIMGDialog = (_imgBase64)=>{ 
        setDlgShowIMG(true);
        setImgBase64(_imgBase64)

    }
    const closeShowIMG =()=> { 
        setDlgShowIMG(false)
    }
    
    const handleUploadClick = (event, _index) =>
    {
        var file = event.target.files[0];
        if(file !== undefined)
        {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // const format_All = ((file.type).split("/"))
            // const foramtName = format_All[format_All.length-1];
    
            reader.onloadend = function(e){
                let picBase64 = [reader.result][0];

                onChangeHandler(_index, propName, picBase64 )
    
            }
        }
        

    }


    return (
        <div>
            <List  sx={{ width: '100%',   outline: "#d3d3d3 solid 1px" }}
            aria-labelledby="nested-list-subheader"
            component = "nav"
            dense ={true}
            disablePadding={true}>   
                       
            {properties? properties.map((value,index) => {
                return (
                <ListItem
                    key={index}>
                <IconButton edge = "start" aria-label ="delete" size="large" color="error" onClick = {(e)=>onClickDeleteHandler(index,propName)}>
                    <RemoveCircle fontSize= 'small'/>
                </IconButton>
                {
                checkIMG(value)
                ?
                <span>
                <img src={value} alt=""  width="100"  onClick={()=>handleShowIMGDialog(value)}/>
                <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    style={{display: 'block'}}
                    onChange={(e)=>handleUploadClick(e, index)}
                /></span>
                    :
                    <TextField
                    onChange={(e)=>onTextFieldChangeHandler(e,index)}
                    color="primary"
                    id= {Number.toString(index)}
                    name= {propName}
                    value ={value}
                    variant="standard"
                    //fullWidth
                    className = {classes.TextField}
                    label ={label}
                    InputLabelProps={{color: "primary"}}
                    />        
                }            
            </ListItem>
            );
      }):<></>}
      </List>

      </div>
    )
}

export default SubTextField
