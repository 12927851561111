import React from 'react'
import {makeStyles} from '@mui/styles';
import { Grid,Box,TextField,Typography, List, ListSubheader, ListItem, IconButton, FormControl, Input, InputLabel, Radio, FormControlLabel, RadioGroup } from '@mui/material';
import { Label, TextFields } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SubTextField from './SubTextField';
import SubTextFieldObjContainer from './SubTextFieldObjContainer';
import SubTextFieldObj from './SubTextFieldObj';
import Axios from 'axios'; 
import serverURL from '../../../serverURL.json';
import { withCookies } from 'react-cookie';



const useStyles = makeStyles((theme) => ({  
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent:'center'
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    content:{ 
      outlineStyle: 'auto',
      
    },
    listItem: { 
        outlineStyle: 'auto', 
        ml:2
    },
    Header: { 
        fontSize: "18px", 

    },
    AppNameInput: { 
        fontSize: "20px"
    },
    TextField:{ 
        display: "flex", 
        float: 'right',
        width: '90%'
    }  }));

function AppManageDetailInfoTabV2(props) {
    const classes = useStyles();
    const appID = props.appID;
    const [appInfo,setAppInfo] = React.useState({
        name_Temp:"",
        description_Temp:"",
        feature_Temp:[],
        advantage_Temp:[],
        function_Temp:[],
        release_Temp:[],
        hwReq_Temp: {os:"",memory:""},
        category_Temp:[],
        companyCI_Temp:"",
        company_Temp:"",
        isOnpremise_Temp:false,
        launcher_api_Temp:[],
        isAPI_Temp:false
    });
    
    React.useEffect(()=>{ 
        if(appID !=="")
        {
            let params = {};
            let key = 'params';
            let value = {
                appID : appID
            }
            params[key] = value;
            Axios.get('api/getAppInfo_Temp', params)
            .then(response =>{
                const returnResult = response.data;
                if(returnResult.result)
                {
                    setAppInfo({...appInfo,...returnResult}); 
                    console.log(returnResult)
                }
              }).catch((error) =>{
             
                  alert('Unexpected Access, Need to refresh page getAppInfo');
            
                console.log(error);
            }
        );
    
    }},[]);

    const handleChange =(e)=>{ 
        let newProp={}; 
        newProp[e.target.name] = e.target.value; 
        setAppInfo({...appInfo,...newProp})
    }
    const handleArrayValueChange = (index, propName, value) => { 
        let tempAppInfo = {...appInfo}; 
        tempAppInfo[propName][index] = value; 
        setAppInfo(tempAppInfo);
      }
    const addProp2Arr =(propName, prop) =>{ 
        console.log(appInfo)
        let appCopied = {...appInfo}; 
        appCopied[propName].push(prop); 
        setAppInfo(appCopied);
    }
    const onDeleteArrValue = (index, propName) => { 
        let tempAppInfo = {...appInfo}; 
        tempAppInfo[propName].splice(index,1)
        setAppInfo(tempAppInfo);
    }
    const appPicOrProp = (propName,prop)=>{ 

    if(window.confirm("Would you like to input an image?"))
    {
        addProp2Arr(propName, "data:image/jpg;base64,");
    }else
    {
        addProp2Arr(propName,prop);
    }
    }
      const handleUploadClick_Icon = (event,propName) =>
      {
          var file = event.target.files[0];
          if(file !== undefined)
          {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              // const format_All = ((file.type).split("/"))
              // const foramtName = format_All[format_All.length-1];
    
              reader.onloadend = function(e){
                  let picBase64 = [reader.result][0];
                  let newProp ={}; 
                  newProp[propName] = picBase64;
                  setAppInfo({...appInfo, ...newProp});
      
              }
          }
      }

      const objArrChangeHandler =(e, index, obj, key) =>{ 
        let appCopied = {...appInfo}; 
        appCopied[obj][index][key] = e.target.value; 
        setAppInfo(appCopied);
      }

      const onDeleteObjArrValue = (e, index, objName) => { 
        let appCopied = {...appInfo}; 
        appCopied[objName].splice(index,1); 
        setAppInfo(appCopied);
      }
    
      const onChangePropHandler = (e,objName, keyName)=> { 
        let appCopied = {...appInfo} 
        appCopied[objName][keyName] = e.target.value; 
        setAppInfo(appCopied)
      }

    return (
        <div>
            <Grid container spacing = {2} className = {classes.content} > 
                <Grid item xs = {12} className = {classes.content}>
                    <FormControl sx={{width:"100%"}}> 
                    <Input value = {appInfo.name_Temp} name = {"name_Temp"} onChange ={handleChange} sx={{fontSize: "22px"}} fullWidth={true}/>
                    {appInfo.name_Temp===""?<InputLabel variant ='filled' sx ={{fontSize:"18px"}} shrink ={false} disableAnimation ={true}> App Name</InputLabel>:
                    null}
                    </FormControl>
                </Grid>
                <Grid item xs ={12} > 
                    <Typography variant ="h6" component ="div" >
                        ㅁ Icon
                    </Typography>
                    <span className = {classes.listItem}>
                        <img src={appInfo.icon_Temp} alt=""  width="100"  onClick={()=>this.handleShowIMGDialog(appInfo.icon_Temp)}/>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            style={{display: 'block'}}
                            onChange={(e) => handleUploadClick_Icon(e, "icon_Temp")}
                        />
                    </span>
                </Grid>
                <Grid item xs ={12} className ={classes.content}> 
                    <Typography variant ="h6" component ="div">                                
                        ㅁ Description
                    </Typography>
                <TextField 
                    label ={appInfo.description_Temp? appInfo.description_Temp.length+"/500":"/500"}
                    inputProps={{ maxLength: 500 }}
                    value = {appInfo.description_Temp}
                    onChange = {handleChange}
                    name="description_Temp"
                    id="description_Temp"

                    multiline = {true} 
                    sx={{ml:2,mt:1, width: "90%"}}
                    
                    />
              
                </Grid>
                <Grid item xs={12} className = {classes.content}>
                    <Typography variant ="h6" component ="div">                                
                        ㅁ Feature
                        <IconButton color="primary"  size="large" onClick = {(e)=>addProp2Arr("feature_Temp", "")}>
                            <AddCircleIcon fontSize= 'small'/>
                        </IconButton>
                    </Typography>
                    <SubTextField 
                        properties = {appInfo.feature_Temp} 
                        onChangeHandler = {handleArrayValueChange} 
                        propName = "feature_Temp"
                        onClickDeleteHandler = {onDeleteArrValue} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant ="h6" component ="div">                                
                        ㅁ Advantage
                        <IconButton color="primary"  size="large" onClick = {(e)=>appPicOrProp("advantage_Temp", "")}>
                            <AddCircleIcon fontSize= 'small'/>
                        </IconButton>
                    </Typography>
                    <SubTextField 
                        properties = {appInfo.advantage_Temp} 
                        onChangeHandler = {handleArrayValueChange} 
                        propName = "advantage_Temp"
                        onClickDeleteHandler = {onDeleteArrValue} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant ="h6" component ="div">                                
                        ㅁ Function
                        <IconButton color="primary"  size="large" onClick = {(e)=>appPicOrProp("function_Temp", "")}>
                            <AddCircleIcon fontSize= 'small'/>
                        </IconButton>
                    </Typography>
                    <SubTextField 
                        properties = {appInfo.function_Temp} 
                        onChangeHandler = {handleArrayValueChange} 
                        propName = "function_Temp"
                        onClickDeleteHandler = {onDeleteArrValue} />
                </Grid>
                <Grid item xs ={12}> 
                    <Typography variant ="h6" component ="div">                                
                        ㅁ Release
                        <IconButton color="primary"  size="large" onClick = {(e)=>addProp2Arr("release_Temp", {version:"",date:"",content:""})}>
                            <AddCircleIcon fontSize= 'small'/>
                        </IconButton>
                    </Typography>
                    <SubTextFieldObjContainer 
                        onChangeHandler = {objArrChangeHandler} 
                        onDeleteHandler = {onDeleteObjArrValue}
                        objArr = {appInfo.release_Temp} 
                        objName={"release_Temp"}
                    />
                </Grid> 
                <Grid item xs ={12}> 
                    <Typography variant ="h6" component ="div">                                
                    ㅁ Hardware Requirement
                    </Typography>
                    <SubTextFieldObj  
                      object ={appInfo.hwReq_Temp} objName = "hwReq_Temp"  onChangeHandler = {onChangePropHandler} 
                    />
                </Grid> 
                <Grid item xs ={12}> 
                    <Typography variant ="h6" component ="div">                                
                    ㅁ Category
                    <IconButton color="primary"  size="large" onClick = {(e)=>addProp2Arr("category_Temp", "")}>
                            <AddCircleIcon fontSize= 'small'/>
                    </IconButton>
                    </Typography>
                   
                    <SubTextField 
                        properties = {appInfo.category_Temp} 
                        onChangeHandler = {handleArrayValueChange} 
                        propName = "category_Temp"
                        onClickDeleteHandler = {onDeleteArrValue} />
                </Grid> 
                <Grid item xs = {12} >
                <Typography variant ="h6" component = "span" >
                    ㅁ Company
                </Typography>
            
                <List sx={{ width: '100%',   outline: "#d3d3d3 solid 1px" }}
                    aria-labelledby="nested-list-subheader"
                    component = "nav"
                    dense ={true}
                    disablePadding={true}>
                    <ListItem  sx={{paddingLeft:"45px", outlineStyle:'auto'}}>
                    <TextField  
                    value = {appInfo.company_Temp} 
                    name = "company_Temp" 
                    onChange={(e)=> {handleChange(e)}}
                    variant="standard" 
                    label ="Company Name"
                    className ={classes.TextField}
                    InputLabelProps={{color: "primary"}}
                    />    </ListItem><ListItem  sx={{paddingLeft:"45px", outlineStyle:'auto'}}>
                    <span >
                    <img src={appInfo.companyCI_Temp} alt=""  width="100"  onClick={()=>this.handleShowIMGDialog(appInfo.companyCI_Temp)}/>
                    <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    sx={{display: 'block'}}
                    onChange={(e)=> handleUploadClick_Icon(e,"companyCI_Temp")}
                    />
                    </span></ListItem></List>
             </Grid>
             <Grid item xs = {12} >
                <Typography variant ="h6" component = "div" >
                    ㅁ Operation
                </Typography> 
            </Grid>
            </Grid>
        </div>
    )
}

export default withCookies(AppManageDetailInfoTabV2);
