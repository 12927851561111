import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Footer from './Footer';
import CircularProgress from '@mui/material/CircularProgress';
import Axios from 'axios';
import { withCookies } from 'react-cookie';
import withStyles from '@mui/styles/withStyles';

import serverURL from '../serverURL.json';
import { Typography } from '@mui/material';

import * as Yup from 'yup'; 
import { typography } from '@mui/system';

const SignInSchema = Yup.object().shape({
  id: Yup.string().required('Please Enter your Email Address'),
});


const styles = theme =>({
   
    root: {
        height: '100vh',
        justifyContent:'center',
        height:'80%',
        marginTop: theme.spacing(5)
      },
      image: {
        backgroundImage: `url(${'/img/mainIMG.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
          theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
  });


  class SignInSide extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: "",
      };
  
      this.handleValueChange = this.handleValueChange.bind(this);
      this.enterPressEvent = this.enterPressEvent.bind(this);
  
    }


    handleValueChange(e){
      let nextState = {};
      nextState[e.target.name] = e.target.value;
      this.setState(nextState);
    }
  
    enterPressEvent(e){
       if (e.key === 'Enter') {
        this.resetPassword();
      }
    }
    
    resetPassword = (e) =>{
        e.preventDefault()
        let params = new URLSearchParams();
        params.append('email', this.state.email);
       
        Axios.post(serverURL.api + '/resetPassword',{
          email: this.state.email
        }).then(
          response =>{
            console.log(response)
            //alert('Your New Password has been sent to your email address.'); 
            this.props.history.push('/signin');}
          ).catch((error) =>{
            if(error.status === 401){
              alert(`Please check your e-mail account`);
            }

          });
    }
    
  
    render(){
      const {classes} = this.props;
      const imgKRCI = '/img/krCI.jpg';

    return (
        <Grid container component="main" className={classes.root}>
        <CssBaseline />
        {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}
        <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square >
         
          <div className={classes.paper}>
          {/* <Link href="https://www.krs.co.kr/main/main.aspx"> */}
          <Link href="/">
            <img src= {imgKRCI} alt="Logo"   width = "125" height = "58"/>
          </Link>
            <Link href="/" underline ="none">
            <span style={{    fontSize: 'calc(1.5vw + 3vh)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  whiteSpace : 'pre-wrap',
                  // fontFamily : 'Tahoma, Verdana, serif;'
                  color : '#0088C7',
                  /*fontFamily : 'Times, Times New Roman, Georgia, serif'*/}} >
              SeaTrust
          </span>
  
          </Link>
  
            <typography>Enter your user account's verified email address and we wiil send you a new password. </typography>
                      
            <form className={classes.form} >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="e-mail"
                name="email"
                autoComplete="Please Enter your Email Account."
                autoFocus
                onKeyPress ={this.enterPressEvent}
                onChange = {this.handleValueChange}
              />
              
              
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={this.resetPassword}
                  className={classes.submit}
                >
                  Rest Password
                </Button>
              <Box mt={5}>
                <Footer />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
  }
  
  
  
  export default withCookies(withStyles(styles)(SignInSide));

// export default function SignInSide() {
//   const classes = useStyles();
//   const imgKRCI = '/img/krCI.jpg';
//   let [isDoing, setIsDoing] = useState(false);

//   function forgetPW(){
//       alert('Please contact "Support".')
//   }


//   function logInEvent(){
    // setIsDoing(isDoing = true);
    // let params = new URLSearchParams();
    // params.append('id', this.state.id);
    // params.append('password', this.state.password);
    // Axios.post('http://localhost:5000/api' + '/logIn', params).then(
    //     response =>{
    // setIsDoing(isDoing = false);

    //     if(response.status === 200)
    //     {
    //         const returnResult = response.data;

    //         if(returnResult.result)
    //         {
    //             let userData = {
    //             _id: returnResult._id,
    //             refToken : returnResult.refToken,
    //             accToken : returnResult.accToken,
    //             auth : true,
    //             };
    //             let langData ={
    //             lang : returnResult.language
    //             }
    //             const { cookies } = this.props;
    //             cookies.set('user', userData, { path: '/', expires: 0});
    //             cookies.set('language', langData, { path: '/' , maxAge: 3600 });
    //             this.props.history.push('/');
    //         }else
    //         {
    //         alert(returnResult.alert)
    //         }
    //     }else
    //     {
    //         alert('engResponseStatusNot200');
    //     }
    //     }
    // ).catch((error) =>{
    //         alert('Unexpected Access, Need to refresh page sign up');

    //     }
    // );

   



//   }



//   return (
    // <Grid container component="main" className={classes.root}>
    //   <CssBaseline />
    //   <Grid item xs={false} sm={4} md={7} className={classes.image} />
    //   <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
       
    //     <div className={classes.paper}>
    //     {/* <Link href="https://www.krs.co.kr/main/main.aspx"> */}
    //     <Link href="/">
    //       <img src= {imgKRCI} alt="Logo"   width = "125" height = "58"/>
    //     </Link>
    //       {/* <Avatar className={classes.avatar}>
    //         <LockOutlinedIcon />
    //       </Avatar>
    //       <Typography component="h1" variant="h5">
    //         SeaTrust
    //       </Typography> */}
    //       {/* <span className = {classes.HeadLine} > */}

    //       <Link href="/">
    //       <span style={{    fontSize: 'calc(1.5vw + 3vh)',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     whiteSpace : 'pre-wrap',
    //     // fontFamily : 'Tahoma, Verdana, serif;'
    //     color : '#0088C7',
    //     fontFamily : 'Times, Times New Roman, Georgia, serif'}} >
    //         SeaTrust
    //     </span>



    //     </Link>

        
                    
    //       <form className={classes.form} noValidate>
    //         <TextField
    //           variant="outlined"
    //           margin="normal"
    //           required
    //           fullWidth
    //           id="email"
    //           label="ID, Email Address"
    //           name="email"
    //           autoComplete="email"
    //           autoFocus
    //         />
    //         <TextField
    //           variant="outlined"
    //           margin="normal"
    //           required
    //           fullWidth
    //           name="password"
    //           label="Password"
    //           type="password"
    //           id="password"
    //           autoComplete="current-password"
    //         />
    //         {/* <FormControlLabel
    //           control={<Checkbox value="remember" color="primary" />}
    //           label="Remember me"
    //         /> */}
    //         {
    //           isDoing
    //           ?
    //           <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
    //             <CircularProgress />
    //           </div>
    //           :
    //           <Button
    //           //   type="submit"
    //             fullWidth
    //             variant="contained"
    //             color="primary"
    //             onClick={logInEvent}
    //             className={classes.submit}
    //           >
    //             Sign In
    //           </Button>
    //         }

    //         <Grid container>
    //           <Grid item xs>
    //             <Link href="#" variant="body2" onClick = {forgetPW}>
    //               Forgot password?
    //             </Link>
    //           </Grid>
    //           <Grid item>
    //             <Link href="/Signup" variant="body2">
    //               {"Don't have an account? Sign Up"}
    //             </Link>
    //           </Grid>
    //         </Grid>
    //         <Box mt={5}>
    //           <Footer />
    //         </Box>
    //       </form>
    //     </div>
    //   </Grid>
    // </Grid>
//   );
// }