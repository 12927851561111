// import React, {Component} from 'react';

// class Main extends Component{
//   render(){
//     return (
//       <div>
//         Hello World
//       </div>
//     );
//   }
// }


// export default Main;





import React, {useState, useEffect, Fragment} from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import ReactPlayer from 'react-player'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import StopIcon from '@mui/icons-material/Stop';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Axios from 'axios';
import Footer from './Footer';
import Header from './Header';


import serverURL from '../serverURL.json';

///APP 수정 ejlee
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
///

const _Info1_Name = "KR PR Film";
const _Info1_URL = "https://www.youtube.com/playlist?list=PLox3tKDEKrkoLWOAcEFFTt8V1e4zH0Yzt";

const _Info2_Name = "KR Cyber Security";
const _Info2_URL = "https://www.youtube.com/playlist?list=PLox3tKDEKrkrspbQOoMWyuRnQqg9_9mQH";

const _Info3_Name = "KR Solutions";
const _Info3_URL = "https://youtu.be/Bk-syN55ty4?si=gDUhYfoJlRjf1MXG";

const _Info4_Name = "KR SeaTrust";
const _Info4_URL = "https://www.youtube.com/playlist?list=PLox3tKDEKrkrfvpwbj6H2lCYCk3gxk3Iu";





const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    color : '#0088C7',
    font : 'bold',
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundImage: `url(${'/img/mainIMG.jpg'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
  HeadLine : {
    fontSize: 'calc(2vw + 2vh)',
    // color :'#0088C7'
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace : 'pre-wrap'
  },
  HeadLine_Sub : {
    fontSize: 'calc(1vw)',
  }

}));

// const tiers = [
//   {
//     title: 'Free',
//     price: '0',
//     description: ['10 users included', '2 GB of storage', 'Help center access', 'Email support'],
//     buttonText: 'Sign up for free',
//     buttonVariant: 'outlined',
//   },
//   {
//     title: 'Pro',
//     subheader: 'Most popular',
//     price: '15',
//     description: [
//       '20 users included',
//       '10 GB of storage',
//       'Help center access',
//       'Priority email support',
//     ],
//     buttonText: 'Get started',
//     buttonVariant: 'contained',
//   },
//   {
//     title: 'Enterprise',
//     price: '30',
//     description: [
//       '50 users included',
//       '30 GB of storage',
//       'Help center access',
//       'Phone & email support',
//     ],
//     buttonText: 'Contact us',
//     buttonVariant: 'outlined',
//   },
// ];



export default (function Homepage(props) {
  
    // const { cookies } = props;
  const classes = useStyles();
  // const imgKRCI = '/img/krCI.gif';
  const imgMainIMG = '/img/mainIMG.jpg';
//   user : cookies.get('user') ? cookies.get('user') : {_id: "", refToken :"", accToken:"", auth: false} ,
  // state = {
  //   showMain: false,
  // };

  








  let [showMain, setShowMain] = useState(false)
  let [newsList, setNewsList] = useState([])
  let [appList, setAppList] = useState([])
  let [numNewsCard, setNumNewsCard] = useState(4)
  let [numAppCard, setNumAppCard ] = useState(4)

  function moreNews() {
    setNumNewsCard(numNewsCard = numNewsCard + 4);
    // this.setState(() => ({ showMain: true }));
  }
  function moreApp() {
    setNumAppCard(numAppCard = numAppCard + 4);
  }

  useEffect( async () => {
    ///App 수정 ejlee
     await Promise.all([axios_getNews(),axios_getApp("view","")]);
    
      ///
    // setUser(user = cookies.get('user'))
    


  // window.addEventListener('mousemove', () => {});

  // // returned function will be called on component unmount 
  // return () => {
  //   window.removeEventListener('mousemove', () => {})
  // }
}, [])

  
  async function axios_getNews(){
    let params = {};
    let key = 'params';
    let value = {
    }
    params[key] = value;

    await Axios.get(serverURL.api+'/getNews', params).then(
      response =>{
        if(response.status === 200)
        {
          const returnResult = response.data;

          if(returnResult.result)
          {
            
            setNewsList(newsList = returnResult.newsList)
            console.log(newsList);
          }else
          {
            alert(returnResult.alert)
          }
        }else
        {
          alert('engResponseStatusNot200');
        }
      }
    ).catch((error) =>{
      alert(error);
      }
    );
  }
async function axios_getApp(_sortType, _searchString){
  let params = {};
        let key = 'params';
        let value = {
            sortType : _sortType,
            searchString : _searchString
        }
        params[key] = value;
        await Axios.get(serverURL.api+'/getAppList', params).then(
        response =>{
            if(response.status === 200)
            {
            const returnResult = response.data;

            if(returnResult.result)
            {
                setAppList(appList = returnResult.appList);
            }else
            {
                alert(returnResult.alert)
            }
            }else
            {
            alert('engResponseStatusNot200');
            }
        }
        ).catch((error) =>{
          console.log(error);
        alert('Unexpected Access, Need to refresh page getAppList');
        }
        );
}
  function playVideo() {
    setShowMain(showMain = !showMain);
    // this.setState(() => ({ showMain: true }));
  }


  function News_Card(news ){
    // let date = "";
    // let getDate = (new Date(parseInt((news.date).substring(0, 8), 16) * 1000));
    // date  =  moment(getDate).format('YYYY-MM-DD HH:mm:ss');
    // date  =  moment(news.date).format('YYYY-MM-DD');


    function goNews(){
      // document.location.href = news.url;
      // document.location.href = news.url;
      window.open(news.url)

    }
   

      {/* return <Fragment style = {{ padding : '3%'}}> */}
      return <Grid item xs={12} md={6}>
      <Card onClick = {goNews} style = {{ display: 'flex' , margin : 10}}>
      <CardActionArea>
      <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center"
                }}
              >
        <CardMedia
          // component='img'
          // imageUrl = {news.img}
          // square
          // image = {'https://images.pexels.com/photos/67636/rose-blue-flower-rose-blooms-67636.jpeg'}
          // src={news.img}
          style={{width: "auto", maxHeight: "200px"}}
        >
                <img src={news.img} style={{width: "auto", maxHeight: "200px"}} />

          </CardMedia>

        </div>

        <CardContent>
          {/* <Typography gutterBottom variant="h5" component="h2">
          {news.title}
          </Typography> */}
          <Typography variant="body2" color="textSecondary" component="p">
            {/* {date} */}
            {news.date}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          {news.title}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
    </Card>
    </Grid>

  };

  function goLink(_link)
  {
    window.open(_link)
  }
/// APP 수정 ejlee
function App_Card(app ){
    
  function goApp(){
    document.location.href = '/App/' + app._id;

  }
  return (
    <Grid item xs={12} md={3} style = {{marginBottom: 10}}>
    <Card onClick = {goApp}  style = {{height: "100%", margin: 5}}>
    <CardActionArea>
    <CardHeader
    avatar={
        <Avatar style={{ height: '35px', width: '35px' }} src={app.icon} />
    }
    title= {app.name}
    subheader={app.company}
    action={
        <Fragment>
            <IconButton aria-label="settings" size="large">
            <img src= {app.companyCI} alt="Logo"   width = "50" height = "23.2"/>
            </IconButton>
        </Fragment>
        }
    />
    </CardActionArea>

    </Card>
  </Grid>
  );

};
///

  return (
    <React.Fragment>
      <Header/>
      
      <Container>
      <br/>
      {/* <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${'https://source.unsplash.com/random'})` }}> */}
      <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: imgMainIMG }}>
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} src={imgMainIMG} alt={'main image description'} />}
      {/* {<img style={{ display: 'none' }} src={'https://source.unsplash.com/random'} alt={'main image description'} />} */}
      <div className={classes.overlay} />
      <Grid container>
        <Grid item md={6}>
          <div className={classes.mainFeaturedPostContent}>
            <Link variant="subtitle1" href="#" onClick={playVideo}>
            {
              showMain
              ?
              <StopIcon  color="action"/>
              // <StopIcon style ={{'color' :  "inherit"}}/>
              :
              <PlayCircleFilledIcon color="action"/>
            } 
            </Link>
            {
              showMain
              ?
              // <ReactPlayer url='https://www.youtube.com/watch?v=rTfIx85oNW8' width='100%'  />
              <ReactPlayer url='https://youtu.be/rTfIx85oNW8' width='100%'  />
              
              :
              null
            }
            {/* #0088C7 */}
            {/* <span className = {classes.HeadLine} >
            Providing the best services, Creating a better world
            </span>
            <span className = {classes.HeadLine_Sub} >
            Promotion of technology for ships and land and offshore facilities, safety of people and property, and environmental protection
            </span> */}
            <Typography component="h1" variant="h3" color="white" gutterBottom>
            Providing the best services, Creating a better world
            </Typography>
            <Typography variant="h6" color="white" paragraph>
            {/* Promotion of technology for ships and land and offshore facilities, safety of people and property, and environmental protection */}
            </Typography>
            {/* <video controls src="https://www.youtube.com/watch?v=ulQbO4Pi0zs"> Your user agent does not support the HTML5 Video element. </video> */}

          </div>
        </Grid>
      </Grid>
    </Paper>
    </Container>
    <Container >
<br/>
<Typography variant="h5" color="inherit" style={{ fontWeight: 600 }}>
      Apps
    </Typography>
          <Grid container spacing={2}>
              
              <Grid item container direction="row" >
              
              {
                appList === null
                ?

                  <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
                        <CircularProgress />
                      </div>
                    :
                    
                    appList.length
                    ?
                  
                    appList.map(app=>{

                    return App_Card(app);
                    })

                    :
                    <span >
                      No List
                    </span>

                  }
                      </Grid>
              {/*
                appList ===null
                ?
                null
                :
              numAppCard >= appList.length
              ?
              null
              :
              <Button style = {{marginLeft: "auto",display: 'flex', justifyContent: 'flex-end'}} onClick ={moreApp}>
                  More...
              </Button>
              */}


          </Grid>
  </Container>
    <Container>
      <br/><br/>

      <Typography variant="h5" color="inherit" style={{ fontWeight: 600 }}>
        Tech. News
      </Typography>
      <Grid item container direction="row">
      {
      newsList
      ?
      newsList.slice(0, numNewsCard).map(news=>{

      return  News_Card(news);
      })
      :
      <div>

      </div>
      }
      </Grid>
      {
        // numNewsCard >= newsList.length
        // ?
        // null
        // :
      <Button style = {{marginLeft: "auto",display: 'flex', justifyContent: 'flex-end'}} onClick ={moreNews}>
        More...
      </Button>
      }
      
  
     
    </Container>

    <Container>
      <br/>
    <Typography variant="h5" color="inherit" style={{ fontWeight: 600 }}>
      Information
    </Typography>
    <Grid item container direction="row" >
      <Grid item xs={12} md={3}>
      {/* <Grid item xs={12} md={6}> */}
      {/* <Grid item xs={6} md={3}> */}
        <Card style = {{ display: 'flex' , margin : 10}}>
          <CardActionArea onClick = {()=>goLink(_Info1_URL)}>
          <div
                    style={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center"
                    }}
                  >
          <ReactPlayer url={_Info1_URL} width='100%' height = '200px' />
          </div>
          <CardContent>
            <Typography variant="body2" color="textSecondary" >{/*component="p"*/}
            {_Info1_Name}
            </Typography>
          </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} md={3}>
      {/* <Grid item xs={6} md={3}> */}
      {/* <Grid item xs={12} md={6}> */}
        <Card style = {{ display: 'flex' , margin : 10}}>
          
          <CardActionArea onClick = {()=>goLink(_Info2_URL)}>
          <div
                    style={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center"
                    }}
                  >
          <ReactPlayer url={_Info2_URL} width='100%' height = '200px' />
          </div>
          
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
            {_Info2_Name}
            </Typography>
          </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} md={3}>
      {/* <Grid item xs={6} md={3}> */}
      {/* <Grid item xs={12} md={6}> */}
        <Card style = {{ display: 'flex' , margin : 10}}>
          <CardActionArea onClick = {()=>goLink(_Info3_URL)}>
          <div
                    style={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center"
                    }}
                  >
          <ReactPlayer url={_Info3_URL} width='100%' height = '200px'  />
          </div>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
            {_Info3_Name}
            </Typography>
          </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} md={3}>
      {/* <Grid item xs={6} md={3}> */}
      {/* <Grid item xs={12} md={6}> */}
        <Card style = {{ display: 'flex' , margin : 10}}>
        <CardActionArea onClick = {()=>goLink(_Info4_URL)}>
          <div
                    style={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center"
                    }}
                  >
          <ReactPlayer url={_Info4_URL} width='100%' height = '200px'  />
          </div>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
            {_Info4_Name}
            </Typography>
          </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
      
    </Container>
     {/* App 수정 ejlee */}

      <Footer/>
    </React.Fragment>
  );
})

