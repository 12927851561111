import React, {Component} from 'react';
import { CookiesProvider } from 'react-cookie';

import ViewRouter from '../ViewModel/ViewRouter'

class MainView extends Component{
  render(){
    return (
        <CookiesProvider>
          <div style={{ minHeight: '100vh'}}>
          <ViewRouter/>
          </div>
        </CookiesProvider>
    );
  }
}

export default MainView;