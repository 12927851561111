import React, { Component } from 'react';
import Axios from 'axios';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Footer from './Footer';
import Header from './Header'
import { withCookies } from 'react-cookie';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { delete_cookie } from 'sfcookies';
import CircularProgress from '@mui/material/CircularProgress';

import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import serverURL from '../serverURL.json';



const styles = theme =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  HeadLine : {
    fontSize: 'calc(1vw + 1.5vh)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace : 'pre-wrap'
  }

});


class UserSetting extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      user : cookies.get('user') ? cookies.get('user') : {_id: "", refToken :"", accToken:"", auth: false} ,

        name : "",
        company : "",
        profilePic : "",

        email : "",

        currentPW :"",
        newPW :"",
        newPW_Check :"",
        
        isDoingSaveAccount : false


      }
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleUploadClick = this.handleUploadClick.bind(this);

    this.changePW = this.changePW.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeCompany = this.changeCompany.bind(this);


    }

    changePW(){
      if(this.state.newPW !== this.state.newPW_Check)
      {
        alert('The new password do not match.')
      }else
      {
        if(this.state.newPW === "")
        {
          alert('Please enter new password')

        }else
        {
          this.setState({isDoingSaveAccount : true});

          let params = new URLSearchParams();
          params.append('userID', this.state.user._id);
          params.append('refToken', this.state.user.refToken);
          params.append('accToken', this.state.user.accToken);
          params.append('currentPW', this.state.currentPW);
          params.append('newPW', this.state.newPW);
    
          Axios.post(serverURL.api + '/editUserPW', params).then(
            response =>{
          this.setState({isDoingSaveAccount : false});

              if(response.status === 200)
              {
                const returnResult = response.data;
    
                if(returnResult.result)
                {
                  alert('Password is changed. Please log in using new password')
                  delete_cookie('user');
                  window.location.href = '/Signin';
                }else
                {
                  alert(returnResult.alert)
                }
              }else
              {
                alert('engResponseStatusNot200');
              }
            }
          ).catch((error) =>{
              alert('Unexpected Access, Need to refresh page User Setting 4');
            }
          );



        }

        

      }
    
      
    
    }

    changeName(){
      if(this.state.name === "")
      {
        alert('Please enter name')
      }else
      {

        let params = new URLSearchParams();
        params.append('userID', this.state.user._id);
        params.append('refToken', this.state.user.refToken);
        params.append('accToken', this.state.user.accToken);
        params.append('name', this.state.name);
        Axios.post(serverURL.api + '/editUserName', params).then(
          response =>{
            if(response.status === 200)
            {
              const returnResult = response.data;

              if(returnResult.result)
              {
                alert('The name is changed.')
                window.location.reload(true);
              }else
              {
                alert(returnResult.alert)
              }
            }else
            {
              alert('engResponseStatusNot200');
            }
          }
        ).catch((error) =>{
            alert('Unexpected Access, Need to refresh page User Setting 4');
          }
        );

      }
      
    
    
    }

    changeCompany(){
      if(this.state.company === "")
      {
        alert('Please enter company')

      }else
      {

        let params = new URLSearchParams();
        params.append('userID', this.state.user._id);
        params.append('refToken', this.state.user.refToken);
        params.append('accToken', this.state.user.accToken);
        params.append('company', this.state.company);
        Axios.post('/api'+ '/editUserCompany', params).then(
          response =>{
            if(response.status === 200)
            {
              const returnResult = response.data;
  
              if(returnResult.result)
              {
                alert('The company is changed.')
                window.location.reload(true);
              }else
              {
                alert(returnResult.alert)
              }
            }else
            {
              alert('engResponseStatusNot200');
            }
          }
        ).catch((error) =>{
            alert('Unexpected Access, Need to refresh page User Setting 4');
          }
        );

      }
    
    
    
    }



    handleUploadClick(event){
      var file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const format_All = ((file.type).split("/"))
      const foramtName = format_All[format_All.length-1];

      reader.onloadend = function(e){
        let picBase64 = [reader.result][0];

        let params = new URLSearchParams();
        params.append('userID', this.state.user._id);
        params.append('refToken', this.state.user.refToken);
        params.append('accToken', this.state.user.accToken);
        params.append('formatName', foramtName);
        params.append('profilePic', picBase64);

        
        Axios.post('/api'+ '/editProfilePic', params).then(
          response =>{
            if(response.status === 200)
            {
              const returnResult = response.data;

              if(returnResult.result)
              {
                  window.location.reload(true);
              }else
              {
                
                alert(returnResult.alert)
              }
            }else
            {
              alert('engResponseStatusNot200');
            }
          }
        ).catch((error) =>{
            alert('Unexpected Access, Need to refresh page User Setting 4');
          }
        );




      }.bind(this)
    };


    handleValueChange(e){
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    }


    componentWillMount()
    {

      let params = {};
      let key = 'params';
      let value = {
          'userID' : this.state.user._id,
          'refToken' : this.state.user.refToken,
          'accToken' : this.state.user.accToken,
      }
      params[key] = value;
      Axios.get('/api' + '/getUserNameCompanyIMG', params).then(
        response =>{
          if(response.status === 200)
          {
              const returnResult = response.data;
          if(returnResult.result)
          {
            this.setState({name: returnResult.name, company: returnResult.company , profilePic : returnResult.profilePic  })
            
              
          }else
          {
              alert(returnResult.alert)
          }
          }else
          {
            alert('engResponseStatusNot200');
          }
        }
      ).catch((error) =>{
        alert('Unexpected Access, Need to refresh page getid');
        }
      );
    }


   

  render(){
    const {classes} = this.props;

  return (
    <div>
  <Header user = {this.state.user}/>
  <br/>
  <Container component="main" maxWidth="xs">




  </Container>
  <Container component="main">
  <Typography component="h6" variant="h6" sx={{marginTop: '10px'}}>
      <PhotoCameraIcon  sx={{ fontSize: 17 }}/> Profile Picture
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs = {3}>
      <Typography component="subtitle1" variant="subtitle1">
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Button 
        component="label"
        >
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={<EditIcon/>}
          >
            <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            style={{display: 'none'}}
            onChange={this.handleUploadClick}
          />
          <Avatar src={this.state.profilePic}  />
          </Badge>
        </Button>
      </Typography>
      </Grid>
    </Grid>
    <br/>
    <Divider/>
    <Grid container>
    <Typography component="h6" variant="h6">
      <AccountCircleIcon  style={{ fontSize: 17 }}/> Account
    </Typography>
    </Grid>
    <br/>
    <Grid container spacing={2}>
      <Grid item xs = {5} sm = {5}>
      <Typography component="subtitle1" variant="subtitle1">
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Password
      </Typography>
      </Grid>
      <Grid container xs = {7} sm = {7} justifyContent="flex-end">
        <TextField
          variant="outlined"
          // required
          name="currentPW"
          label="Current Password"
          type="password"
          id="currentPW"
          onChange = {this.handleValueChange}
          color = "primary"
        />
      </Grid>
    
      <Grid container xs = {7} sm = {7}   style={{ marginLeft: "auto", display: 'flex', justifyContent: 'flex-end'}} >
        <TextField
          style={{marginTop: '5px'}}
          variant="outlined"
          // required
          name="newPW"
          label="New Password"
          type="password"
          id="newPW"
          onChange = {this.handleValueChange}
        />
      </Grid>

      <Grid container xs = {7} sm = {7}   style={{  marginLeft: "auto",display: 'flex', justifyContent: 'flex-end'}}  >
        <TextField
          style={{marginTop: '5px'}}
          variant="outlined"
          // required
          name="newPW_Check"
          label="New Password-Check"
          type="password"
          id="newPW_Check"
          onChange = {this.handleValueChange}
        />
      </Grid>
    </Grid>
    <br/>
    {
      this.state.isDoingSaveAccount
      ?
      <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
        <CircularProgress />
      </div>
      :
      <Button variant="outlined" color="primary" fullWidth onClick = {this.changePW}>
      Change Password 
      </Button>
    }
   
    <br/>
    <br/>
    <Grid container spacing={2}>
      <Grid item xs = {5} sm = {5}>
      <Typography component="subtitle1" variant="subtitle1">
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Name
      </Typography>
      </Grid>
      <Grid container xs = {7} sm = {7} justifyContent="flex-end">
        <TextField
          variant="outlined"
          // required
          name="name"
          label="Name"
          id="name"
          value={this.state.name}
          onChange = {this.handleValueChange}
        />
      </Grid>
    
    </Grid>
    <br/>
    <Button variant="outlined" color="primary" fullWidth onClick = {this.changeName}>
      Change Name 
    </Button>
    <br/>
    <br/>
    <Grid container spacing={2}>
      <Grid item xs = {5} sm = {5}>
      <Typography component="subtitle1" variant="subtitle1">
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Company
      </Typography>
      </Grid>
      <Grid container xs = {7} sm = {7} justifyContent="flex-end">
        <TextField
          variant="outlined"
          // required
          name="company"
          label="Company"
          id="company"
          value={this.state.company}
          onChange = {this.handleValueChange}
        />
      </Grid>
    
    </Grid>
    <br/>
    
  </Container>
  <Footer/>
  </div>
  );
}

}



export default withStyles(styles)(withCookies(UserSetting));
