import React, {useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Footer from '../Footer';
import Axios from 'axios';
import serverURL from '../../serverURL.json';
import { useFormik } from 'formik';
import { Divider, CircularProgress, Typography, Grid, IconButton } from '@mui/material';

import * as Yup from 'yup'; 
import SearchCompany from './SearchCompany'

const SignupSchema = Yup.object().shape({
  Id: Yup.string().required('Please Enter your ID').min(6),
  name: Yup.string()
    .required('Please Enter your name').min(2),
  password: Yup.string('Please Enter your password')
    .min(8)
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, at least one Number and one special case Character"
    )
    .required('Password is required'),
    email: Yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
    confirmPassword: Yup.string("Enter your password")
    .required("Confirm your password")
    .oneOf([Yup.ref("password")], "Password does not match"),
    company: Yup.string().required('Please Search Your Company')
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  search: { 
    margin: theme.spacing(1),
    height: '50px',
  }
  
}));

export default function SignUp(props) {
    const classes = useStyles();
    //const imgKRCI = '/img/krCI.jpg';
    const {terms} = props
    
    let [isDoing, setIsDoing] = useState(false);
    let [crmId, setCrm] = useState(""); 
    let [company, setCompany] = useState("");
    let [isModalOpen, setModalOpen] = useState(false)
    let [isIdDuplicate, setIsIdDuplicate] = useState(false);
    let [isMailDuplicate, setIsMailDuplicate] = useState(false);
    let [isNameDuplicate, setIsNameDuplicate] = useState(false);
    const SendAuthMail = function(e) { 
      
    }

    const OpenModal = function(e) {      
      setModalOpen(true);
    }
    const CloseModal = function() { 
      console.log(isModalOpen)
      setModalOpen(false);
    } 

    const SelectCompany = function(companyInfo){ 
      console.log(companyInfo)
      setCrm(companyInfo.crmId);
      setCompany(companyInfo.company)
      formik.values.company = companyInfo.company
    }
    const CheckDuplicate = (e, fieldName, isFormikError) => {
      e.persist();
      formik.handleChange(e); 
      if(!isFormikError) 
      {      
        const query = e.target.value;
        Axios.get('api/user'+ '/isIdExists' +`?${fieldName}=${query}`).then(
          res=>{ 
            if(res.status === 200){ 
              SetIsDuplicate(fieldName, false);
            }
            else { /** 에러페이지 만들어버리기 */}
          }
        ).catch(err=>{ 
          SetIsDuplicate(fieldName, true);

        });
      }
    }
    const SetIsDuplicate = (propName, input) =>{ 
      if(propName ==="Id"){ 
        setIsIdDuplicate(input); 
      }
      else if (propName === "email"){ 
        setIsMailDuplicate(input); 
      }
      else if(propName ==="name"){ 
        setIsNameDuplicate(input)
      }
    }
    const formik = useFormik({
      initialValues: {
        password: '',
        confirmPassword:'',
        email:'',
        Id:'',
        name:'',
        company:''
      },
      validationSchema: SignupSchema,
      onSubmit: (values) => {
          let params = new URLSearchParams();
          params.append('id', values.Id);
          params.append('email', values.email);
          params.append('pw', values.password);
          params.append('name', values.name);
          params.append('crmId', crmId);
          params.append('company', values.company)
       // console.log("company")
          let user_terms=[];
          let index = 0;
          terms.forEach(term=>{  

            let user_term = {}; 
            user_term['url'] = term.url; 
            user_term['term'] = term.term;
            user_term['checked'] = term.checked;

            user_terms.push(user_term);
            //params.append(`terms[${index}]`, user_term);
            index++;
          });
          
          params.append('terms', JSON.stringify(user_terms))
          setIsDoing(true);
          Axios.post( 'api/signup', params).then(
              response =>{
                setIsDoing(isDoing = false)
                if(response.status === 201)
                {
                    const returnResult = response.data;
                    
                    if(returnResult.result)
                    {
                      alert("Sign up completed. You can log-in after administrator's approval.")
                      this.props.history.push('/');

                        //document.location.href = `/signUp/Auth/${values.Id}`;
                    }else
                    {
                    alert(returnResult.alert)
                    }
                }else
                {
                    alert('engResponseStatusNot200');
                }
                }
          ).catch((error) =>{
            setIsDoing(isDoing = false)

              alert('Unexpected Access, Need to refresh page sign up');
          }
                );
              }                                                                                                                                                                                                                                                                                                                                                                                                                                                      
    });


    // function signupClick(){ 
    //     if(pw !== pwchk)
    //     {
    //         alert('Password and password check do not match.')
    //     }else
    //     {
    //         if(pw === "")
    //         {
    //             alert('Please enter Password')

    //         }else
    //         {
    //             if(id === "")
    //             {
    //                 alert('Please enter id')
    //             }else
    //             {
    //                 if(email ==="")
    //                 {
    //                     alert('Please enter email')
    //                 }else
    //                 {
    //                     if(!allowTos)
    //                     {
    //                         alert('You have to agree to use the service.')

    //                     }else
    //                     {
    //                       if(name === "")
    //                       {
    //                         alert('Please enter name')
    //                       }else
    //                       {
    //                         let params = new URLSearchParams();
    //                         params.append('id', id);
    //                         params.append('email', email);
    //                         params.append('pw', pw);
    //                         params.append('signDate', (new Date()).toUTCString());
    //                         params.append('name', name);
    //                         params.append('company', company);
    //                         setIsDoing(isDoing = true);
    //                         Axios.post(serverURL.api + '/signup', params).then(
    //                             response =>{
    //                         setIsDoing(isDoing = false);
                        
    //                             if(response.status === 200)
    //                             {
    //                                 const returnResult = response.data;
                        
    //                                 if(returnResult.result)
    //                                 {
    //                                     alert('Signup is complete. Once approved, it becomes available. If the value entered is incorrect or there is a problem, it may be rejected.')
    //                                     document.location.href = "/SignIn";
    //                                 }else
    //                                 {
    //                                 alert(returnResult.alert)
    //                                 }
    //                             }else
    //                             {
    //                                 alert('engResponseStatusNot200');
    //                             }
    //                             }
    //                         ).catch((error) =>{
    //                                 alert('Unexpected Access, Need to refresh page sign up');
                    
    //                             }
    //                         );


    //                       }
                           
                            
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }



  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
        <form className={classes.form}  onSubmit={formik.handleSubmit} >
          <Grid container spacing={2} >
            <Grid item xs = {12}></Grid>
            <Grid item xs = {12}>
              <Typography component="h3" variant="inherit">
                User Info
              </Typography>
              <Divider orientation="horizontal"></Divider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                required
                name="Id"
                variant="outlined"
                fullWidth
                id="Id"
                label="User id"
                autoFocus
                value={formik.values.Id}
                onChange={(e) => CheckDuplicate(e,"Id", formik.errors.Id)}
                error={isIdDuplicate || (formik.touched.Id && Boolean(formik.errors.Id))}
                helperText={isIdDuplicate ? 'Id already exists':formik.errors.Id}                
              />           
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="name"
                label="User name"
                autoComplete = "name"
                name="name"
                value={formik.values.name}
                onChange={(e) => CheckDuplicate(e,"name", formik.errors.name)}
                error={isNameDuplicate || (formik.touched.name && Boolean(formik.errors.name))}
                helperText={isNameDuplicate ? 'Username already exists':formik.errors.name}
              />
            </Grid> 
            <Grid item xs={12}>        
              <TextField
                name="email"
                variant="outlined"
                required
                fullWidth
                id="email"
                label="E-mail"
                value={formik.values.email}
                onChange={(e) => CheckDuplicate(e,"email",formik.errors.email)}
                error={isMailDuplicate||(formik.touched.email && Boolean(formik.errors.email))}
                helperText={isNameDuplicate? 'Email already exists':formik.errors.email}
              />            
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                required
                value={formik.values.password}
                autoComplete = "new-password"
                onChange={formik.handleChange}
                error={ formik.touched.password &&Boolean(formik.errors.password)}
                helperText={formik.errors.password}
              />
            </Grid> 
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                label="Password Confirmation"
                type="password"
                autoComplete = "new-passwordConfirm"
                required
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.errors.confirmPassword}
              />
            </Grid>
         
        
              <Grid item xs = {12}></Grid>
              <Grid item xs = {12}>
                <Typography component="h3" variant="inherit">
                  Company Info
                </Typography>
                <Divider orientation="horizontal"></Divider>
              </Grid>                      
              <Grid item xs = {8}>              
                <TextField
                  variant="outlined"
                  id="company"
                  label="Company"
                  autoComplete = "Company"
                  name="company"
                  fullWidth
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  error={formik.touched.company && Boolean(formik.errors.company)}
                  helperText={formik.touched.company && formik.errors.company}
                  
                  required
                />
              </Grid>
              <Grid item xs = "auto">
                <Button color= "primary" variant="contained"  className={classes.search} onClick = {OpenModal}>
                  Search      
                </Button>  
            </Grid>      
        
          </Grid>
          <br/>
          {
            isDoing
            ?
            <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
              <CircularProgress />
            </div>
            :
            <Button color="primary" variant="contained" fullWidth type="submit" className={classes.submit} >
              Sign up         
            </Button>  
          }
        </form>
      <Box mt={5}>
        <Footer />
      </Box>
      <SearchCompany isOpen ={isModalOpen} close = {CloseModal} SelectCompany = {SelectCompany}></SearchCompany>
    </Container>
  );
}