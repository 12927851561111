import React from 'react';
import ReactDOM from 'react-dom';
//import '../../public/css/App.css';
import Main from './View/Main';
import * as serviceWorker from './serviceWorker';

import { ThemeProvider } from '@mui/material/styles';
import { unstable_createMuiStrictModeTheme } from '@mui/material'; 
const theme = unstable_createMuiStrictModeTheme();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme = {theme}>
      <Main />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
