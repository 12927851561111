import React, {useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Footer from '../Footer';
import Axios from 'axios';
import serverURL from '../../serverURL.json';
import { useFormik } from 'formik';
import { Divider, CircularProgress, Typography, Grid, IconButton } from '@mui/material';

import { 
    useParams
} from "react-router-dom"
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  search: { 
    margin: theme.spacing(1),
    height: '50px',
  }
  
}));  

export default function ConfirmationCode(props) {

    const classes = useStyles();    
    let [authCode, setAuthCode] = useState("");

    //const imgKRCI = '/img/krCI.jpg';
    const {userId} = useParams();

    const onSubmit = function(e) {     
        e.preventDefault() 
        axios.get('/api/authCode', {params:
            
            {userId: userId, 
        authCode: authCode}}
        ).then(res=>{ 
            document.location.href = "/";
        }).catch(e=> { 
            alert("please check code")
        })
    }
    const onChangeCode = function(e) { 
        setAuthCode(e.target.value);
    }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <form className={classes.form}  onSubmit={onSubmit} >       
              <TextField
                variant="outlined"
                fullWidth
                id="AuthCode"
                name="AuthCode"
                onChange ={onChangeCode}
                label="Enter Autorization Code"
                required
              />
          
          <br/>
            <Button color="primary" variant="contained" fullWidth type="submit" className={classes.submit} >
                Submit      
            </Button>  
        </form>
      <Box mt={5}>
        <Footer />
      </Box>
    </Container>
  );
}