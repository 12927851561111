import React, {Component} from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

import AppManageDetailInfoTab from './AppManageDetailInfoTab';
import AppManageDetailPermissionTab from './AppManageDetailPermissionTab';
import AppManageDetailInfoTabV2 from './AppManagement/DetailInfo/AppManageDetailInfoTabV2';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component = 'div' p={3}>
            {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor:"right grey",
      },
      active_tabStyle:{
        width: '100%',
        color: 'white',
        backgroundColor: 'red',
        }
}));

function AppManageDetailTabs(props) {
  const [appID, setAppId] = React.useState(props.appID); 
  const [tabIndex, setIndex] = React.useState(0)
  const classes = useStyles(); 
  
  const tabChange = (e, newValue)=>{
    setIndex(newValue)
  }

  return (
    <div  className={classes.root}>
    <AppBar position="static" color="default">
      <Tabs
        value={tabIndex}
        onChange={tabChange}
        // variant="scrollable"
        // scrollButtons="on"
        variant= 'fullWidth'
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
      >
        <Tab label="Info" icon={<InfoIcon/>} {...a11yProps(0)} />
        <Tab label="Permission" icon={<CardMembershipIcon/>} {...a11yProps(1)} />
      </Tabs>
    </AppBar>
    <TabPanel value={tabIndex} index={0} >
      <AppManageDetailInfoTab key={props.appID} appID = {props.appID}/>
    </TabPanel>
    <TabPanel value={tabIndex} index={1}>
      <AppManageDetailPermissionTab key={props.appID} appID = {props.appID}/>
    </TabPanel>
  </div>
  )
}

export default AppManageDetailTabs

// class AppManageDetailTabs extends Component{
//     constructor(props) {
//         super(props);
//         this.state = {

//           appID : this.props.appID,
//           tabIndex : 0,

//         }
//         this.tabChange  = this.tabChange.bind(this)
//         }

//         tabChange(event, newValue){
//             this.setState({tabIndex : newValue})
//         }

//         render(){
//             const {classes} =  this.props;

//             return(

//                 <div className={classes.root}>
//                 <AppBar position="static" color="default">
//                   <Tabs
//                     value={this.state.tabIndex}
//                     onChange={this.tabChange}
//                     // variant="scrollable"
//                     // scrollButtons="on"
//                     variant= 'fullWidth'
//                     indicatorColor="primary"
//                     textColor="primary"
//                     aria-label="scrollable force tabs example"
//                   >
//                     <Tab label="Info" icon={<InfoIcon/>} {...a11yProps(0)} />
//                     <Tab label="Permission" icon={<CardMembershipIcon/>} {...a11yProps(1)} />
//                   </Tabs>
//                 </AppBar>
//                 <TabPanel value={this.state.tabIndex} index={0}>
//                   <AppManageDetailInfoTab key={this.state.appID} appID = {this.state.appID}/>
//                 </TabPanel>
//                 <TabPanel value={this.state.tabIndex} index={1}>
//                   <AppManageDetailPermissionTab key={this.state.appID} appID = {this.state.appID}/>
//                 </TabPanel>
//               </div>
//             )
//         }
// }

// export default withStyles(useStyles,{withTheme: true})((AppManageDetailTabs));




