import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import CheckCircle from '@mui/icons-material/CheckCircle'
import RadioButtonUncheked from '@mui/icons-material/RadioButtonUnchecked';

function TermDetail(props) {

    const {propId, propName, handleChange, state} = props;
   
    let isChecked = state

    const onClickCheck =(e)=>{ 
        handleChange(e);
    }

    return (
        <div>
            <Checkbox checked = {isChecked} onChange={onClickCheck} name= {propName} value = {propId} checkedIcon = {<CheckCircle/>} icon = {<RadioButtonUncheked/>} />
        </div>
    )
}

export default TermDetail
