import React, { Component } from 'react';
import Axios from 'axios';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Footer from './Footer';
import Header from './Header'
import { withCookies } from 'react-cookie';

import serverURL from '../serverURL.json';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ShowArticle.css'


import QRCode from "react-qr-code";
import ShareIcon from '@mui/icons-material/Share';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';


const styles = theme =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  HeadLine : {
    fontSize: 'max( 25px ,calc(1vw + 1.5vh))',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace : 'pre-wrap'
  }

});




class ShowArticle extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
        user : cookies.get('user') ? cookies.get('user') : {_id: "", refToken :"", accToken:"", auth: false} ,
        articleID : "",
        title : "",
        contents : "",
        regiDate : "",
        showShareMoreMenu : false,
        shareMoreMenuPosition : null,

        dlgShowQR : false,


      }
    this.handleValueChange = this.handleValueChange.bind(this);
    this.shareMoreMenu = this.shareMoreMenu.bind(this);
    
    this.showDlgQR = this.showDlgQR.bind(this);
    this.closeShowDlgQR = this.closeShowDlgQR.bind(this);
    this.showSaveURLAlert = this.showSaveURLAlert.bind(this);

    
    }
    iframePrivacyPart(){ 
      return { 
        __html: 
        this.state.contents
      }
    }
    showSaveURLAlert(){
      this.setState({showShareMoreMenu : false , shareMoreMenuPosition : null})

      alert('The link has been copied')
    }


    showDlgQR(){
      this.setState({showShareMoreMenu : false , shareMoreMenuPosition : null})
      this.setState({dlgShowQR : true})
    }

    closeShowDlgQR(e){
      if(e.currentTarget.name === 'download'){

        var filter = "win16|win32|win64|mac";
    if( navigator.platform){
        if( filter.indexOf(navigator.platform.toLowerCase())<0 ){
            alert("Mobile is not supported");
        }else{

          const svgElement = document.getElementById("QR_Code");
          let {width, height} = svgElement.getBBox(); 
          let clonedSvgElement = svgElement.cloneNode(true);
          let outerHTML = clonedSvgElement.outerHTML,
          blob = new Blob([outerHTML],{type:'image/svg+xml;charset=utf-8'});
          let URL = window.URL || window.webkitURL || window;
          let blobURL = URL.createObjectURL(blob);
          let image = new Image();
          image.onload = () => {
            
             let canvas = document.createElement('canvas');
             
             canvas.widht = width;
             
             canvas.height = height;
             let context = canvas.getContext('2d');
             context.drawImage(image, 0, 0, width, height );
  
            const pngUrl = canvas
            .toDataURL("image/png")
              .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = "QR_Code.png";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
  
  
          };
          image.src = blobURL;


        }
    }

      }
      this.setState({dlgShowQR : false})
    }

    handleValueChange(e){
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    }

    componentWillMount(){
        let splitURL = document.location.href.split("/");
        let _articleID = splitURL[splitURL.length-1];
        this.setState({ articleID : _articleID});


        let params = {};
        let key = 'params';
        let value = {
          articleID : _articleID
        }
        params[key] = value;
        Axios.get(serverURL.api + '/getArticleInfo', params).then(
        response =>{
            if(response.status === 200)
            {
            const returnResult = response.data;

            if(returnResult.result)
            {
                this.setState({
                  title : returnResult.title,
                  contents : returnResult.contents,
                  regiDate : returnResult.regiDate
                });
             
              


            }else
            {
                alert(returnResult.alert)
            }
            }else
            {
            alert('engResponseStatusNot200');
            }
        }
        ).catch((error) =>{
        alert('Unexpected Access, Not Exist Article');
        }
        );
    }

    shareMoreMenu(e){
      this.setState({showShareMoreMenu : true, shareMoreMenuPosition : e.currentTarget})
    }
   

  render(){
    const {classes} = this.props;


    const dlgShowQRCode = (
      <Dialog
      open={this.state.dlgShowQR}
      onClose={this.closeShowDlgQR}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
  >
    <QRCode id = 'QR_Code' value = {"https://seatrust.kr/Article" + this.state.articleID}>
      </QRCode>
     <DialogActions>
     <Button name ='download' onClick={this.closeShowDlgQR} color="primary" >
          Download
      </Button> 
      <Button name ='close' onClick={this.closeShowDlgQR} color="primary" >
          Close
      </Button>
      </DialogActions>
  </Dialog>
  );


  const shareMoreMenuButton = (
    <Menu
      id="moreMenu"
      anchorEl = {this.state.shareMoreMenuPosition}
      keepMounted
      open = {this.state.showShareMoreMenu}
      onClose ={this.closeShareMoreMenu}
      TransitionComponent = {Fade}
    >
      <MenuItem onClick={this.showDlgQR}>
      QR Code
      </MenuItem>
      <MenuItem onClick={this.showSaveURLAlert}>
      Link
      </MenuItem>

    </Menu>

  )



  return (
    <div>
  <Header user = {this.state.user}/>
  <br/>
  <Container component="main">
      <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
  <CopyToClipboard text = {'https://seatrust.kr/Article/' + this.state.articleID}>
              <IconButton onClick={this.shareMoreMenu} size="large">
                <ShareIcon/>
              </IconButton>
            </CopyToClipboard>
  </div>
      <div style={{width:'100%'}} align = 'center'  >
          <span className = {classes.HeadLine} >
              {this.state.title}
          </span>
          {/* <span >
          You will receive an e-mail response to your request. The information you submit will be saved for support, and you agree to it.
          </span> */}
          <br/>
          <br/>
      </div>
      <p dangerouslySetInnerHTML={this.iframePrivacyPart()}></p>        

      {/* <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
          {this.state.regiDate}
      </div> */}
      <br/>

                  {/* <div class="editor-layout"> */}
                  {/* <div class ='parentDiv'> */}

                  {/* <ReactQuill 
              value = {this.state.contents}
              readOnly = {true}
              theme = {'snow'}
              modules = {{
                "toolbar" : false
              }
                
              }
              // class ='parentDiv'
              // scrollingContainer = '#scrolling-container'
             />  */}

                  {/* </div> */}

                    {/* </div>           */}
           
  </Container>
  <Footer/>
  {dlgShowQRCode}
  {shareMoreMenuButton}
  </div>
  );
}

}


export default withStyles(styles)(withCookies(ShowArticle));
