import React from 'react'
import List from '@mui/material/List';
import CompanyCard from './CompanyCard';

function CompanyCardContainer(props) {
    const {companyInfo,SelectCompany, close} = props

    return (
        <div>
            <List
                sx={{
                width: '100%',
                '& ul': { padding: 0 },
               
            }}            
            subheader={<li/>}>

               {companyInfo.map(company=>(
                    <CompanyCard key = {company.crmId} company ={company} SelectCompany={SelectCompany} close = {close}></CompanyCard>
               ))}
            </List>     
        </div>
    )
}

export default CompanyCardContainer
