import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import withStyles from '@mui/styles/withStyles';
import Axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import ManageTabs from './ManageTabs';

import Header from '../Header'

import serverURL from '../../serverURL.json';



const styles = theme =>({
   
    root: {
        height: '100vh',
      },
      image: {
        backgroundImage: `url(${'/img/mainIMG.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
          theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
  });


  class SeaTrustManagement extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        id : "",
        password : "",

        isDoing: false,

        isNeedLogin : true,
      };
      this.handleValueChange = this.handleValueChange.bind(this);
      this.loginClick = this.loginClick.bind(this);
      this.enterPressEvent = this.enterPressEvent.bind(this);
      this.logInEvent = this.logInEvent.bind(this);
  
  
    }

    enterPressEvent(e){
        if (e.key === 'Enter') {
         this.logInEvent();
       }
     }

     logInEvent(){

        this.setState({isDoing : true});
        let params = new URLSearchParams();
        params.append('id', this.state.id);
        params.append('password', this.state.password);
        Axios.post(serverURL.api + '/logIn_Manager', params).then(
            response =>{
        this.setState({isDoing : false});
            if(response.status === 200)
            {
                const returnResult = response.data;
    
                if(returnResult.result)
                {
                    this.setState({isNeedLogin: false})


                }else
                {
                    alert(returnResult.alert)
                }
            }else
            {
                alert('engResponseStatusNot200');
            }
            }
        ).catch((error) =>{
                alert('Unexpected Access, Need to refresh page sign up');
    
            }
        );
     }

    loginClick(){

        this.logInEvent();
        


    }


    handleValueChange(e){
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    }


    render(){
        const {classes} = this.props;
        const ShowLoginView = (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                    Manager Sign in
                    </Typography>
                    <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="id"
                        label="ID"
                        name="id"
                        autoComplete="id"
                        autoFocus
                        onKeyPress ={this.enterPressEvent}
                        onChange = {this.handleValueChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onKeyPress ={this.enterPressEvent}
                        onChange = {this.handleValueChange}
                    />
                    
                    {
                    this.state.isDoing
                    ?
                    <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
                    <CircularProgress />
                    </div>
                    :
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={this.loginClick}
                    >
                        Sign In
                    </Button>
                }
                
                   
                    </form>
                </div>
                
                </Container>


        );

      return (
        <div>
           <Header user = ""/>
                <br/>
            {
                this.state.isNeedLogin
                ?
                ShowLoginView

                :
                <div>
                <Container>
                <Grid container style = {{margin : 5, marginTop : 10}}>
                <Typography component="h6" variant="h6">
                    ㅁ SeaTrust Management
                </Typography>
                </Grid>
                <ManageTabs id = {this.state.id} password = {this.state.password}/>
                </Container>
              </div>








                

            }



        </div>
      
      
      
        )}
}

export default (withStyles(styles)(SeaTrustManagement));
