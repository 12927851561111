import React, { Component } from 'react';
import Axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Footer from './Footer';
import Header from './Header'
import { withCookies } from 'react-cookie';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ShareIcon from '@mui/icons-material/Share';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import { DialogTitle,DialogContent } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from '@mui/material';

import QRCode from "react-qr-code";

import serverURL from '../serverURL.json';



const styles = theme =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  HeadLine : {
    fontSize: 'calc(1vw + 1.5vh)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace : 'pre-wrap'
  }

});

function checkIMG(strValue){
    if(strValue.includes('data:image/'))
    {
        return true
    }else
    {
        return false
    }
}


class AppInfo extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {

        user : cookies.get('user') ? cookies.get('user') : {_id: "", refToken :"", accToken:"", auth: false} ,

        appID : "",
        isAccess : false,
        status : "",
        name : "",
        description : "",
        category : [],
        hwReq : {},
        feature : [],
        advantage : [], // #IMG# 
        function : [], // #IMG# 
        release : [], 
        price : [] ,

        companyCI  : "",
        company  : "",
        icon : "",

        viewCount : 0,
        webURL:'', 
        added : null,
        showShareMoreMenu : false,
        shareMoreMenuPosition : null,

        dlgShowQR : false,
        dlgDownload: false,
        isAPI : false,
        launcher_mail : [],
        launcher_api : []

      }

      this.handleValueChange = this.handleValueChange.bind(this);
      this.addAppClick = this.addAppClick.bind(this);
      this.addMessenger = this.addMessenger.bind(this);
      this.shareMoreMenu = this.shareMoreMenu.bind(this);
      this.closeShareMoreMenu = this.closeShareMoreMenu.bind(this);
      this.moreMenuQRClick = this.moreMenuQRClick.bind(this);
      this.moreMenuCopyURLClick = this.moreMenuCopyURLClick.bind(this);
     
      this.showSaveURLAlert = this.showSaveURLAlert.bind(this);



      this.showDlgQR = this.showDlgQR.bind(this);
      this.closeShowDlgQR = this.closeShowDlgQR.bind(this);

      this.dlgDownloadCancel= this. dlgDownloadCancel.bind(this)

      
    }

    showDlgQR(){
      this.setState({showShareMoreMenu : false , shareMoreMenuPosition : null})
      this.setState({dlgShowQR : true})
    }

    closeShowDlgQR(e){
      if(e.currentTarget.name === 'download'){

        var filter = "win16|win32|win64|mac";
    if( navigator.platform){
        if( filter.indexOf(navigator.platform.toLowerCase())<0 ){
            alert("Mobile is not supported");
        }else{



          const svgElement = document.getElementById("QR_Code");
          let {width, height} = svgElement.getBBox(); 
          let clonedSvgElement = svgElement.cloneNode(true);
          let outerHTML = clonedSvgElement.outerHTML,
          blob = new Blob([outerHTML],{type:'image/svg+xml;charset=utf-8'});
          let URL = window.URL || window.webkitURL || window;
          let blobURL = URL.createObjectURL(blob);
          let image = new Image();
          image.onload = () => {
            
             let canvas = document.createElement('canvas');
             
             canvas.widht = width;
             
             canvas.height = height;
             let context = canvas.getContext('2d');
             // draw image in canvas starting left-0 , top - 0  
             context.drawImage(image, 0, 0, width, height );
            //  downloadImage(canvas); need to implement
  
            const pngUrl = canvas
            .toDataURL("image/png")
              .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = "QR_Code.png";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
  
  
  
  
          };
          image.src = blobURL;







        }
    }
        // const pngUrl = canvas
        //   .toDataURL("image/png")
        //   .replace("image/png", "image/octet-stream");
        // let downloadLink = document.createElement("a");
        // downloadLink.href = pngUrl;
        // downloadLink.download = "QR_Code.png";
        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);
      }
      this.setState({dlgShowQR : false})
    }
   

    showSaveURLAlert(){
      this.setState({showShareMoreMenu : false , shareMoreMenuPosition : null})

      alert('The link has been copied')
    }
    
    moreMenuQRClick(){
      this.setState({showShareMoreMenu : false , shareMoreMenuPosition : null})

    }
    moreMenuCopyURLClick(){
      this.setState({showShareMoreMenu : false , shareMoreMenuPosition : null})

    }


    shareMoreMenu(e){
      this.setState({showShareMoreMenu : true, shareMoreMenuPosition : e.currentTarget})
    }

    closeShareMoreMenu(){
      this.setState({showShareMoreMenu : false , shareMoreMenuPosition : null})
    }

    addMessenger(){
      if(this.state.user._id === "")
      {
          if(window.confirm("Login is required. Would you like to go to the login page?"))
          {
              window.location.href = '/Signin';
          }
      }else
      {


        if(window.confirm("Would you like to ask a provider for a consultation?")){

          let params = new URLSearchParams();
          params.append('userID', this.state.user._id);
          params.append('refToken', this.state.user.refToken);
          params.append('accToken', this.state.user.accToken);
          params.append('appID', this.state.appID);
          Axios.post( serverURL.api+'/addMessenger', params).then(
              response =>{
              if(response.status === 200)
              {
                  const returnResult = response.data;
      
                  if(returnResult.result)
                  {
                    if(window.confirm("Your request for consultation has been registered. Would you like to go to Messenger?")){
                        window.location.href = '/Messenger';
                    } 
                  }else
                  {
                      alert(returnResult.alert)
                  }
              }else
              {
                  alert('engResponseStatusNot200');
              }
              }
          ).catch((error) =>{
                  alert('Unexpected Access, Need to refresh page addAppViewCount');
  
              }
          );
  
        }



        
      }

      
    }

    handleValueChange(e){
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    }

    componentWillMount(){
        let splitURL = document.location.href.split("/");
        let _appID = splitURL[splitURL.length-1];
        this.setState({ appID : _appID});
        let params = {};
        let key = 'params';
        let value = {
            appID : _appID
        }
        params[key] = value;
        Axios.get(serverURL.api+'/getAppInfo', params).then(
        response =>{
            if(response.status === 200)
            {
            const returnResult = response.data;

            if(returnResult.result)
            {
              if(returnResult.isAccess)
              {

                this.setState({
                  isAccess : returnResult.isAccess,
                  status : returnResult.status,
                  name : returnResult.name,
                  description : returnResult.description,
                  category : returnResult.category, 
                  hwReq : returnResult.hwReq, 
                  feature : returnResult.feature,
                  advantage : returnResult.advantage, // #IMG# 
                  function : returnResult.function, // #IMG# 
                  release : returnResult.release, 
                  price : returnResult.price ,
                  company : returnResult.company,
                  companyCI : returnResult.companyCI,
                  icon : returnResult.icon,
                  viewCount : returnResult.viewCount + 1,
                  webURL: returnResult.webURL,
                  isAPI:returnResult.isAPI
                });
                if(returnResult.launcher_mail){
                  this.setState({ 
                    launcher_mail: returnResult.launcher_mail,})
                }
                if (returnResult.launcher_api) {

                  this.setState({
                    launcher_api: returnResult.launcher_api
                  })
                }
              }else
              {
                alert('This app is waiting for Access')
                window.location.href = '/';
              }
              


            }else
            {
                alert(returnResult.alert)
            }
            }else
            {
            alert('engResponseStatusNot200');
            }
        }
        ).catch((error) =>{
        alert('Unexpected Access, Need to refresh page getAppInfo');
        }
        );


        let params2 = new URLSearchParams();
        params2.append('appID', _appID);
        Axios.post(serverURL.api+'/addAppViewCount', params2).then(
            response =>{
            if(response.status === 200)
            {
                const returnResult = response.data;
    
                if(returnResult.result)
                {
                    // 
                }else
                {
                    // alert(returnResult.alert)
                }
            }else
            {
                alert('engResponseStatusNot200');
            }
            }
        ).catch((error) =>{
                alert('Unexpected Access, Need to refresh page addAppViewCount');

            }
        );

        let params3 = {};
        let key3 = 'params';
        let value3 = {
            userID : this.state.user._id,
            appID : _appID,
        }
        params3[key3] = value3;
        Axios.get(serverURL.api+'/getAppAddStatus', params3).then(
        response =>{
            if(response.status === 200)
            {
            const returnResult = response.data;

            if(returnResult.result)
            {
                this.setState({
                    added : returnResult.added,
                });
            }else
            {
                alert(returnResult.alert)
            }
            }else
            {
            alert('');
            }
        }
        ).catch((error) =>{
        alert('Unexpected Access, Need to refresh page getAppAddStatus');
        }
        );






    }


    addAppClick()
    {
        if(this.state.added)
        {
          if (this.state.webURL !== '') {
            window.location.replace(this.state.webURL)
          } else { 
              this.setState({dlgDownload: true});

          }
        }else
        {
            if(this.state.user._id === "")
            {
                if(window.confirm("Login is required. Would you like to go to the login page?"))
                {
                    window.location.href = '/Signin';
                }
            }else
            {

              if(window.confirm("Would you like to add it to My App?"))
              {
                let params = new URLSearchParams();
                params.append('userID', this.state.user._id);
                params.append('refToken', this.state.user.refToken);
                params.append('accToken', this.state.user.accToken);
                params.append('appID', this.state.appID);
                Axios.post( serverURL.api+'/addToMyApp', params).then(
                    response =>{
                    if(response.status === 200)
                    {
                        const returnResult = response.data;
            
                        if(returnResult.result)
                        {
                          window.location.reload(true);
                        }else
                        {
                            alert(returnResult.alert)
                        }
                    }else
                    {
                        alert('engResponseStatusNot200');
                    }
                    }
                ).catch((error) =>{
                        alert('Unexpected Access, Need to refresh page addToMyApp');
        
                    }
                );


              }


            }


        }
    }
    dlgDownloadCancel()
    {
      this.setState({dlgDownload: false});
    }

   
   

  render(){
    const {classes} = this.props;

    const dlgDownloadQuestion = (
      <Dialog
      open={this.state.dlgDownload}
      onClose={this.dlgDownloadCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">Download Appㅤㅤㅤㅤㅤㅤㅤㅤ</DialogTitle>
      <Divider/>
      <DialogContent>
      <Typography component="h6" variant="h6">
          ㅁ List
      </Typography>
      
      {
        this.state.isAPI
        ?
              this.state.launcher_api.map((dictLauncher, index) => {
          return <Paper style={{margin : 10}}>
          <a href = {dictLauncher.file} style={{'color' : 'black', 'text-decoration' : 'none' }}>
          <Button>
            {dictLauncher.name}
          </Button>
          </a>
          </Paper>
          })
        :
        this.state.launcher_mail.map((dictLauncher,index)=>{
          return <Paper style={{margin : 10}}>
          <a href = {serverURL.api + '/getAppDownload?appID='+ this.state.appID + '&index=' + index} style={{'color' : 'black', 'text-decoration' : 'none' }}>
          <Button>
            {dictLauncher.name}
          </Button>
          </a>
          </Paper>
          })

      }





       </DialogContent>
       <DialogActions>
       <Button name= 'disagree' onClick={this.dlgDownloadCancel} color="primary">
          Close
           </Button>
       </DialogActions>
       </Dialog>
  );
    function ShowFeature(strFea) {
      
        return <Container style = {{margin : 10}}>
            <Typography variant="subtitle1" color="textSecondary" component="p">
            - {strFea}
            </Typography>
           
      </Container>

    }

    function ShowAdvantage(strAd) {

        return <Container style = {{margin : 10}}>
            
            {
                checkIMG(strAd)
                ?
                <div align = 'center'>
               <Card >
                  <CardMedia
                  component='img' 
                  image= {strAd}
                  title="Advantage Picture"
                  />
                </Card>
                </div>
                :
                <Typography variant="subtitle1" color="textSecondary" component="p">
                - {strAd}
                </Typography>
            }
      </Container>
    }

    function ShowFunction(strFunc){
        return <Container style = {{margin : 10}}>
            
            {
                checkIMG(strFunc)
                ?
                <div align = 'center'>
               <Card >
                  <CardMedia
                  component='img' 
                  image= {strFunc}
                  title="Function Picture"
                  />
                </Card>
                </div>
                :
                <Typography variant="subtitle1" color="textSecondary" component="p">
                - {strFunc}
                </Typography>
            }
      </Container>
    }


    function ShowRelease(dicRel){
        return  <TableRow key={dicRel.version + dicRel.date +dicRel.content}>
        <TableCell component="th" scope="row">
        {dicRel.version}
        </TableCell>
        <TableCell align="right">{dicRel.date}</TableCell>
        <TableCell align="right">{dicRel.content}</TableCell>
      </TableRow>
    }

    

    
    function ShowPrice(dicPrice){
        return  <TableRow key={dicPrice.name + dicPrice.charge}>
        <TableCell component="th" scope="row">
        {dicPrice.name}
        </TableCell>
        <TableCell align="right">{dicPrice.charge}</TableCell>
      </TableRow>
    }

    function ShowCategory(strCat){
      return '#' + strCat + "ㅤ";


    }



    const shareMoreMenuButton = (
      <Menu
        id="moreMenu"
        anchorEl = {this.state.shareMoreMenuPosition}
        keepMounted
        open = {this.state.showShareMoreMenu}
        onClose ={this.closeShareMoreMenu}
        TransitionComponent = {Fade}
      >
        <MenuItem onClick={this.showDlgQR}>
        QR Code
        </MenuItem>
        <MenuItem onClick={this.showSaveURLAlert}>
        Link
        </MenuItem>

      </Menu>

    )

    const dlgShowQRCode = (
      <Dialog
      open={this.state.dlgShowQR}
      onClose={this.closeShowDlgQR}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
  >
    {/* <canvas id = "QR_Save"> */}
    <QRCode id = 'QR_Code' value = {"https://seatrust.kr/App/" + this.state.appID}>
      </QRCode>
    {/* </canvas> */}
     <DialogActions>
     <Button name ='download' onClick={this.closeShowDlgQR} color="primary" >
          Download
      </Button> 
      <Button name ='close' onClick={this.closeShowDlgQR} color="primary" >
          Close
      </Button>
      </DialogActions>
  </Dialog>
  );



  return (
    <div>
  <Header user = {this.state.user}/>
  <br/>
  <Container component="main">
  {/* <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
          <VisibilityIcon fontSize = "small"/>{this.state.viewCount}
  </div> */}
  {/* <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
  <Button>
    Gen QR
  </Button>
  </div> */}
 
  <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
    
  {
  this.state.added === null
  ?
  null:<div>
    <CopyToClipboard text = {'https://seatrust.kr/App/' + this.state.appID}>
      <IconButton onClick={this.shareMoreMenu} size="large">
        <ShareIcon/>
      </IconButton>
    </CopyToClipboard>
    <Button name = 'addMessenger' variant="contained" color= "primary" onClick = {this.addMessenger} style={{margin : 10}}>
      Contact
                </Button>
  {
    this.state.appID ==='3a7e861f-85bc-4784-882c-a6a16fd23b2b'? 
    <Button name='HullScan30' variant="contained" color="error" style={{marginRight : 10}} href = "https://bit.ly/3TVtJt6">Download v3.0</Button>
    : null
  }
  {
    <Button name='addApp' variant="contained" color={this.state.added ? "" : "secondary"} onClick={this.addAppClick}>
      {this.state.added ? this.state.webURL!==''?  "Open" : "Download" : "Add App" }
    </Button>
  }
  </div>
  }

  </div>
  <Grid item container direction="row" >
      <Avatar style={{ height: '35px', width: '35px' }} src={this.state.icon} /> 
      ㅤ
      <Typography variant="h4" color="inherit" style={{ fontWeight: 600 }} color="primary">
      {this.state.name}
      </Typography>
  </Grid>


    <Container>
    <Typography variant="overline"  component="p">
    {
    this.state.category
    ?
    
    this.state.category.map((strCat)=>{

    return ShowCategory(strCat);
    })
    :
    <div>

    </div>
    }
  </Typography>
    <Typography variant="subtitle1" color="textSecondary" component="p">
      {this.state.description}
  </Typography>
    </Container>
    <br/>
    {this.state.feature? 
      <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
        Feature
      </Typography>:<div></div>
    }

      {
      this.state.feature.length>0
      ?
      
      this.state.feature.map(strFea=>{

      return ShowFeature(strFea);
      })
      :
      <div>

      </div>
      }
      <br/>
      {this.state.advantage?.length >0 ? 
      <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
        Advantage
      </Typography>:<div></div>
     }
    
    {
      this.state.advantage
      ?
      this.state.advantage.map(strAd=>{

      return ShowAdvantage(strAd);
      })
      :
      <div>

      </div>
      }
      <br/>
      {this.state.function.length>0? 
            <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
            Function
          </Typography>:<div></div>
     }

    {
      this.state.function
      ?
      
      this.state.function.map(strFunc=>{

      return ShowFunction(strFunc);
      })
      :
      <div>

      </div>
      }
      <br/>
      <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
      Release
    </Typography>
    {
      this.state.release
      ?
      <TableContainer component={Paper} style = {{margin : 10}}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow style={{backgroundColor:'lightgrey', color: 'white',}}>
            <TableCell>Version</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Content</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
          
          this.state.release.map(dicRel=>{

              return ShowRelease(dicRel);
              })
          }
        </TableBody>
      </Table>
    </TableContainer>
    
      :
      <div>

      </div>
      }
      <br/>
      <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
      Hardware Requirement
    </Typography>
    {
      this.state.hwReq
      ?
      <TableContainer component={Paper} style = {{margin : 10}}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow  style={{backgroundColor:'lightgrey', color: 'white',}}>
            <TableCell>Item</TableCell>
            <TableCell>Spec.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
      <TableRow key={"hwReq_OS"}>
        <TableCell component="th" scope="row">
        {"OS"}
        </TableCell>
        <TableCell component="th" scope="row">
        {this.state.hwReq['os']}
        </TableCell>
      </TableRow>
      <TableRow key={"hwReq_CPU"}>
        <TableCell component="th" scope="row">
        {"CPU"}
        </TableCell>
        <TableCell component="th" scope="row">
        {this.state.hwReq['cpu']}
        </TableCell>
      </TableRow>
      <TableRow key={"hwReq_Memory"}>
        <TableCell component="th" scope="row">
        {"Memory"}
        </TableCell>
        <TableCell component="th" scope="row">
        {this.state.hwReq['memory']}
        </TableCell>
      </TableRow>
      <TableRow key={"hwReq_Hdd"}>
        <TableCell component="th" scope="row">
        {"HDD Free Space"}
        </TableCell>
        <TableCell component="th" scope="row">
        {this.state.hwReq['capacity']}
        </TableCell>
      </TableRow>
      <TableRow key={"hwReq_Display"}>
        <TableCell component="th" scope="row">
        {"Display"}
        </TableCell>
        <TableCell component="th" scope="row">
        {this.state.hwReq['display']}
        </TableCell>
      </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    
      :
      <div>

      </div>
      }
      <br/>
      {
        this.state.price
        ?
        this.state.price.length !==0
        ?
        <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
        Price
       </Typography>
        :
        null
        :
        null
      }
     
    {
      this.state.price
      ?
      <TableContainer component={Paper} style = {{margin : 10}}>
      <Table className={classes.table} aria-label="simple table">
        {/* <TableHead>
            <div align = 'center'>
            <Typography variant="subtitle1" color="inherit" >
          Price Policy
      </Typography>
         
            </div>
        
        </TableHead> */}
        <TableBody>
          {
          
          this.state.price.map(dicPrice=>{

              return ShowPrice(dicPrice);
              })
          }
        </TableBody>
      </Table>
    </TableContainer>
      :
      <div>

      </div>
      }
      <br/>
      <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
          Provider
      </Typography>
      <Container>
      <Card  style = {{margin : 10}}>
          <CardMedia
          component='img' 
          image= {this.state.companyCI}
          title="Company CI"
          />
            <CardHeader
              // title= {this.state.company}
              subheader={this.state.company}
            />
            </Card>
      </Container>
      

  </Container>
  <Footer/>
  {dlgDownloadQuestion}

  {dlgShowQRCode}
  {shareMoreMenuButton}
  </div>
  );
}

}

export default withStyles(styles)(withCookies(AppInfo));
