import React, { Component } from 'react';
import Axios from 'axios';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Footer from './Footer';
import Header from './Header'
import { withCookies } from 'react-cookie';
import BuildIcon from '@mui/icons-material/Build';


import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';


import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import MenuIcon from '@mui/icons-material/Menu';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import clsx from 'clsx';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import AppManageDetailTabs from './AppManageDetailTabs';

import serverURL from '../serverURL.json';



const styles = theme =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  HeadLine : {
    fontSize: 'calc(1vw + 1.5vh)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace : 'pre-wrap'
  },
  labelPlacementStart: {
    display: "flex",
    justifyContent: 'space-between',
  },
  input: {
    width: 150,
  },

});


class AppManagement extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {

        user : cookies.get('user') ? cookies.get('user') : {_id: "", refToken :"", accToken:"", auth: false} ,
        appID : "",
        appName : "",
        appManagementList : [],
        dlgShowAddApp :  false,
        addAppName :  "",
      }

    this.handleValueChange = this.handleValueChange.bind(this);
    this.addAppButtonClick = this.addAppButtonClick.bind(this);
    this.closeShowAddApp = this.closeShowAddApp.bind(this);

    }

    addAppButtonClick(e){
      this.setState({dlgShowAddApp : true},()=>{ 
      })
    }

    closeShowAddApp(e){
      if(e.currentTarget.name ==="ok"){
        let params = new URLSearchParams();
        params.append('userID', this.state.user._id);
        params.append('refToken', this.state.user.refToken);
        params.append('accToken', this.state.user.accToken);
        params.append('addAppName', this.state.addAppName);
        params.append('regiDate', (new Date()).toUTCString());
        Axios.post(serverURL.api + '/addApp', params).then(
            response =>{
                if(response.status === 200)
                {
                const returnResult = response.data;
                if(returnResult.result)
                {
                    alert('The app has been added.')
                    window.location.reload(true);
                }else
                {
                    alert(returnResult.alert)
                }
                }else
                {
                alert('engResponseStatusNot200');
                }
            }
            ).catch((error) =>{
                alert('Unexpected Access, Need to refresh page addApp');
            }
        );
      }
      this.setState({dlgShowAddApp : false})
    }

    handleValueChange(e){
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    }
    componentWillMount(){
      let params = {};
            let key = 'params';
            let value = {
                userID : this.state.user._id,
                refToken : this.state.user.refToken,
                accToken : this.state.user.accToken,
            }
            params[key] = value;
            Axios.get(serverURL.api + '/getAppManagementList_Temp', params).then(
                response =>{
                  console.log(response)
                    if(response.status === 200)
                    {
                    const returnResult = response.data;

                    if(returnResult.result)
                    {
                        const _appManagementList = returnResult.appManagementList;
                        this.setState({appManagementList : _appManagementList});
                        if(_appManagementList.length !==0)
                        {
                          console.log(_appManagementList)
                            this.setState({appName : _appManagementList[0]['name_Temp'],  appID : _appManagementList[0]['appID']});
                        }
                    }else
                    {
                        alert(returnResult.alert)
                    }
                    }else
                    {
                    alert('engResponseStatusNot200');
                    }
                }
            ).catch((error) =>{
            alert('Unexpected Access, Need to refresh page getAppManagementList');
            });
  }

  changeApp(_appID, _appName){
    this.setState({appID : _appID, appName : _appName , 'left': false }); 
    console.log(_appID)
  }  

  render(){
    const {classes} = this.props;
    const ShowAddAppDialog = (
      <Dialog
        open={this.state.dlgShowAddApp}
        onClose={this.closeShowAddApp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add App Name</DialogTitle>
        <DialogContent>
              
        <TextField
        className={classes.input}
        value= {this.state.addAppName}
        name = "addAppName"
        margin="dense"
        fullWidth
        onChange={this.handleValueChange }
        />

        </DialogContent>
        <DialogActions>
          <Button name ='ok' onClick={this.closeShowAddApp} color="primary" >
            OK
          </Button>
          <Button name ='close' onClick={this.closeShowAddApp} color="primary" >
            Close
          </Button>
        </DialogActions>
    </Dialog>
    );  
    const toggleDrawer = (anchor, open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      this.setState({ ...this.state, [anchor]: open });
    };
  


    const list = (anchor) => (
      <div
        className={clsx(styles.list, {
          [styles.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
      >
      
        <List>
        <ListItem key="List">
            <ListItemIcon><SpeakerNotesIcon/></ListItemIcon>
            <ListItemText primary={"App List"} />
          </ListItem>
          {
            this.state.appManagementList
            ?
            this.state.appManagementList.length ===0
            ?
            <div style = {{ display: 'flex',  alignItems: 'center', justifyContent: 'center'}}>
              No List
            </div>
            :
            this.state.appManagementList.map(c=>{
              return <ListItem key= {c.appID} button onClick = {() => this.changeApp(c.appID,c.name_Temp)}>
              <ListItemText primary={c.name_Temp} />
            </ListItem>
              })
          :
          null
          }
          <ListItem key="add">
          <Button
          fullWidth
          variant = "outlined"
          onClick = {this.addAppButtonClick}
          >
            Add
          </Button>
          </ListItem>
        </List>     
      </div>
    );
  return (
    <div>
  <Header user = {this.state.user}/>
  <br/>
  <Container component="main">
      <div style={{width:'100%'}} align = 'center'  >
          <span className = {classes.HeadLine} >
              <BuildIcon color = "primary" fontSize = "large"/>App Management
          </span>
          <span >
          You can register and license the app here.
          </span>
          <br/>
          <br/>
      </div>
      <IconButton color="primary" aria-label="Open drawer" size="large" onClick ={toggleDrawer('left', true)}>
        <MenuIcon  />
      </IconButton>
      <Drawer anchor= 'left' open={this.state['left']} onClose={toggleDrawer('left', false)}>
        {list('left')}
      </Drawer>
      <span >
          {this.state.appName}
      </span>
      <br/>
      <br/>
      <Grid container spacing={2}>
        <Container>

        <AppManageDetailTabs key = {this.state.appID} appID ={this.state.appID}/>
        </Container>
     </Grid>


  </Container>
  <Footer/>
  {/*ShowAddAppDialog*/}
  {ShowAddAppDialog}
  </div>
  );
}

}

export default withStyles(styles)(withCookies(AppManagement));
