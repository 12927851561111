import React, {useState} from 'react'
import {makeStyles} from '@mui/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Grid,Box } from '@mui/material';
import Link from '@mui/material/Link';
import Terms from './Terms/Terms'; 
import SignUp from './Signup';
import Footer from '../Footer';

import termsJson from '../../Controller/Signup/Terms.json'


const useStyles = makeStyles((theme) => ({  
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    content:{ 
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      outline: '#d3d3d3 solid 1px',
      padding: '60px',
      outlineStyle: 'auto',
      
    }
  }));
  
function SignUpParent() {
    const classes = useStyles();
    const [isTerm, setIsTerm] = useState(true); 
    const [terms, setTerms] = React.useState(termsJson.terms);
    const changeTerms=(_terms)=>{ 
      setTerms(_terms)
    }
    const ChangeView = () => { 
      setIsTerm(!isTerm)
    }
    return (
        <Container component="main" maxWidth="md">
   
            <CssBaseline />
 
                <div className={classes.paper}>
                  <Link href="/SignUp/profile" variant="h3" underline="none">
                  {"SeaTrust"}
                  </Link>
                  <div className = {classes.content}>
                    {isTerm? <Terms ChangeView = {ChangeView} terms = {terms} setTerms = {changeTerms} />:<SignUp terms = {terms}/>}            
                    <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="/Signin" variant="body2" underline="hover">
                      Already have an account? Log in
                    </Link>
                  </Grid>
                </Grid>
                  </div>
                </div>
 
        <Box mt={5}>
          <Footer />
        </Box>
        </Container>
    )
}

export default SignUpParent
