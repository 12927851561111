import { Button } from '@mui/material';
import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Axios from 'axios';
import serverURL from '../../serverURL.json';


class MakeCore extends Component{
    constructor(props){
        super(props);
        this.state = {
            id : this.props.id,
            password : this.props.password,
            regiDate : "",
            contents : "",
            title : "",
            mainIMG : "",

            dlgShowIMG : false,

            imgBase64 : "",

            isDoing :false,

        }
        this.onChange  = this.onChange.bind(this)
        this.writeArticle  = this.writeArticle.bind(this)
        this.handleValueChange  = this.handleValueChange.bind(this)
        this.handleShowIMGDialog  = this.handleShowIMGDialog.bind(this)
        this.closeShowIMG  = this.closeShowIMG.bind(this)
        this.handleUploadClick_IMG  = this.handleUploadClick_IMG.bind(this)

        
    }


    handleUploadClick_IMG(event)
    {
        var file = event.target.files[0];
        if(file !== undefined)
        {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // const format_All = ((file.type).split("/"))
            // const foramtName = format_All[format_All.length-1];
    
            reader.onloadend = function(e){
                let picBase64 = [reader.result][0];
                this.setState({mainIMG : picBase64});
    
            }.bind(this)
        }
    }


    handleShowIMGDialog(_imgBase64){
        this.setState({dlgShowIMG : true, imgBase64 :_imgBase64 })
      }
      closeShowIMG(){
        this.setState({dlgShowIMG : false})
      }



    handleValueChange(e){
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
      } 



    writeArticle(){
        let params = new URLSearchParams();
        params.append('id', this.state.id);
        params.append('password', this.state.password);
        params.append('mainIMG', this.state.mainIMG);
        params.append('title', this.state.title);
        params.append('contents', this.state.contents);
        params.append('regiDate', this.state.regiDate)
        this.setState({isDoing : true});
        Axios.post(serverURL.api+'/addArticle', params).then(
            response =>{
        this.setState({isDoing : false});

                if(response.status === 200)
                {
                const returnResult = response.data;
                if(returnResult.result)
                {
                    this.setState({mainIMG : ""});
                    this.setState({title : ""});
                    this.setState({contents : ""});
                    this.setState({regiDate : ""});
                    alert('The article has been added. Changes are reflected when you change tabs')
                }else
                {
                    alert(returnResult.alert)
                }
                }else
                {
                alert('engResponseStatusNot200');
                }
            }
            ).catch((error) =>{
                alert('Unexpected Access, Need to refresh page addApp');
            }
        );


    }

    onChange(_contents){
        this.setState({contents : _contents});
    }

   

    // modules = {
    //     toolbar: [
    //     //   [{ 'font': [] }],
    //       [{ 'header': [1, 2, false] }],
    //       ['bold', 'italic', 'underline','strike', 'blockquote'],
    //       [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    //       ['link', 'image','video'],
    //       [{ 'align': [] }, { 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    //       ['clean']
    //     ],
    //   }

    // modules = {

    //     toolbar: [Name
          
    //         [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    //         [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    //         [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    //         [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    //         [{ 'direction': 'rtl' }],                         // text direction
          
    //         [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    //         [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          
    //         [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    //         [{ 'font': [] }],
    //         [{ 'align': [] }],
          
    //         ['clean']                                         // remove formatting button
    //       ]

    // }
    modules = {

        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
             {'indent': '-1'}, {'indent': '+1'} ,
             {'align': ['', 'center', 'right', 'justify']}],
            ['link', 'image', 'video'],
            ['clean']
          ]

    }

    formats = [
        'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote','align',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
      ]

    
    
    //   formats = [
    //     // 'font',
    //     'header',
    //     'bold', 'italic', 'underline', 'strike', 'blockquote',
    //     'list', 'bullet', 'indent',
    //     'link', 'image', 'video',
    //     'align', 'color', 'background',        
    //   ]

    render(){
       


        const ShowIMGDialog = (
            <Dialog
            open={this.state.dlgShowIMG}
            onClose={this.closeShowIMG}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <img src={this.state.imgBase64} alt="" />
      
           <DialogActions>
                 
            <Button name ='close' onClick={this.closeShowIMG} color="primary" >
                Close
            </Button>
            </DialogActions>
      
        </Dialog>
        );



        return(
            <div>
                     <Grid container  style = {{margin : 5}}>
                        <Typography component="h6" variant="h6">
                            ㅁ Main IMG
                        </Typography>
                    </Grid>
                    <Grid container  style = {{margin : 5}}>
                    
                <img src={this.state.mainIMG} alt=""  width="100"  onClick={()=>this.handleShowIMGDialog(this.state.mainIMG)}/>
                <div>
                <input
                accept="image/*"
                // className={classes.input}
                id="contained-button-file"
                type="file"
                style={{display: 'block'}}
                onChange={this.handleUploadClick_IMG}
                />
                </div>
            </Grid>
            <Grid container  style = {{margin : 5}}>
                        <Typography component="h6" variant="h6">
                            ㅁ Register Date
                        </Typography>
                    </Grid>
            <div  style ={{width : "100%"}}>
                <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                id="regiDate"
                name="regiDate"
                label = "Register Date"
                value = {this.state.regiDate}
                onChange = {this.handleValueChange}
                />
            </div>
            <Grid container  style = {{margin : 5}}>
                        <Typography component="h6" variant="h6">
                            ㅁ Title
                        </Typography>
                    </Grid>
            <div  style ={{width : "100%"}}>
                <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                id="title"
                name="title"
                label = "Title"
                value = {this.state.title}
                onChange = {this.handleValueChange}
                />
            </div>
            <Grid container  style = {{margin : 5}}>
                        <Typography component="h6" variant="h6">
                            ㅁ Contents
                        </Typography>
                    </Grid>
            <div style={{height: "650px"}}>
                <ReactQuill 
                    style={{height: "600px"}} 
                    theme="snow" 
                    modules={this.modules} 
                    formats={this.formats} 
                    value={this.state.contents}
                    placeholder = {"Contents..."}
                    onChange={(content, delta, source, editor) => this.onChange(editor.getHTML())} />
                  
            </div>
              <div  style={{ display: 'flex', justifyContent: 'flex-end'}}>
              {
                this.state.isDoing
                ?
                <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
                  <CircularProgress />
                </div>
                :
                <Button variant="contained" color="primary" onClick = {this.writeArticle}>Write</Button>
              }
              
              </div>
{/*                   
              <ReactQuill 
                value = {this.state.contents}
                readOnly = {true}
                theme = {'bubble'}
               /> */}
                    
              {/* <div dangerouslySetInnerHTML={{__html: this.state.contents}}></div> */}
              {ShowIMGDialog}
              </div>
        )
    }
}
export default MakeCore