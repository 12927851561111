import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import withStyles from '@mui/styles/withStyles';

import { withCookies } from 'react-cookie';
import Axios from 'axios';

import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import serverURL from '../serverURL.json';
import { List, ListItem } from '@mui/material';


function checkIMG(strValue){
    if(strValue.includes('data:image/'))
    {
        return true
    }else
    {
        return false
    }
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </span>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: "white",
    },
    active_tabStyle:{
        width: '100%',
        color: 'white',
        backgroundColor: 'red',
    },
    labelPlacementStart: {
        display: "flex",
        justifyContent: 'space-between',
        wrap: 'nowrap',
        fullwidth : true
    },
    textFieldUnderlined:{
        fontSize:"18px", 
        float:"left",
    }
});


class AppManageDetailInfoTab extends Component{
    constructor(props) {
        super(props);
        const { cookies, classes } = this.props;
        this.state = {
            user : cookies.get('user') ? cookies.get('user') : {_id: "", refToken :"", accToken:"", auth: false} ,
            appID : this.props.appID,
            isAccess : false,
            isApplying : false,
            status : "",
            name_Temp : "",
            icon_Temp : "",
            description_Temp : "",
            feature_Temp : [],
            advantage_Temp : [],
            function_Temp : [],
            release_Temp : [],
            hwReq_Temp : {},
            price_Temp : [],
            category_Temp : [],
            launcher_api_Temp : [],
            isOnpremise_Temp : true,
            isAPI_Temp : true,
            webURL_Temp : "",
            company_Temp : "",
            companyCI_Temp : "",
            


            dlgShowIMG :  false,
            imgBase64 :  "",


        }
      this.radioHandleChange = this.radioHandleChange.bind(this);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.featureValueChange = this.featureValueChange.bind(this);
      this.advantageValueChange = this.advantageValueChange.bind(this);
      this.functionValueChange = this.functionValueChange.bind(this);
      this.releaseValueChange_Version = this.releaseValueChange_Version.bind(this);
      this.releaseValueChange_Date = this.releaseValueChange_Date.bind(this);
      this.releaseValueChange_Content = this.releaseValueChange_Content.bind(this);
      this.priceValueChange_Name = this.priceValueChange_Name.bind(this);
      this.priceValueChange_Charge = this.priceValueChange_Charge.bind(this);
      this.categoryValueChange = this.categoryValueChange.bind(this);
      this.launcherValueChange_Name = this.launcherValueChange_Name.bind(this);
      this.launcherValueChange_File = this.launcherValueChange_File.bind(this);
      
      

    this.handleShowIMGDialog = this.handleShowIMGDialog.bind(this);
    this.closeShowIMG = this.closeShowIMG.bind(this);
      
    this.addFeature = this.addFeature.bind(this);
    this.addAdvantage = this.addAdvantage.bind(this);
    this.addFunction = this.addFunction.bind(this);
    this.addRelease = this.addRelease.bind(this);
    this.addPrice = this.addPrice.bind(this);
    this.addCategory = this.addCategory.bind(this);
    this.addLauncher = this.addLauncher.bind(this);
    

    this.deleteFeature = this.deleteFeature.bind(this);
    this.deleteAdvantage = this.deleteAdvantage.bind(this);
    this.deleteFunction = this.deleteFunction.bind(this);
    this.deleteRelease = this.deleteRelease.bind(this);
    this.deletePrice = this.deletePrice.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.deleteLauncher = this.deleteLauncher.bind(this);

    this.handleUploadClick_Icon = this.handleUploadClick_Icon.bind(this);
    this.handleUploadClick_Ad = this.handleUploadClick_Ad.bind(this);
    this.handleUploadClick_Func = this.handleUploadClick_Func.bind(this);
    this.handleUploadClick_CI = this.handleUploadClick_CI.bind(this);
    
    this.saveClick = this.saveClick.bind(this);
    this.applyClick = this.applyClick.bind(this);

    this.hwReqValueChange_OS = this.hwReqValueChange_OS.bind(this);
    this.hwReqValueChange_CPU = this.hwReqValueChange_CPU.bind(this);
    this.hwReqValueChange_Memory = this.hwReqValueChange_Memory.bind(this);
    this.hwReqValueChange_Capacity = this.hwReqValueChange_Capacity.bind(this);
    this.hwReqValueChange_Display = this.hwReqValueChange_Display.bind(this);

    
    
    

    }  

    applyClick(){

        if(window.confirm("※ It cannot be modified until the platform administrator confirms it. Would you like to apply?\nAfter applying for registration, you must send the app installation file by e-mail(ictsolution@krs.co.kr)."))
        {
            if(window.confirm("Would you like to save and submit your current content?"))
            {
    
               
                
                let dicList = {};
                dicList['userID'] = this.state.user._id;
                dicList['refToken'] = this.state.user.refToken;
                dicList['accToken'] = this.state.user.accToken;
                dicList['appID'] =this.state.appID;
                dicList['name_Temp'] =this.state.name_Temp;
                dicList['icon_Temp'] =this.state.icon_Temp;
                dicList['description_Temp'] =this.state.description_Temp;
                dicList['feature_Temp'] =this.state.feature_Temp;
                dicList['advantage_Temp'] =this.state.advantage_Temp;
                dicList['function_Temp'] =this.state.function_Temp;
                dicList['release_Temp'] =this.state.release_Temp;
                dicList['hwReq_Temp'] =this.state.hwReq_Temp;
                dicList['price_Temp'] =this.state.price_Temp;
                dicList['launcher_api_Temp'] =this.state.launcher_api_Temp;
                dicList['isOnpremise_Temp'] =this.state.isOnpremise_Temp;
                dicList['isAPI_Temp'] =this.state.isAPI_Temp;
                dicList['webURL_Temp'] =this.state.webURL_Temp;
                dicList['category_Temp'] =this.state.category_Temp;
                dicList['company_Temp'] =this.state.company_Temp;
                dicList['companyCI_Temp'] = this.state.companyCI_Temp;
                
                Axios.post(serverURL.api+'/saveAppInfo_Temp', dicList).then(
                response =>{
                    if(response.status === 200)
                    {
                    const returnResult = response.data;
                    if(returnResult.result)
                    {

                        let params = new URLSearchParams();
                        params.append('userID', this.state.user._id);
                        params.append('refToken', this.state.user.refToken);
                        params.append('accToken', this.state.user.accToken);
                        params.append('appID', this.state.appID);
                        Axios.post(serverURL.api+'/applyAppInfo', dicList).then(
                            response =>{
                                if(response.status === 200)
                                {
                                const returnResult = response.data;
                                if(returnResult.result)
                                {
                                    alert('The application registration application has been successfully completed.')
                                  window.location.reload(true);

                                }else
                                {
                                    alert(returnResult.alert)
                                }
                                }else
                                {
                                alert('engResponseStatusNot200');
                                }
                            }
                            ).catch((error) =>{
                                alert('Unexpected Access, Need to refresh page saveAppInfo');
                            }
                        );
                    }else
                    {
                        alert(returnResult.alert)
                    }
                    }else
                    {
                    alert('engResponseStatusNot200');
                    }
                }
                ).catch((error) =>{
                    alert('Unexpected Access, Need to refresh page saveAppInfo1');
                }
                );
            }else
            {

                let params = new URLSearchParams();
                params.append('userID', this.state.user._id);
                params.append('refToken', this.state.user.refToken);
                params.append('accToken', this.state.user.accToken);
                params.append('appID', this.state.appID);
                Axios.post(serverURL.api+'/applyAppInfo', params).then(
                    response =>{
                        if(response.status === 200)
                        {
                        const returnResult = response.data;
                        if(returnResult.result)
                        {
                            alert('The application registration application has been successfully completed.')
                          window.location.reload(true);

                        }else
                        {
                            alert(returnResult.alert)
                        }
                        }else
                        {
                        alert('engResponseStatusNot200');
                        }
                    }
                    ).catch((error) =>{
                        alert('Unexpected Access, Need to refresh page saveAppInfo2');
                    }
                );



            }

            
        }


    }


    saveClick(){
        if(this.state.isApplying)
        {
            alert('The platform manager is under review.\nIf there is an installation file to be sent,\nPlease send it to "ictsolution@krs.co.kr"')
        }else
        {

            if(window.confirm("Would you like to save?"))
            {
    
                let params = new URLSearchParams();
                params.append('userID', this.state.user._id);
                params.append('refToken', this.state.user.refToken);
                params.append('accToken', this.state.user.accToken);
                
                let dicList = {};
                dicList['userID'] = this.state.user._id;
                dicList['refToken'] = this.state.user.refToken;
                dicList['accToken'] = this.state.user.accToken;
                dicList['appID'] =this.state.appID;
                dicList['name_Temp'] =this.state.name_Temp;
                dicList['icon_Temp'] =this.state.icon_Temp;
                dicList['description_Temp'] =this.state.description_Temp;
                dicList['feature_Temp'] =this.state.feature_Temp;
                dicList['advantage_Temp'] =this.state.advantage_Temp;
                dicList['function_Temp'] =this.state.function_Temp;
                dicList['release_Temp'] =this.state.release_Temp;
                dicList['hwReq_Temp'] =this.state.hwReq_Temp;
                dicList['price_Temp'] =this.state.price_Temp;
                dicList['launcher_api_Temp'] =this.state.launcher_api_Temp;
                dicList['isOnpremise_Temp'] =this.state.isOnpremise_Temp;
                dicList['isAPI_Temp'] =this.state.isAPI_Temp;
                dicList['webURL_Temp'] =this.state.webURL_Temp;
                dicList['category_Temp'] =this.state.category_Temp;
                dicList['company_Temp'] =this.state.company_Temp;
                dicList['companyCI_Temp'] = this.state.companyCI_Temp;
                Axios.post(serverURL.api+'/saveAppInfo_Temp', dicList).then(
                response =>{
                    if(response.status === 200)
                    {
                    const returnResult = response.data;
                    if(returnResult.result)
                    {
                        alert('The app info is changed.')
                    }else
                    {
                        alert(returnResult.alert)
                    }
                    }else
                    {
                    alert('engResponseStatusNot200');
                    }
                }
                ).catch((error) =>{
                    alert('Unexpected Access, Need to refresh page User Setting 4');
                }
                );
            }


        }
       

    }
    
    handleUploadClick_Icon(event)
    {
        var file = event.target.files[0];
        if(file !== undefined)
        {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // const format_All = ((file.type).split("/"))
            // const foramtName = format_All[format_All.length-1];
    
            reader.onloadend = function(e){
                let picBase64 = [reader.result][0];
                this.setState({icon_Temp : picBase64});
    
            }.bind(this)
        }
    }


    handleUploadClick_CI(event)
    {
        var file = event.target.files[0];
        if(file !== undefined)
        {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // const format_All = ((file.type).split("/"))
            // const foramtName = format_All[format_All.length-1];
    
            reader.onloadend = function(e){
                let picBase64 = [reader.result][0];
                this.setState({companyCI_Temp : picBase64});
    
            }.bind(this)
        }
    }

    handleUploadClick_Func(event, _index)
    {
        var file = event.target.files[0];
        if(file !== undefined)
        {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // const format_All = ((file.type).split("/"))
            // const foramtName = format_All[format_All.length-1];
    
            reader.onloadend = function(e){
                let picBase64 = [reader.result][0];
    
                let _functionList = this.state.function_Temp;
                _functionList[_index] = picBase64;
                this.setState({function_Temp : _functionList});
    
            }.bind(this)
        }
        

    }

    handleUploadClick_Ad(event, _index)
    {
        var file = event.target.files[0];
        if(file !== undefined)
        {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // const format_All = ((file.type).split("/"))
            // const foramtName = format_All[format_All.length-1];
    
            reader.onloadend = function(e){
                let picBase64 = [reader.result][0];
    
                let _advantageList = this.state.advantage_Temp;
                _advantageList[_index] = picBase64;
                this.setState({advantage_Temp : _advantageList});
    
            }.bind(this)
        }
        

    }
    

    deleteLauncher(_index)
    {
        let _launcherList = this.state.launcher_api_Temp;
        _launcherList.splice(_index, 1)
        this.setState({launcher_api_Temp : _launcherList});
    }


    deleteCategory(_index)
    {
        let _categoryList = this.state.category_Temp;
        _categoryList.splice(_index, 1)
        this.setState({category_Temp : _categoryList});
    }


    deletePrice(_index)
    {
        let _priceList = this.state.price_Temp;
        _priceList.splice(_index, 1)
        this.setState({price_Temp : _priceList});
    }


    deleteRelease(_index)
    {
        let _releaseList = this.state.release_Temp;
        _releaseList.splice(_index, 1)
        this.setState({release_Temp : _releaseList});
    }

    deleteFunction(_index)
    {
        let _functionList = this.state.function_Temp;
        _functionList.splice(_index, 1)
        this.setState({function_Temp : _functionList});
    }


    deleteAdvantage(_index)
    {
        let _advantageList = this.state.advantage_Temp;
        _advantageList.splice(_index, 1)
        this.setState({advantage_Temp : _advantageList});

    }

    deleteFeature(_index)
    {
        let _featureList = this.state.feature_Temp;
        _featureList.splice(_index, 1)
        this.setState({feature_Temp : _featureList});

    }


    addLauncher(){
        let _launcherList = this.state.launcher_api_Temp;
        let tempDictLauncher = {
            name : "",
            file : "",
        }
        _launcherList.push(tempDictLauncher);
        this.setState({launcher_api_Temp : _launcherList});
        
    }

    addCategory(){
        let _categoryList = this.state.category_Temp;
        _categoryList.push("");
        this.setState({category_Temp : _categoryList});
    }


    addPrice(){
        let _priceList = this.state.price_Temp;
        let tempDictPrice = {
            name : "",
            charge : "",
        }
        _priceList.push(tempDictPrice);
        this.setState({price_Temp : _priceList});
    }

    addRelease(){
        let _releaseList = this.state.release_Temp;
        let tempDictRelease = {
            version : "",
            date : "",
            content : "",
        }
        _releaseList.push(tempDictRelease);
        this.setState({release_Temp : _releaseList});
    }

    addFunction(){
        let _functionList = this.state.function_Temp;
        if(window.confirm("Would you like to input an image?"))
        {
            _functionList.push("data:image/jpg;base64,");
        }else
        {
            _functionList.push("");
        }
        this.setState({function_Temp : _functionList});
    }

    addAdvantage(){
        let _advantageList = this.state.advantage_Temp;
        if(window.confirm("Would you like to input an image?"))
        {
            _advantageList.push("data:image/jpg;base64,");
        }else
        {
            _advantageList.push("");
        }
        this.setState({advantage_Temp : _advantageList});
    }

    addFeature(){
        let _featureList = this.state.feature_Temp;
        _featureList.push("");
        this.setState({feature_Temp : _featureList});

    }

    handleShowIMGDialog(_imgBase64){
        this.setState({dlgShowIMG : true, imgBase64 :_imgBase64 })
      }
      closeShowIMG(){
        this.setState({dlgShowIMG : false})
      }
      
      hwReqValueChange_Display(e){
        let _hwReq = this.state.hwReq_Temp;
        _hwReq['display'] = e.target.value;
        this.setState({hwReq_Temp : _hwReq});
      }

      hwReqValueChange_Capacity(e){
        let _hwReq = this.state.hwReq_Temp;
        _hwReq['capacity'] = e.target.value;
        this.setState({hwReq_Temp : _hwReq});
      }

      hwReqValueChange_Memory(e){
        let _hwReq = this.state.hwReq_Temp;
        _hwReq['memory'] = e.target.value;
        this.setState({hwReq_Temp : _hwReq});
      }

      hwReqValueChange_CPU(e){
        let _hwReq = this.state.hwReq_Temp;
        _hwReq['cpu'] = e.target.value;
        this.setState({hwReq_Temp : _hwReq});
      }


      hwReqValueChange_OS(e){
        let _hwReq = this.state.hwReq_Temp;
        _hwReq['os'] = e.target.value;
        this.setState({hwReq_Temp : _hwReq});
      }


      launcherValueChange_Name(e, _index){
        let _launcherList = this.state.launcher_api_Temp;
        _launcherList[_index]['name'] = e.target.value;
        this.setState({launcher_api_Temp : _launcherList});
      }

      launcherValueChange_File(e, _index){
        let _launcherList = this.state.launcher_api_Temp;
        _launcherList[_index]['file'] = e.target.value;
        this.setState({launcher_api_Temp : _launcherList});
      }
      
      
      categoryValueChange(e, _index){
        let _categoryList = this.state.category_Temp;
        _categoryList[_index] = e.target.value;
        this.setState({category_Temp : _categoryList});
      } 



      priceValueChange_Charge(e, _index){
        let _priceList = this.state.price_Temp;
        _priceList[_index]['charge'] = e.target.value;
        this.setState({price_Temp : _priceList});
      }

      priceValueChange_Name(e, _index){
        let _priceList = this.state.price_Temp;
        _priceList[_index]['name'] = e.target.value;
        this.setState({price_Temp : _priceList});
      }


      
      releaseValueChange_Content(e, _index){
        let _releaseList = this.state.release_Temp;
        _releaseList[_index]['content'] = e.target.value;
        this.setState({release_Temp : _releaseList});
      }


      releaseValueChange_Date(e, _index){
        let _releaseList = this.state.release_Temp;
        _releaseList[_index]['date'] = e.target.value;
        this.setState({release_Temp : _releaseList});
      }

      releaseValueChange_Version(e, _index){
        let _releaseList = this.state.release_Temp;
        _releaseList[_index]['version'] = e.target.value;
        this.setState({release_Temp : _releaseList});
      }
      
      functionValueChange(e, _index){
        let _functionList = this.state.function_Temp;
        _functionList[_index] = e.target.value;
        this.setState({function_Temp : _functionList});
      } 
      
    advantageValueChange(e, _index){
        let _advantageList = this.state.advantage_Temp;
        _advantageList[_index] = e.target.value;
        this.setState({advantage_Temp : _advantageList});
      } 

    featureValueChange(e, _index){
        let _featureList = this.state.feature_Temp;
        _featureList[_index] = e.target.value;
        this.setState({feature_Temp : _featureList});
      } 

   

    radioHandleChange(e, _handleName)
    {
    let nextState = {};
    nextState[_handleName] = e.target.value;
    this.setState(nextState);
    }
      

    handleValueChange(e){
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
      } 


    componentWillMount()
    {
        if(this.state.appID !=="")
        {
            let params = {};
            let key = 'params';
            let value = {
                appID : this.state.appID
            }
            params[key] = value;
            Axios.get(serverURL.api+'/getAppInfo_Temp', params).then(
            response =>{
                if(response.status === 200)
                {
                const returnResult = response.data;
    
                if(returnResult.result)
                {
                    this.setState({
                        isAccess : returnResult.isAccess,
                        isApplying : returnResult.isApplying,
                        status : returnResult.status,
                        name_Temp : returnResult.name_Temp,
                        description_Temp : returnResult.description_Temp,
                        category_Temp : returnResult.category_Temp, 
                        feature_Temp : returnResult.feature_Temp,
                        advantage_Temp : returnResult.advantage_Temp, // #IMG# 
                        function_Temp : returnResult.function_Temp, // #IMG# 
                        release_Temp : returnResult.release_Temp, 
                        hwReq_Temp : returnResult.hwReq_Temp,
                        price_Temp : returnResult.price_Temp,
                        launcher_api_Temp : returnResult.launcher_api_Temp,
                        isOnpremise_Temp : returnResult.isOnpremise_Temp,
                        isAPI_Temp : returnResult.isAPI_Temp,
                        webURL_Temp : returnResult.webURL_Temp,
                        company_Temp : returnResult.company_Temp,
                        companyCI_Temp : returnResult.companyCI_Temp,
                        icon_Temp : returnResult.icon_Temp,
    
                    });
    
    
                }else
                {
                    alert(returnResult.alert)
                }
                }else
                {
                alert('engResponseStatusNot200');
                }
            }
            ).catch((error) =>{
            alert('Unexpected Access, Need to refresh page getAppInfo');
            }
            );

        }
        

    }

    render(){
        const {classes} =  this.props;

        const ShowIMGDialog = (
            <Dialog
            open={this.state.dlgShowIMG}
            onClose={this.closeShowIMG}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <img src={this.state.imgBase64} alt="" />
      
           <DialogActions>
                 
            <Button name ='close' onClick={this.closeShowIMG} color="primary" >
                Close
            </Button>
            </DialogActions>
      
        </Dialog>
        );

       
     

        function ShowFeature(_strFea, _index, _this){
            return (
                <Grid container>
                           <div  style ={{width : "10%"}}>
                <IconButton
                    color="error"
                    onClick = {()=>_this.deleteFeature(_index)}
                    size="large">
                    <RemoveCircleIcon fontSize= 'small'/>
                </IconButton>
                </div>
                <div  style ={{width : "90%"}}>
                <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    id="feature"
                    name="feature"
                    variant = "standard"
                    value = {_strFea}
                    onChange = {(e)=>_this.featureValueChange(e, _index)}
                />
                </div>
                </Grid>
            );
            
    
        }

        function ShowAdvantage(_strAd, _index, _this){
            return checkIMG(_strAd)
                ?
            <Grid container  sx = {{margin : 1}}>
                <Grid item xs = {1}>
                    <IconButton
                        color="error"
                        onClick = {()=>_this.deleteAdvantage(_index)}
                        size="large">
                        <RemoveCircleIcon fontSize= 'small'/>
                    </IconButton>
                </Grid>
                <img src={_strAd} alt=""  width="100"  onClick={()=>_this.handleShowIMGDialog(_strAd)}/>
                <span>
                <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                type="file"
                style={{display: 'block'}}
                onChange={(e)=>_this.handleUploadClick_Ad(e, _index)}
                />
                </span>
                </Grid >

                :
                <Grid container sx = {{margin : 1}}>
                    <Grid item xs = {1}>
                        <IconButton
                            color="error"
                            onClick = {()=>_this.deleteAdvantage(_index)}
                            size="large">
                            <RemoveCircleIcon fontSize= 'small'/>
                        </IconButton>
                </Grid>
                <Grid item xs={11}>
                <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    id="advantage"
                    name="advantage"
                    variant = "standard"
                    value = {_strAd}
                    onChange = {(e)=>_this.advantageValueChange(e, _index)}
                />
            </Grid>               
            </Grid>;
                    
            
        }

        function ShowFunction(_strFunc, _index, _this){
            return checkIMG(_strFunc)
            ?
            <Grid container  style = {{margin : 1}}>
                 <div  sx ={{width : "10%"}}>
                <IconButton
                    color="error"
                    onClick = {()=>_this.deleteFunction(_index)}
                    size="large">
                    <RemoveCircleIcon fontSize= 'small'/>
                </IconButton>
                </div>
                <img src={_strFunc} alt=""  width="100"  onClick={()=>_this.handleShowIMGDialog(_strFunc)}/>
                <div>
                <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                type="file"
                
                style={{display: 'block'}}
                onChange={(e)=>_this.handleUploadClick_Func(e, _index)}
                />
                </div>
            </Grid >
            :
            <Grid container>
            <div  style ={{width : "10%"}}>
                <IconButton
                    color="error"
                    onClick = {()=>_this.deleteFunction(_index)}
                    size="large">
                    <RemoveCircleIcon fontSize= 'small'/>
                </IconButton>
                </div>
            <div  style ={{width : "90%"}}>
                <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                id="function"
                name="function"
                variant = "standard"

                value = {_strFunc}
                onChange = {(e)=>_this.functionValueChange(e, _index)}
                />
            </div>
            </Grid>;

           
        }
        

        function ShowRelease(_dictRel, _index, _this){
            return (
                <ListItem key = {_index}>
                <Grid container>
                    <Grid item xs = {1}>
                        <IconButton
                            color="error"
                            onClick = {()=>_this.deleteRelease(_index)}
                            size="large">
                            <RemoveCircleIcon fontSize= 'small'/>
                        </IconButton>
                    </Grid>
                    <Grid item xs = {11}>
                        <Grid container >
                            <Grid item xs = {12}> 
                                <span className = {classes.textFieldUnderlined}>Version</span>       
                                <TextField
                                    onChange={(e)=>_this.releaseValueChange_Version(e, _index)}
                                    color="primary"
                                    id="version"
                                    name="version"
                                    value ={_dictRel['version']}
                                    variant="standard" 
                                    sx={{ display: "flex",  float: 'right', width: '90%'}}
                                    InputLabelProps={{ shrink: false, color: "primary"}}
                                />    
                            </Grid>
                            <Grid item xs = {12}> 
                             <span className = {classes.textFieldUnderlined} >Date</span>       
                                <TextField
                                    onChange={(e)=>_this.releaseValueChange_Date(e, _index)}
                                    color="primary"
                                    id="date"
                                    name="date"
                                    value ={_dictRel['date']}
                                    variant="standard" 
                                    sx={{ display: "flex",  float: 'right',width: '90%'}}
                                    InputLabelProps={{ shrink: false, color: "primary"}}
                                />    
                            </Grid>
                            <Grid item xs = {12}> 
                                <span className = {classes.textFieldUnderlined} >Content</span>       
                                <TextField
                                    onChange={(e)=>_this.releaseValueChange_Content(e, _index)}
                                    color="primary"
                                    id="content"
                                    name="content"
                                    value ={_dictRel['content']}
                                    variant="standard" 
                                    sx={{ display: "flex", float: 'right',width: '90%'}}
                                    InputLabelProps={{ shrink: false, color: "primary"}}
                                />    
                            </Grid>
                        </Grid>
                    </Grid>
               </Grid>
               </ListItem>
            ); 

        }

        
        function ShowPrice(_dictpri, _index, _this){
            return (
                <Grid container  style = {{margin : 1}}>
                      <Grid container>
                </Grid>
                <div  style ={{width : "10%"}}>
                <IconButton color="secondary" onClick = {()=>_this.deletePrice(_index)} size="large">
                    <RemoveCircleIcon fontSize= 'small'/>
                </IconButton>
                </div>
                    <Container>
                    <FormControlLabel
                labelPlacement="start"
                classes = {{
                  labelPlacementStart : classes.labelPlacementStart
                }}
                    control={
                    <TextField
                      onChange={(e)=>_this.priceValueChange_Name(e, _index)}
                      color="primary"
                      id="name"
                      name="name"
                      value ={_dictpri['name']}
                      style={{ width : '100%', paddingLeft: '35px',justify : "flex-end" ,  display: "flex"}}
                      variant = "standard"

                    />    
                    }
                    label={"Name"}
                />
                </Container>
                <Container>
                <FormControlLabel
                labelPlacement="start"
                classes = {{
                  labelPlacementStart : classes.labelPlacementStart
                }}
                    control={
                    <TextField
                      onChange={(e)=>_this.priceValueChange_Charge(e, _index)}
                      color="primary"
                      id="charge"
                      name="charge"
                      value ={_dictpri['charge']}
                      style={{ width : '100%', paddingLeft: '25px',justify : "flex-end" ,  display: "flex"}}
                      variant = "standard"

                    />    
                    }
                    label={"Charge"}
                />
                </Container>
               </Grid>
            ); 

        }

        function ShowCategory(_strCat, _index, _this){
            return (
                <Grid container>
                <div  style ={{width : "10%"}}>
                <IconButton
                    color="secondary"
                    onClick = {()=>_this.deleteCategory(_index)}
                    size="large">
                    <RemoveCircleIcon fontSize= 'small'/>
                </IconButton>
                </div>
                <div  style ={{width : "90%"}}>
                <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    id="category"
                    name="category"
                    value = {_strCat}
                    variant = "standard"
                    onChange = {(e)=>_this.categoryValueChange(e, _index)}
                />
                </div>
                </Grid>
            );

        }

        function ShowLauncher(_strOpr, _index, _this){
            return (
                <Grid container  style = {{margin : 1}}>
                <Grid container>
              <div>
              <Divider  component="li" />

              </div>
          </Grid>
          <div  style ={{width : "10%"}}>
          <IconButton
              color="secondary"
              onClick = {()=>_this.deleteLauncher(_index)}
              size="large">
              <RemoveCircleIcon fontSize= 'small'/>
          </IconButton>
          </div>
              <Container>
              <FormControlLabel
          labelPlacement="start"
          classes = {{
            labelPlacementStart : classes.labelPlacementStart
          }}
              control={
              <TextField
                onChange={(e)=>_this.launcherValueChange_Name(e, _index)}
                color="primary"
                id="name"
                name="name"
                value ={_strOpr['name']}
                style={{ width : '100%', paddingLeft: '25px',justify : "flex-end" ,  display: "flex"}}
              />    
              }
              label={"Name"}
          />
          </Container>
          <Container>
          <FormControlLabel
          labelPlacement="start"
          classes = {{
            labelPlacementStart : classes.labelPlacementStart
          }}
              control={
              <TextField
                onChange={(e)=>_this.launcherValueChange_File(e, _index)}
                color="primary"
                id="file"
                name="file"
                value ={_strOpr['file']}
                style={{ width : '100%', paddingLeft: '40px',justify : "flex-end" ,  display: "flex"}}
              />    
              }
              label={"File"}
          />
          </Container>
         </Grid>
            ); 
            
            
            
            
            
            
            
            
            
            
            // <Grid container>
            // <div  style ={{width : "10%"}}>
            // <IconButton
            // color="secondary"
            // onClick = {()=>_this.deleteLauncher(_index)}
            // >
            //     <RemoveCircleIcon fontSize= 'small'/>
            // </IconButton>
            // </div>
            // <div  style ={{width : "90%"}}>
            // <TextField
            //     fullWidth
            //     variant="outlined"
            //     margin="dense"
            //     id="launcher_name"
            //     name="launcher_name"
            //     value = {_strOpr}
            //     onChange = {(e)=>_this.launcherValueChange_File(e, _index)}
            // />
            // </div>
            // </Grid>

        }


        

  
    

        return (
            <div className={classes.root}>
            {
                this.state.appID  === ""
                ?
                "No App"
                :
                <div>

            <Grid container sx = {{margin : '5px', marginTop : '10px'}}>
            <Typography component="h6" variant="h6">
                ㅁ Status : {this.state.isAccess ? "Access": "Not Access" }{", "}{this.state.status}
            </Typography>
            </Grid>
            <Grid container sx = {{margin : '5px', marginTop : '10px'}}>
            <Typography component="h6" variant="h6">
                ㅁ Name
            </Typography>
            <TextField
                variant="outlined"
                // margin="normal"
                fullWidth
                id="name_Temp"
                name="name_Temp"
                value = {this.state.name_Temp}
                onChange = {this.handleValueChange}
            />
            </Grid>
            <Grid container sx = {{margin : '5px', marginTop : '10px'}}>
            <Typography component="h6" variant="h6">
                ㅁ Icon
            </Typography>
            <Grid container  style = {{margin : 5}}>
                <img src={this.state.icon_Temp} alt=""  width="100"  onClick={()=>this.handleShowIMGDialog(this.state.icon_Temp)}/>
                <div>
                <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                type="file"
                style={{display: 'block'}}
                onChange={this.handleUploadClick_Icon}
                />
                </div>
            </Grid>
            </Grid>
            <Grid container sx = {{margin : '5px', marginTop : '10px'}}>
            <Typography component="h6" variant="h6">
                ㅁ Description
            </Typography>
            <TextField
                variant="outlined"
                // margin="normal"
                fullWidth
                id="description_Temp"
                name="description_Temp"
                label ={this.state.description_Temp.length+"/500"}
                inputProps={{ maxLength: 500 }}
                value = {this.state.description_Temp}
                onChange = {this.handleValueChange}
              />
            </Grid>
            <Grid container sx = {{margin : '5px', marginTop : '10px'}}>
                <Typography component="h6" variant="h6">
                    ㅁ Feature
                    <IconButton color="primary" onClick = {this.addFeature} size="large">
                      <AddCircleIcon fontSize= 'small'/>
                  </IconButton>
                </Typography>
                {
                this.state.feature_Temp
                ?
                
                this.state.feature_Temp.map((strFea,index)=>{

                return ShowFeature(strFea,index,this);
                })
                :
                <div>

                </div>
                }
            </Grid>
            <Grid container sx = {{margin : '5px', marginTop : '10px'}}>
                <Typography component="h6" variant="h6">
                    ㅁ Advantage
                    <IconButton color="primary" onClick = {this.addAdvantage} size="large">
                      <AddCircleIcon fontSize= 'small'/>
                  </IconButton>
                </Typography>
                {
                this.state.advantage_Temp
                ?
                
                this.state.advantage_Temp.map((strAd,index)=>{

                return ShowAdvantage(strAd, index,this);
                })
                :
                <div>

                </div>
                }
            </Grid>
            <Grid container sx = {{margin : '5px', marginTop : '10px'}}>
                <Grid item xs = {12}>
                <Typography component="h6" variant="h6">
                    ㅁ Function
                    <IconButton color="primary" onClick = {this.addFunction} size="large">
                      <AddCircleIcon fontSize= 'small'/>
                  </IconButton>
                </Typography>
                </Grid>
                {
                this.state.function_Temp
                ?
                
                this.state.function_Temp.map((strFunc,index)=>{

                return ShowFunction(strFunc, index, this);
                })
                :
                <div>

                </div>
                }
            </Grid>
            <Grid container sx = {{margin : '5px', marginTop : '10px'}} >
                <Grid item xs ={12}>
                <Typography component="h6" variant="h6">
                    ㅁ Release
                    <IconButton color="primary" onClick = {this.addRelease} size="large">
                      <AddCircleIcon fontSize= 'small'/>
                  </IconButton>
                </Typography>
                </Grid>
                
                {
                this.state.release_Temp
                ?
                <List>               
                    {this.state.release_Temp.map((dictRel, index)=>{
                        return ShowRelease(dictRel, index, this);
                    })}
                </List>
                :
                <span>

                </span>
                }
            </Grid>

            <Grid container sx = {{margin : '5px', marginTop : '10px'}}>             
                <Grid container  style = {{margin : 1}}>
                 
            <Typography component="h6" variant="h6">
                ㅁ Hardware Requirement
            </Typography>
            <Grid container spacing = {1} sx = {{margin : '5px',  marginBottom: '10px', marginTop:'0px'}}>
                <Grid item xs= {1}>   
                <div className = {classes.textFieldUnderlined} >OS</div>       
                </Grid>
                <Grid item xs ={11}>      
                <TextField
                    onChange={this.hwReqValueChange_OS}
                    color="primary"
                    id="os"
                    name="os"
                    value ={this.state.hwReq_Temp['os']}
                    variant="standard"
                    fullWidth
                    sx={{ display: "flex",  }}
                    InputLabelProps={{ shrink: false, color: "primary"}}/>
                </Grid>
                <Grid item xs={1}>
                    <div className = {classes.textFieldUnderlined} >CPU</div>       
                </Grid>
                <Grid item xs = {11}>
                    <TextField
                        onChange={this.hwReqValueChange_CPU}
                        color="primary"
                        id="cpu"
                        name="cpu"
                        variant="standard" 
                        value ={this.state.hwReq_Temp['cpu']}
                        sx={{ display: "flex"}}
                        InputLabelProps={{ shrink: false, color: "primary"}}
                    />    
                </Grid>
                <Grid item xs={1}>
                    <div className = {classes.textFieldUnderlined} >Memory</div>       
                </Grid>
                <Grid item xs = {11}> 
                    <TextField
                        onChange={this.hwReqValueChange_Memory}
                        color="primary"
                        id="memory"
                        name="memory"
                        value ={this.state.hwReq_Temp['memory']}
                        variant="standard" 
                        sx={{ display: "flex"}}
                        InputLabelProps={{ shrink: false, color: "primary"}}
                    />    
                </Grid>
                <Grid item xs={1}>
                    <div className = {classes.textFieldUnderlined} >HDD</div>       
                </Grid>
                <Grid item xs = {11}> 
                    <TextField
                        onChange={this.hwReqValueChange_Capacity}
                        color="primary"
                        id="capacity"
                        name="capacity"
                        value ={this.state.hwReq_Temp['capacity']}
                        variant="standard" 
                        sx={{ display: "flex"}}
                        InputLabelProps={{ shrink: false, color: "primary"}}
                    />    
                </Grid>
                <Grid item xs= {1}>
                    <div className = {classes.textFieldUnderlined} >Display</div>       
                </Grid>
                <Grid item xs = {11}>
                    <TextField
                         onChange={this.hwReqValueChange_Display}
                         color="primary"
                         id="display"
                         name="display"
                         value ={this.state.hwReq_Temp['display']}
                        variant="standard" 
                        sx={{ display: "flex"}}
                        InputLabelProps={{ shrink: false, color: "primary"}}
                    />  
                    </Grid>  
                </Grid>
            </Grid> 
            <Typography component="h6" variant="h6">
                    ㅁ Price
                    <IconButton color="primary" onClick = {this.addPrice} size="large">
                      <AddCircleIcon fontSize= 'small'/>
                  </IconButton>
            </Typography>
            <Grid container sx = {{margin : '5px', marginTop : '10px'}}>
   
                {
                this.state.price_Temp
                ?
                
                this.state.price_Temp.map((dictpri, index)=>{

                return ShowPrice(dictpri, index, this);
                })
                :
                <div>

                </div>
                }
            </Grid>
            </Grid>

             
            
            
            <Grid container sx = {{margin : '5px', marginTop : '10px'}}>
                <Typography component="h6" variant="h6">
                    ㅁ Category
                    <IconButton color="primary" onClick = {this.addCategory} size="large">
                      <AddCircleIcon fontSize= 'small'/>
                  </IconButton>
                </Typography>
                {
                this.state.category_Temp
                ?
                
                this.state.category_Temp.map((strCat,index)=>{

                return ShowCategory(strCat, index, this);
                })
                :
                <div>

                </div>
                }
            </Grid>
            <Grid container sx = {{margin : '5px', marginTop : '10px'}}>
                <Typography component="h6" variant="h6">
                    ㅁ Company
                </Typography>
                <Grid container  style = {{margin : 1}}>
                    <Grid container>
                        <FormControlLabel
                        labelPlacement="start"
                        classes = {{
                        labelPlacementStart : classes.labelPlacementStart
                        }}
                            control={
                            <TextField
                            onChange={this.handleValueChange}
                            color="primary"
                            id="company_Temp"
                            name="company_Temp"
                            value ={this.state.company_Temp}
                            style={{ width : '300px', paddingLeft: '20px',justify : "flex-end" ,  display: "flex"}}
                            />    
                            }
                            label={"Nameㅤ"}
                        />
                    </Grid>
                    <Grid container  style = {{margin : 5}}>
                    <Grid container>
                            <FormControlLabel
                            labelPlacement="start"
                            classes = {{
                            labelPlacementStart : classes.labelPlacementStart
                            }}
                                control={
                                    <div>
                                    <img src={this.state.companyCI_Temp} alt=""  width="100"  onClick={()=>this.handleShowIMGDialog(this.state.companyCI_Temp)}/>
                                    <input
                                    accept="image/*"
                                    className={classes.input}
                                    id="contained-button-file"
                                    type="file"

                                    style={{display: 'block'}}
                                    onChange={this.handleUploadClick_CI}
                                    />
                                    </div>
                                }
                                label={"CIㅤㅤ"}
                            />
                    </Grid>
                </Grid>
            </Grid> 
            </Grid>



            <Grid container sx = {{margin : '5px', marginTop : '10px'}}>
                <Typography component="h6" variant="h6">
                    ㅁ Operation
                </Typography>
                <FormControl component="fieldset" style = {{margin : 10, width : '100%'}}>
                    <FormLabel component="legend">Type</FormLabel>
                    <RadioGroup row aria-label="isOnpremise_Temp" name="isOnpremise_Temp" value={String(this.state.isOnpremise_Temp)} onChange={this.handleValueChange}>
                    {/* <RadioGroup row aria-label="isOnpremise" name="isOnpremise" value={String(this.state.isOnpremise)} onChange={(e) =>this.radioHandleChange(e,"isOnpremise")}> */}
                        <FormControlLabel value={'true'} control={<Radio />} label="On-Premise" />
                        <FormControlLabel value={'false'} control={<Radio />} label="Web" />
                    </RadioGroup>
                </FormControl>
                {
                    String(this.state.isOnpremise_Temp) === 'true'
                    ?
                    <FormControl component="fieldset" style = {{margin : 10, width : '100%'}}>
                        <FormLabel component="legend">Install
                        {
                            String(this.state.isAPI_Temp) === 'true'
                            ?
                            <IconButton color="primary" onClick = {this.addLauncher} size="large">
                                <AddCircleIcon fontSize= 'small'/>
                            </IconButton>
                            :
                            null
                        }
                  </FormLabel>
                        <RadioGroup row aria-label="isAPI_Temp" name="isAPI_Temp" value={String(this.state.isAPI_Temp)} onChange={this.handleValueChange}>
                            <FormControlLabel value={'true'} control={<Radio />} label="API" />
                            <FormControlLabel value={'false'} control={<Radio />} label="Send E-Mail" />
                        </RadioGroup>
                    </FormControl>
                    :
                    <div>

                    </div>

                }
              
                {
                this.state.launcher_api_Temp
                ?
                String(this.state.isOnpremise_Temp) === 'true'
                ?
                String(this.state.isAPI_Temp) === 'true'
                ?
                this.state.launcher_api_Temp.map((strOpr,index)=>{

                    return ShowLauncher(strOpr, index, this);
                    })
                :
                null
                :
                <div  style ={{width : "90%"}}>
                <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    id="webURL_Temp"
                    name="webURL_Temp"
                    value = {this.state.webURL_Temp}
                    onChange = {this.handleValueChange}
                />
                </div>
              
                :
                <div>

                </div>
                }
            </Grid>





            <div style={{display: 'flex', justifyContent: 'flex-end', marginLeft: "auto"}}>
                <Button
                variant="contained"
                style ={{margin: 5}}
                onClick ={this.saveClick}
                disabled ={this.state.isApplying ? true: false}
                >
                Save 
                </Button>
                {
                    this.state.isApplying
                    ?
                    null
                    :
                    <Button
                    variant="contained"
                    style ={{margin: 5}}
                    color="error" 
                    onClick ={this.applyClick}
                    >
                    Apply
                    </Button>
                    
                }
            </div>
            
                
            </div>
                

            }
            
            {ShowIMGDialog}
            </div>
        );
    }
}

export default withStyles(useStyles, {withTheme: true})(withCookies(AppManageDetailInfoTab));




