import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import withStyles from '@mui/styles/withStyles';

import ManageCSTab from './ManageCSTab';
import ManageNewsTab from './ManageNewsTab';
import ManageSignupTab from './ManageSignupTab';
import ManageAppAccessTab from './ManageAppAccessTab';
import MakeArticleTab from './MakeArticleTab';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AppsIcon from '@mui/icons-material/Apps';
import DescriptionIcon from '@mui/icons-material/Description';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      active_tabStyle:{
        width: '100%',
        color: 'white',
        backgroundColor: 'red',
        }
  });



class ManageTab extends Component{
    constructor(props) {
        super(props);
        this.state = {
          id : this.props.id,
          password : this.props.password,

          appID : this.props.appID,
          tabIndex : 0,

        }
        this.tabChange  = this.tabChange.bind(this)
        }

        tabChange(event, newValue){
            this.setState({tabIndex : newValue})
        }

        render(){
            const {classes} =  this.props;

            return(

                <div className={classes.root}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.tabIndex}
                    onChange={this.tabChange}
                    // variant="scrollable"
                    // scrollButtons="on"
                    variant= 'fullWidth'
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                  >
                    <Tab label="CS" icon={<ContactSupportIcon/>} {...a11yProps(0)} />
                    <Tab label="News" icon={<FiberNewIcon/>} {...a11yProps(1)} />
                    <Tab label="Sign" icon={<LockOpenIcon/>} {...a11yProps(2)} />
                    <Tab label="App" icon={<AppsIcon/>} {...a11yProps(3)} />
                    <Tab label="Make" icon={<DescriptionIcon/>} {...a11yProps(4)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={this.state.tabIndex} index={0}>
                  <ManageCSTab id = {this.state.id} password = {this.state.password}/>
                </TabPanel>
                <TabPanel value={this.state.tabIndex} index={1}>
                  <ManageNewsTab id = {this.state.id} password = {this.state.password}/>
                </TabPanel>
                <TabPanel value={this.state.tabIndex} index={2}>
                  <ManageSignupTab id = {this.state.id} password = {this.state.password}/>
                </TabPanel>
                <TabPanel value={this.state.tabIndex} index={3}>
                  <ManageAppAccessTab id = {this.state.id} password = {this.state.password}/>
                </TabPanel>
                <TabPanel value={this.state.tabIndex} index={4}>
                  <MakeArticleTab id = {this.state.id} password = {this.state.password}/>
                </TabPanel>

                

              </div>
            )
        }
}

export default withStyles(useStyles)((ManageTab));




