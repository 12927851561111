import React, {Component} from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import Axios from 'axios';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';

import CircularProgress from '@mui/material/CircularProgress';
import AppInfo_Manager from '../AppInfo_Manager';

import serverURL from '../../serverURL.json';




const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      active_tabStyle:{
        width: '100%',
        color: 'white',
        backgroundColor: 'red',
        },
        labelPlacementStart: {
            display: "flex",
            justifyContent: 'space-between',
          },
          iconButton: {
            padding: 10,
          },
          input: {
            marginLeft: theme.spacing(1),
            flex: 1,
          },
          searchRoot: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            // width: 400,
          },
  });



class ManageAppAccessTab extends Component{
    constructor(props) {
        super(props);

        this.state = {
          
          id : this.props.id,
          password : this.props.password,

            searchString : "",
            searchString_Result : "",

            isAccess_False : true,
            isAccess_True : true,
            isApplying_False : true,
            isApplying_True : true,

            appList :[],
            appList_filterd :null,

            dlgShowEdit : false,
            dlgShowPreView : false,

            tempStatus:"",
            tempIsAccess : "",
            tempIsApplying : "",
            tempAppID : "",
            tempName : "",
            tempCompany : "",
            tempDescription : ""

        }
      this.appInfoHandleChange = this.appInfoHandleChange.bind(this);
      

          
    this.handleValueChange_Check = this.handleValueChange_Check.bind(this);
    this.editClick = this.editClick.bind(this);
    this.preViewClick = this.preViewClick.bind(this);

    this.closeShowEdit = this.closeShowEdit.bind(this);
    this.closeShowPreView = this.closeShowPreView.bind(this);
    
    

    }  

    preViewClick(_dicApp)
    {
        this.setState({dlgShowPreView  : true, tempAppID : _dicApp['_id'] })

    }

    appInfoHandleChange(e,_name){
        let nextState = {};
        nextState[_name] = e.target.value;
        this.setState(nextState);

    }


    handleValueChange_Check(e){
        let nextState = {};
        nextState[e.target.name] = !this.state[e.target.name];
        this.setState(nextState);
        let _isAccess_False = this.state.isAccess_False;
        let _isAccess_True = this.state.isAccess_True;
        let _isApplying_False = this.state.isApplying_False;
        let _isApplying_True = this.state.isApplying_True;
       
        if(e.target.name === 'isAccess_False')
        {
            _isAccess_False = !_isAccess_False;
        }

        if(e.target.name === 'isAccess_True')
        {
            _isAccess_True = !_isAccess_True;
        }

        if(e.target.name === 'isApplying_False')
        {
            _isApplying_False = !_isApplying_False;
        }

        if(e.target.name === 'isApplying_True')
        {
            _isApplying_True = !_isApplying_True;
        }

       
       
        this.setFiltered(this.state.appList, _isAccess_False, _isAccess_True,_isApplying_False,_isApplying_True )
  
      }



    componentWillMount(){

        this.getAppList_Axios('new',this.state.searchString_Result);

    }

    getAppList_Axios(_sortType, _searchString ){

        let params = {};
        let key = 'params';
        let value = {
            id : this.state.id,
            password : this.state.password,
            sortType : _sortType,
            searchString : _searchString
        }
        params[key] = value;
        Axios.get(serverURL.api+'/getAppList_Manager', params).then(
            response =>{
                if(response.status === 200)
                {
                const returnResult = response.data;

                if(returnResult.result)
                {

                    const _appList = returnResult.appList;
                    this.setState({appList : _appList});
                    this.setFiltered(_appList, this.state.isAccess_False, this.state.isAccess_True, this.state.isApplying_False,this.state.isApplying_True );
                    
                }else
                {
                    alert(returnResult.alert)
                }
                }else
                {
                alert('engResponseStatusNot200');
                }
            }
        ).catch((error) =>{
        alert('Unexpected Access, Need to refresh page getAppPermission');
        });
        this.setState({searchString_Result:_searchString, searchString: ""})
      }

      setFiltered(_appList, _isAccess_False, _isAccess_True, _isApplying_False, _isApplying_True)
      {
        
        let filterList_Access = [];
        if(_isAccess_False)
        {
            filterList_Access.push(false);
        }

        if(_isAccess_True)
        {
            filterList_Access.push(true);
        }

        let filterList_Apply = [];
        if(_isApplying_False)
        {
            filterList_Apply.push(false);
        }

        if(_isApplying_True)
        {
            filterList_Apply.push(true);
        }

        
        let tempList = []
        for(let num =0; num < _appList.length; num ++)
        {
          let _accessStatus = _appList[num]['isAccess'];
          let _applyStatus = _appList[num]['isApplying'];

          if(filterList_Access.includes(_accessStatus)){
            tempList.push(_appList[num]);
          }else if(filterList_Apply.includes(_applyStatus)){
            tempList.push(_appList[num]);
          }

        }

        this.setState({ appList_filterd :tempList })

      }

      closeShowPreView(){

        this.setState({dlgShowPreView : false})

      }

      closeShowEdit(e){
        if(e.currentTarget.name ==="ok"){
            let params = new URLSearchParams();
            params.append('id', this.state.id);
            params.append('password', this.state.password);
            params.append('appID', this.state.tempAppID);
            params.append('status', this.state.tempStatus);
            params.append('isAccess', this.state.tempIsAccess);
            params.append('isApplying', this.state.tempIsApplying);
            Axios.post(serverURL.api+'/editAppInfo_Manager', params).then(
                response =>{
                    if(response.status === 200)
                    {
                    const returnResult = response.data;
                    if(returnResult.result)
                    {
                        alert('The App info is changed')
                        this.getAppList_Axios('new', this.state.searchString_Result);
                    }else
                    {
                        alert(returnResult.alert)
                    }
                    }else
                    {
                    alert('engResponseStatusNot200');
                    }
                }
                ).catch((error) =>{
                    alert('Unexpected Access, Need to refresh page editApp');
                }
            );
  
  
        }
        this.setState({dlgShowEdit : false})
      }


    editClick(_dicApp)
    {

      let _status = String(_dicApp['status']);
      let _isAccess = String(_dicApp['isAccess']);
      let _isApplying = String(_dicApp['isApplying']);
      let _appID = _dicApp['_id'];
      let _name = _dicApp['name_Temp'];
      let _company = _dicApp['company_Temp'];
      let _description = _dicApp['description_Temp'];

      this.setState({
        dlgShowEdit :true,
        tempStatus : _status,
        tempIsAccess : _isAccess,
        tempIsApplying : _isApplying,
        tempAppID : _appID,
        tempName : _name,
        tempCompany : _company,
        tempDescription : _description

      })

    }

   

    render(){
        const {classes} =  this.props;


        function ShowLauncher(_strOpr, _index, _this){
          return <Grid container  style = {{margin : 1}}>
          <Grid container>
        <div>
        <Divider  component="li" />

        </div>
    </Grid>
 
        <Container>
        <FormControlLabel
    labelPlacement="start"
    classes = {{
      labelPlacementStart : classes.labelPlacementStart
    }}
        control={
        <TextField
          color="primary"
          id="name"
          name="name"
          value ={_strOpr['name']}
          style={{ width : '100%', paddingLeft: '10px',justify : "flex-end" ,  display: "flex"}}
        />    
        }
        label={"Name"}
    />
    </Container>
    <Container>
    <FormControlLabel
    labelPlacement="start"
    classes = {{
      labelPlacementStart : classes.labelPlacementStart
    }}
        control={
        <TextField
          color="primary"
          id="file"
          name="file"
          value ={_strOpr['file']}
          style={{ width : '100%', paddingLeft: '25px',justify : "flex-end" ,  display: "flex"}}
        />    
        }
        label={"File"}
    />
    </Container>
   </Grid> 

      }






        function ShowApp(_dicApp, _index, _this){


            return (
              <Paper style = {{margin : 10}}>
                 <div>
            <Divider  component="li" />
          <br/>
          <TextField
              id="status"
              name="status"
              value ={_dicApp['status']}
              label ={"Status"}
              style = {{margin : 10, width : '82%'}}
              inputProps = {"aria-readonly"}
            />
          <FormControl component="fieldset" style = {{margin : 10, width : '40%'}}>
            <FormLabel component="legend">Access Status</FormLabel>
            <RadioGroup row aria-label="status" name="access_status" value={(_dicApp['isAccess'])}>
              <FormControlLabel value={false} control={<Radio />} label="Not Access" />
              <FormControlLabel value={true} control={<Radio />} label="Access Done" />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset" style = {{margin : 10, width : '40%'}}>
            <FormLabel component="legend">Apply Status</FormLabel>
            <RadioGroup row aria-label="status" name="apply_status" value={(_dicApp['isApplying'])}>
              <FormControlLabel value={true} control={<Radio />} label="Applying" />
              <FormControlLabel value={false} control={<Radio />} label="Not Applying" />
            </RadioGroup>
          </FormControl>
            
             <TextField
              id="name"
              name="name"
              value ={_dicApp['name_Temp']}
              label ={"Name"}
              style = {{margin : 10, width : '40%'}}
              inputProps = {"aria-readonly"}
            />
            <TextField
              id="company"
              name="company"
              value ={_dicApp['company_Temp']}
              label ={"Company"}
              style = {{margin : 10, width : '40%'}}
              inputProps = {"aria-readonly"}
            />
            <TextField
              id="description"
              name="description"
              value ={_dicApp['description_Temp']}
              label ={"Description"}
              style = {{margin : 10, width : '82%'}}
              inputProps = {"aria-readonly"}
            />
            <FormControl component="fieldset" style = {{margin : 10, width : '40%'}}>
              <FormLabel component="legend">Type</FormLabel>
              <RadioGroup row aria-label="status" name="access_status" value={(_dicApp['isOnpremise_Temp'])}>
                <FormControlLabel value={true} control={<Radio />} label="On-Premise" />
                <FormControlLabel value={false} control={<Radio />} label="Web" />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" style = {{margin : 10, width : '40%'}}>
              <FormLabel component="legend">Install</FormLabel>
              <RadioGroup row aria-label="isAPI_Temp" name="isAPI_Temp" value={(_dicApp['isAPI_Temp'])}>
                <FormControlLabel value={true} control={<Radio />} label="API" />
                <FormControlLabel value={false} control={<Radio />} label="Send E-Mail" />
              </RadioGroup>
            </FormControl>
            <TextField
              id="webURL_Temp"
              name="webURL_Temp"
              value ={_dicApp['webURL_Temp']}
              label ={"Web URL"}
              style = {{margin : 10, width : '82%'}}
              inputProps = {"aria-readonly"}
            />
            {
            _dicApp['launcher_api_Temp'].map((strOpr,index)=>{

              return ShowLauncher(strOpr, index, this);
              })
              
            }
          



            </div>
            
            <div  style={{display: 'flex', justifyContent: 'flex-end'}}>
            {/* , marginLeft: "auto" */}
            <Button
              variant="contained"
              style ={{margin: 20}}
              onClick ={() => _this.preViewClick(_dicApp)}>
            Pre-View 
            </Button>
            <Button
            variant="contained"
            style ={{margin: 20}}
            color= {"primary"} 
            onClick ={() => _this.editClick(_dicApp)}
            >
            Edit 
            </Button>
            </div>    

           
         </Paper>
            );

    }

    
    const ShowPreViewDialog = (
        <Dialog
        open={this.state.dlgShowPreView}
        onClose={this.closeShowPreView}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">App Pre-View</DialogTitle>
      <DialogContent>
      <AppInfo_Manager managerID = "seatrustManager" appID = {this.state.tempAppID}/>
      </DialogContent>
        <DialogActions>
          <Button name ='close' onClick={this.closeShowPreView} color="primary" >
              Close
          </Button>
          </DialogActions>
      </Dialog>
      );


    const ShowEditDialog = (
        <Dialog
        open={this.state.dlgShowEdit}
        onClose={this.closeShowEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit App Info</DialogTitle>
      <DialogContent>
      <TextField
            id="status"
            name="status"
            value ={this.state.tempStatus}
            label ={"Status"}
            style = {{margin : 10, width : '80%'}}
            onChange = {(e)=>this.appInfoHandleChange(e,'tempStatus')}
          />
        <FormControl component="fieldset" style = {{margin : 10, width : '40%'}}>
          <FormLabel component="legend">Access Status</FormLabel>
          <RadioGroup row aria-label="status" name="access_status" value={String(this.state.tempIsAccess)} onChange={(e) =>this.appInfoHandleChange(e,"tempIsAccess")}>
            <FormControlLabel value={'false'} control={<Radio />} label="Not Access" />
            <FormControlLabel value={'true'} control={<Radio />} label="Access Done" />
          </RadioGroup>
        </FormControl>
        <FormControl component="fieldset" style = {{margin : 10, width : '40%'}}>
          <FormLabel component="legend">Apply Status</FormLabel>
          <RadioGroup row aria-label="status" name="apply_status" value={String(this.state.tempIsApplying)} onChange={(e) =>this.appInfoHandleChange(e,"tempIsApplying")}>
            <FormControlLabel value={'true'} control={<Radio />} label="Applying" />
            <FormControlLabel value={'false'} control={<Radio />} label="Not Applying" />
          </RadioGroup>
        </FormControl>
          
           <TextField
            id="name"
            name="name"
            value ={this.state.tempName}
            label ={"Name"}
            style = {{margin : 10, width : '40%'}}
            inputProps = {"aria-readonly"}
          />
          <TextField
            id="company"
            name="company"
            value ={this.state.tempCompany}
            label ={"Company"}
            style = {{margin : 10, width : '40%'}}
            inputProps = {"aria-readonly"}
          />
          <TextField
            id="description"
            name="description"
            value ={this.state.tempDescription}
            label ={"Description"}
            style = {{margin : 10, width : '80%'}}
            inputProps = {"aria-readonly"}
          />
      </DialogContent>
        <DialogActions>
        <Button name ='ok' onClick={this.closeShowEdit} color="primary" >
              Edit
          </Button>
          <Button name ='close' onClick={this.closeShowEdit} color="primary" >
              Close
          </Button>
          </DialogActions>
  
      </Dialog>
      );





        return (
          <div className={classes.root}>
               <Grid item xs={12}>
              <Typography variant="h5" color="inherit" style={{ fontWeight: 600 }}>
                {
                  this.state.searchString_Result === ""
                  ?
                  "All Apps"
                  :
                  "Search : " + this.state.searchString_Result
                }
                  
              </Typography>
              </Grid>
              <Paper component="form" className={classes.searchRoot}>
              <InputBase
                  className={classes.input}
                  placeholder="Search App"
                  value = {this.state.searchString}
                  name = "searchString"
                  onChange = {(e)=>this.appInfoHandleChange(e,'searchString')}
                  onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                      // Do code here
                      this.getAppList_Axios('new',this.state.searchString);
                      ev.preventDefault();
                  }
                  }}
              />
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick ={()=>this.getAppList_Axios('new',this.state.searchString)}
                size="large">
                  <SearchIcon />
              </IconButton>
          </Paper>
          <br/>
              <FormGroup row>
              <FormControlLabel
                      control={
                      <Checkbox
                          checked={this.state.isAccess_False}
                          onChange={this.handleValueChange_Check}
                          name="isAccess_False"
                          color="primary"
                      />
                      }
                      label="Not Access"
                  />
                  <FormControlLabel
                      control={
                      <Checkbox
                          checked={this.state.isAccess_True}
                          onChange={this.handleValueChange_Check}
                          name="isAccess_True"
                          color="primary"
                      />
                      }
                      label="Access Done"
                  />
                  <FormControlLabel
                      control={
                      <Checkbox
                          checked={this.state.isApplying_True}
                          onChange={this.handleValueChange_Check}
                          name="isApplying_True"
                          color="primary"
                      />
                      }
                      label="Applying"
                  />
                   <FormControlLabel
                      control={
                      <Checkbox
                          checked={this.state.isApplying_False}
                          onChange={this.handleValueChange_Check}
                          name="isApplying_False"
                          color="primary"
                      />
                      }
                      label="Not Applying"
                  />
                 
              </FormGroup>
             
              {
              this.state.appList_filterd === null 
              ?
              <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
              <CircularProgress />
              </div>
              :
              this.state.appList_filterd.length ===0
              ?
              <div style = {{ display: 'flex',  alignItems: 'center', justifyContent: 'center'}}>
                  No List
              </div>
              :
              this.state.appList_filterd.map((dicApp,index)=>{

              return ShowApp(dicApp, index, this);
              })
              
              }
          {ShowPreViewDialog}
          {ShowEditDialog}
          </div>
        );
    }
}

export default withStyles(useStyles)(ManageAppAccessTab);




