// import React, {Component} from 'react';

// class Main extends Component{
//   render(){
//     return (
//       <div>
//         Hello World
//       </div>
//     );
//   }
// }


// export default Main;





import React, {useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';

import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';

import Axios from 'axios';
import { withCookies } from 'react-cookie';
import {delete_cookie } from 'sfcookies';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import AppsIcon from '@mui/icons-material/Apps';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BuildIcon from '@mui/icons-material/Build';

import serverURL from '../serverURL.json';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import StoreIcon from '@mui/icons-material/Store';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Hidden from '@mui/material/Hidden';


const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    color : '#0088C7',
    font : 'bold',
    flexGrow: 1,
  },
  link: {
    // margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundImage: `url(${'/img/mainIMG.jpg'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },

}));

// const tiers = [
//   {
//     title: 'Free',
//     price: '0',
//     description: ['10 users included', '2 GB of storage', 'Help center access', 'Email support'],
//     buttonText: 'Sign up for free',
//     buttonVariant: 'outlined',
//   },
//   {
//     title: 'Pro',
//     subheader: 'Most popular',
//     price: '15',
//     description: [
//       '20 users included',
//       '10 GB of storage',
//       'Help center access',
//       'Priority email support',
//     ],
//     buttonText: 'Get started',
//     buttonVariant: 'contained',
//   },
//   {
//     title: 'Enterprise',
//     price: '30',
//     description: [
//       '50 users included',
//       '30 GB of storage',
//       'Help center access',
//       'Phone & email support',
//     ],
//     buttonText: 'Contact us',
//     buttonVariant: 'outlined',
//   },
// ];



export default withCookies(function Homepage(props) {
  
    const { cookies } = props;
  const classes = useStyles();
  const imgKRCI = '/img/krCI_head.jpg';
  // const imgMainIMG = '/img/mainIMG.jpg';
  let [user, setUser] = useState('')
  let [name , setName] = useState('')  
  let [company , setCompany] = useState('')  
  let [profilePic , setProfilePic] = useState('')  
  
  let [accountMoreMenuPosition , setAccountMoreMenuPosition] = useState(null)  
  let [accountMoreMenuShow , setAccountMoreMenuShow] = useState(false)  

  let [showNavMoreMenu , setShowNavMoreMenu] = useState(false)  
  let [navMoreMenuPosition , setNavMoreMenuPosition] = useState(null)  
  
  useEffect(() => {
    const userData = cookies.get('user');
    if(userData !==undefined)
    {
        let params = {};
        let key = 'params';
        let value = {
            'userID' : userData._id,
            'refToken' : userData.refToken,
            'accToken' : userData.accToken,
        }
        params[key] = value;
        Axios.get(serverURL.api + '/getUserNameCompanyIMG', params).then(
          response =>{
            if(response.status === 200)
            {
                const returnResult = response.data;
            if(returnResult.result)
            {
              setName(name = returnResult.name)
                setCompany(company = returnResult.company)
                setProfilePic(profilePic = returnResult.profilePic)
                if(returnResult.refToken !== userData.refToken ||
                    returnResult.accToken !== userData.accToken){
                      let userData_change = {
                        _id: userData._id,
                        refToken : returnResult.refToken,
                        accToken : returnResult.accToken,
                        auth : true,
                      };
                      
                      const { cookies } = props;
                      cookies.set('user', userData_change, { path: '/', expires: 0});
                    }
                
            }else
            {

                alert(returnResult.alert)

            }
            }else
            {
              alert('engResponseStatusNot200');
            }
          }
        ).catch((error) =>{
          alert('Unexpected Access, Need to refresh page getid');
          }
        );




    }
    setUser(user = userData)
    


  // window.addEventListener('mousemove', () => {});

  // // returned function will be called on component unmount 
  // return () => {
  //   window.removeEventListener('mousemove', () => {})
  // }
}, [])


function goApp(){
    window.location.href = '/MyApp';
}
function goMessanger(){
  window.location.href = '/Messenger';
}

function logoutEvent(){
    delete_cookie('user');
    window.location.href = '/';
}

function goAppManagement(){
  window.location.href = '/AppManagement';

}

function goSetting(){
  window.location.href = '/UserSetting';

}

function showMoreMenuAccount(e){
    setAccountMoreMenuPosition(accountMoreMenuPosition = e.currentTarget)
    setAccountMoreMenuShow(accountMoreMenuShow = true)

  }

function closeMoreMenuAccount(){
    setAccountMoreMenuPosition(accountMoreMenuPosition = null)
    setAccountMoreMenuShow(accountMoreMenuShow = false)

  }


  function navMoreMenu(e){

    setNavMoreMenuPosition(navMoreMenuPosition = e.currentTarget)
  setShowNavMoreMenu(showNavMoreMenu = true)


  }

  function closeNavMoreMenu(){

    setNavMoreMenuPosition(navMoreMenuPosition = null)
  setShowNavMoreMenu(showNavMoreMenu = false)


  }


  const navMoreMenuButton = (
    <Menu
      id="moreMenu"
      anchorEl = {navMoreMenuPosition}
      keepMounted
      open = {showNavMoreMenu}
      onClose ={closeNavMoreMenu}
      TransitionComponent = {Fade}
    >
      <MenuItem onClick={goStore}>
      <StoreIcon/>App Store 
      </MenuItem>
      <MenuItem onClick={goCS}>
      <ContactSupportIcon/>Support
      </MenuItem>

    </Menu>

  )

  function goStore(){
    window.location.href = '/Store';

  }

  function goCS(){
    window.location.href = '/ContactSupport';

  }


  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
              <Link href ="/" >
              <img src= {imgKRCI}  alt="Logo" />
              </Link>
          </Typography>
          <nav>
            {/* <IconButton onClick={navMoreMenu}>
              <MoreVertIcon/>
            </IconButton> */}
            <Link variant="button" color="textPrimary" href="/Store" className={classes.link}>
              <IconButton size="large">
              <StoreIcon/>
              <Hidden mdDown>
          <Typography variant="body1" color="inherit" noWrap>
              {"App Store"}
              </Typography>
            </Hidden>
              </IconButton>
              {/* Store */}
            </Link>
            <Link variant="button" color="textPrimary" href="/ContactSupport" className={classes.link}>
            <IconButton size="large">
            <ContactSupportIcon/>
            <Hidden mdDown>
            <Typography variant="body1" color="inherit" noWrap>
              {"Support"}
              </Typography>
            </Hidden>
              </IconButton>
              {/* Support */}
            </Link>
          </nav>
          {
              user !== undefined
              ?<>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  size="large"
                  onClick = {showMoreMenuAccount}
                 >
                  <Avatar aria-label="profile" src = {profilePic} />
                  </IconButton>
                  <Menu
                        id="moreMenu"
                        anchorEl={accountMoreMenuPosition}
                        keepMounted
                        open={accountMoreMenuShow}
                        onClose={closeMoreMenuAccount}
                        TransitionComponent={Fade}
                        /*getContentAnchorEl={null}*/
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        >
                         <MenuItem><span>&nbsp;&nbsp;</span>Name : {name}</MenuItem> 
                         <MenuItem><span>&nbsp;&nbsp;</span>Company : {company}</MenuItem> 
                         <Divider/>
                        {/* <MenuItem onClick = {() => this.goChannel(this.state.user._id)} ><AccountBoxIcon/><span>&nbsp;&nbsp;</span>{localString.myChannel}</MenuItem>
                         <MenuItem onClick = {this.goLikeBookmark}><LoyaltyIcon/><span>&nbsp;&nbsp;</span>{localString.likeBookmark}</MenuItem>
                         <MenuItem onClick = {this.goSupport}><EmojiPeopleIcon/><span>&nbsp;&nbsp;</span>{localString.support}</MenuItem>
                         <Divider/>
                         <MenuItem onClick = {this.showLangDlg}><TranslateIcon/><span>&nbsp;&nbsp;</span>{localString.language}</MenuItem>
                         <MenuItem onClick = {this.goUserSetting}><SettingsIcon/><span>&nbsp;&nbsp;</span>{localString.setting}</MenuItem>
                         <MenuItem onClick = {this.goContactSupport}><ContactSupportIcon/><span>&nbsp;&nbsp;</span>{localString.contactSupport}</MenuItem>*/}
                         <MenuItem onClick = {goApp} ><AppsIcon/><span>&nbsp;&nbsp;</span>My App</MenuItem>
                         <MenuItem onClick = {goMessanger} ><QuestionAnswerIcon/><span>&nbsp;&nbsp;</span>Messenger</MenuItem>
                         <MenuItem onClick = {goAppManagement}><BuildIcon/><span>&nbsp;&nbsp;</span>App Management</MenuItem>
                         <MenuItem onClick = {goSetting}><SettingsIcon/><span>&nbsp;&nbsp;</span>Setting</MenuItem>
                         <MenuItem onClick = {logoutEvent} ><ExitToAppIcon/><span>&nbsp;&nbsp;</span>Logout</MenuItem> 
                    </Menu>
                </>
            //   <Button href="#" color="primary" variant="outlined" className={classes.link} onClick = {logoutEvent}>
            //   {id}
            // </Button>
              :
            <Button href="/Signin" color="primary" variant="outlined" className={classes.link}>
            Login
          </Button>
          }
          
        </Toolbar>
      </AppBar>

          {navMoreMenuButton}
    </React.Fragment>
  );
})

