import React from 'react'
import {makeStyles} from '@mui/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography, Box } from '@mui/material';
import Link from '@mui/material/Link';
import Footer from '../../../Footer';

const useStyles = makeStyles((theme) => ({  
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    content:{ 
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      outline: '#d3d3d3 solid 1px',
      padding: '60px',
      outlineStyle: 'auto',
      
    }
  }));
  
function userTerm1() {
    const classes = useStyles;

    return (<>
        <Container component="main" maxWidth="lg">
   
        <CssBaseline/>
        <div className={classes.paper}>
            <Link href="/" variant="h3" underline="hover">
            {/*"SeaTrust"*/}
            </Link>
            <div className = {classes.content}>
            <br/><br/>
                <Typography variant = "h5">개인정보 수집 및 이용 동의</Typography>
                <br/>
                <Typography variant = "h6">한국선급은 개인정보를 안전하게 취급하는데 최선을 다합니다.</Typography>
                <br/>
                <Typography variant = "body1"> 
                본인은 SeaTrust (이하 ‘회사’라 함)에 회원가입 시 개인정보제공이 필요하다는 것을 이해하고 있으며, 이를 위해 “개인정보보호법”등 규정 등에 따라 아래의 개인정보를 수집 · 이용하는 것에 동의합니다.
                </Typography>
                <ol>
                  <li>개인정보 항목</li>
                  <ul>
                    <li>필수항목 : 로그인ID, 비밀번호, 전자우편</li>
                  </ul> 
                  <li>수집 이용목적</li>
                  <ul><li>컨텐츠 제공, 특정 맞춤 서비스 제공, 본인인증, 정보발송 서비스제공</li></ul>
                  <li>보유기간</li>
                  <ul><li>회원 탈퇴 시 파기</li></ul>
                </ol>
            </div>
        </div>

    <Box mt={5}>
      <Footer />
    </Box>
    </Container></>
    )
}

export default userTerm1
