import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import Axios from 'axios';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';

import CircularProgress from '@mui/material/CircularProgress';

import serverURL from '../../serverURL.json';


const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      active_tabStyle:{
        width: '100%',
        color: 'white',
        backgroundColor: 'red',
        },
        labelPlacementStart: {
            display: "flex",
            justifyContent: 'space-between',
          },
  });



class ManageSignupTab extends Component{
    constructor(props) {
        super(props);

        this.state = {
            id : this.props.id,
            password : this.props.password,
          
          
            isAccess_False : true,
            isAccess_True : true,

            signList :[],
            signList_filterd :null,

            dlgShowEdit : false,
            
            tempUserID : "",
            tempIsAccess : false,
            tempID : "",
            tempEmail : "",
            tempName : "",
            tempCompany : "",



        }
    //   this.handleValueChange = this.handleValueChange.bind(this);
      

    this.handleValueChange_Check = this.handleValueChange_Check.bind(this);
    
    this.setFiltered = this.setFiltered.bind(this);
    
    this.editClick = this.editClick.bind(this);

    this.closeShowEdit =this.closeShowEdit.bind(this)

    
    this.signInfoHandleChange =this.signInfoHandleChange.bind(this)
    
    }

    signInfoHandleChange(e, _handleName)
    {
      let nextState = {};
      nextState[_handleName] = e.target.value;
      this.setState(nextState);
    }

    closeShowEdit(e){
        if(e.currentTarget.name ==="ok"){
            let params = new URLSearchParams();
            params.append('id', this.state.id);
            params.append('password', this.state.password);
            params.append('userID', this.state.tempUserID);
            params.append('isAccess', this.state.tempIsAccess);
            Axios.post(serverURL.api + '/editSignInfo_Manager', params).then(
                response =>{
                    if(response.status === 200)
                    {
                    const returnResult = response.data;
                    if(returnResult.result)
                    {
                        alert('The Sign info is changed')
                        this.getSignList_Axios();
                    }else
                    {
                        alert(returnResult.alert)
                    }
                    }else
                    {
                    alert('engResponseStatusNot200');
                    }
                }
                ).catch((error) =>{
                    alert('Unexpected Access, Need to refresh page addApp');
                }
            );
  
  
        }
        this.setState({dlgShowEdit : false})
      }

    
    editClick(_dicSign)
    {
      console.log(_dicSign)

      let _isAccess = (_dicSign['isAccess']);
      let _userID = _dicSign['_id'];
      let _id = _dicSign['id'];
      let _email = _dicSign['email'];
      let _name = _dicSign['name'];
      let _company = _dicSign['company'];

      this.setState({
        dlgShowEdit :true,
        
        tempIsAccess : String(_isAccess),
        tempUserID : _userID,
        tempID : _id,
        tempEmail : _email,
        tempName : _name,
        tempCompany : _company,

      })
     



    }

    componentWillMount(){

        this.getSignList_Axios();

    }

    getSignList_Axios(){

        let params = {};
        let key = 'params';
        let value = {
            id : this.state.id,
            password : this.state.password,
        }
        params[key] = value;
        Axios.get(serverURL.api + '/getSignList', params).then(
            response =>{
                if(response.status === 200)
                {
                const returnResult = response.data;

                if(returnResult.result)
                {
                    const _signList = returnResult.signList;
                    this.setState({signList : _signList});
                    this.setFiltered(_signList, this.state.isAccess_False, this.state.isAccess_True);
                }else
                {
                    alert(returnResult.alert)
                }
                }else
                {
                alert('engResponseStatusNot200');
                }
            }
        ).catch((error) =>{
        alert('Unexpected Access, Need to refresh page getAppPermission');
        });
      }

    handleValueChange_Check(e){
        let nextState = {};
        nextState[e.target.name] = !this.state[e.target.name];
        this.setState(nextState);
        let _isAccess_False = this.state.isAccess_False;
        let _isAccess_True = this.state.isAccess_True;
       
        if(e.target.name === 'isAccess_False')
        {
            _isAccess_False = !_isAccess_False;
        }

        if(e.target.name === 'isAccess_True')
        {
            _isAccess_True = !_isAccess_True;
        }

       
       
        this.setFiltered(this.state.signList, _isAccess_False, _isAccess_True )
  
      }
   

      setFiltered(_signList, _isAccess_False, _isAccess_True)
      {
        
        let filterList = [];
        if(_isAccess_False)
        {
          filterList.push(false);
        }

        if(_isAccess_True)
        {
          filterList.push(true);
        }

        
        let tempList = []
        for(let num =0; num < _signList.length; num ++)
        {
          let _status = _signList[num]['isAccess'];
          if(filterList.includes(_status)){
          tempList.push(_signList[num]);
          }
        }
        this.setState({ signList_filterd :tempList })


      }


    render(){
        const {classes} =  this.props;

     
        const ShowEditDialog = (
            <Dialog
            open={this.state.dlgShowEdit}
            onClose={this.closeShowEdit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Sign Info</DialogTitle>
          <DialogContent>
          <FormControl component="fieldset" style = {{margin : 10, width : '80%'}}>
               <FormLabel component="legend">Status</FormLabel>
               <RadioGroup row aria-label="status" name="status1" value={String(this.state.tempIsAccess)} onChange={(e) =>this.signInfoHandleChange(e,"tempIsAccess")}>
                 <FormControlLabel value={'false'} control={<Radio />} label="Not Access" />
                 <FormControlLabel value={'true'} control={<Radio />} label="Access Done" />
               </RadioGroup>
            </FormControl>
             <TextField
                 id="id"
                 name="id"
                 value ={this.state.tempID}
                 label ={"ID"}
                 style = {{margin : 10, width : '40%'}}
                 inputProps = {"aria-readonly"}
               />
              
                <TextField
                 id="email"
                 name="email"
                 value ={this.state.tempEmail}
                 label ={"E-Mail"}
                 style = {{margin : 10, width : '40%'}}
                 inputProps = {"aria-readonly"}
               />
                <TextField
                 id="name"
                 name="name"
                 value ={this.state.tempName}
                 label ={"Name"}
                 style = {{margin : 10, width : '40%'}}
                 inputProps = {"aria-readonly"}
               />
               <TextField
                 id="company"
                 name="company"
                 value ={this.state.tempCompany}
                 label ={"Company"}
                 style = {{margin : 10, width : '40%'}}
                 inputProps = {"aria-readonly"}
               />
          </DialogContent>
            <DialogActions>
            <Button name ='ok' onClick={this.closeShowEdit} color="primary" >
                  Edit
              </Button>
              <Button name ='close' onClick={this.closeShowEdit} color="primary" >
                  Close
              </Button>
              </DialogActions>
      
          </Dialog>
          );



        function ShowSign(_dicSign, _index, _this){


                 return  <Paper style = {{margin : 10}}>
                    <div>
               <Divider  component="li" />
             <br/>
             <FormControl component="fieldset" style = {{margin : 10, width : '80%'}}>
               <FormLabel component="legend">Status</FormLabel>
               <RadioGroup row aria-label="status" name="status1" value={(_dicSign['isAccess'])}>
                 <FormControlLabel value={false} control={<Radio />} label="Not Access" />
                 <FormControlLabel value={true} control={<Radio />} label="Access Done" />
               </RadioGroup>
               </FormControl>
             <TextField
                 id="id"
                 name="id"
                 value ={_dicSign['id']}
                 label ={"ID"}
                 style = {{margin : 10, width : '40%'}}
                 inputProps = {"aria-readonly"}
               />
              
                <TextField
                 id="email"
                 name="email"
                 value ={_dicSign['email']}
                 label ={"E-Mail"}
                 style = {{margin : 10, width : '40%'}}
                 inputProps = {"aria-readonly"}
               />
                <TextField
                 id="name"
                 name="name"
                 value ={_dicSign['name']}
                 label ={"Name"}
                 style = {{margin : 10, width : '40%'}}
                 inputProps = {"aria-readonly"}
               />
               <TextField
                 id="company"
                 name="company"
                 value ={_dicSign['company']}
                 label ={"Company"}
                 style = {{margin : 10, width : '40%'}}
                 inputProps = {"aria-readonly"}
               />
               </div>
               <div  style={{display: 'flex', justifyContent: 'flex-end', marginLeft: "auto"}}>
               <Button
               variant="contained"
               style ={{margin: 20}}
               color= {"primary"} 
               onClick ={() => _this.editClick(_dicSign)}
               >
               Edit 
               </Button>
               </div>    

              
            </Paper>
 
         }



        return(
            <div className={classes.root}>

                <FormGroup row>
                <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.state.isAccess_False}
                            onChange={this.handleValueChange_Check}
                            name="isAccess_False"
                            color="primary"
                        />
                        }
                        label="Not Access"
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.state.isAccess_True}
                            onChange={this.handleValueChange_Check}
                            name="isAccess_True"
                            color="primary"
                        />
                        }
                        label="Access Done"
                    />
                   
                </FormGroup>




           {
            this.state.signList_filterd === null 
            ?
            <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
              <CircularProgress />
            </div>
            :
            this.state.signList_filterd.length ===0
            ?
            <div style = {{ display: 'flex',  alignItems: 'center', justifyContent: 'center'}}>
                No List
            </div>
            :
            this.state.signList_filterd.map((dicSign,index)=>{

            return ShowSign(dicSign, index, this);
            })
            
            }

            {ShowEditDialog}              
            </div>
        )
    }
}

export default withStyles(useStyles)(ManageSignupTab);




