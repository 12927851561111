import { Grid, List, ListItem, TextField,IconButton } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles';
//            Object.keys(subjects).map((keyName, i) =
import { RemoveCircle } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    TextField:{ 
        display: "flex", 
        //float: 'right',
        width: '90%'
    }
}));

function SubTextFieldObjContainer(props) {
    const classes = useStyles(); 
 
    const {onChangeHandler, onDeleteHandler, objArr, objName} = props
    return (
        <div>
            
            <List 
                dense ={true}
                disablePadding={true}
                sx = {{width : "100%", outlineStyle: 'auto'}}>
                
                {objArr? objArr.map((obj, index)=>{
                    return (
                    <ListItem key ={index} sx= {{outlineStyle:'auto'}}>
                        <IconButton edge = "start" aria-label ="delete" size="large" color="error" onClick = {(e)=>onDeleteHandler(e,index,objName)}>
                            <RemoveCircle fontSize= 'small'/>
                        </IconButton>     
                        <List 
                            dense ={true}
                            disablePadding={true}
                            sx = {{width : "100%",outlineStyle: 'auto'}}>
                        {     
                            obj? Object.keys(obj).map((keyName,objIndex)=>{ 
                            if(keyName !== "isTemp"){
                                return(                      
                                <div key = {keyName}>
                                    <TextField 
                                        onChange={(e)=>onChangeHandler(e,index,objName,keyName )}
                                        color="primary"
                                        name= {keyName}
                                        value ={obj[keyName]}
                                        variant="standard" 
                                        label ={keyName}
                                        //fullWidth
                                        className ={classes.TextField}
                                        InputLabelProps={{color: "primary"}}
                                    />                                            
                                </div>
                        )}}):null}          
                        </List>       
                    </ListItem>
                    )
            }):<></>}
                    
            </List>
        </div>
    )
}

export default SubTextFieldObjContainer
