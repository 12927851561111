import React, { Component , Fragment } from 'react';
import Axios from 'axios';

import StoreIcon from '@mui/icons-material/Store';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Footer from './Footer';
import Header from './Header'

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

import CircularProgress from '@mui/material/CircularProgress';


import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';


import SortIcon from '@mui/icons-material/Sort';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import serverURL from '../serverURL.json';



const styles = theme =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  HeadLine : {
    fontSize: 'calc(1vw + 1.5vh)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace : 'pre-wrap'
  },


  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    // width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },

  contents: {
    overflow: "hidden",
    display: "-webkit-box",
    whiteSpace: 'pre-line',
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical"
  }


});


class Store extends Component {
  constructor(props) {
    super(props);
    this.state = {
        title : "",
        contents : "",
        fullName : "",
        email : "",
        listFilterPosition : null,
        listFilterShow : false,

        appList : null,
        numAppCard : 4,
        // filterType : "view"

        sortType : "view",
        searchString : "",
        searchString_Result : "",


      }
    this.handleValueChange = this.handleValueChange.bind(this);

    this.listFilterClick =this.listFilterClick.bind(this);
    this.listFilterClose =this.listFilterClose.bind(this);
    this.filterClick_new = this.filterClick_new.bind(this);
    this.filterClick_view = this.filterClick_view.bind(this);

    this.moreApp = this.moreApp.bind(this);
    this.searchApp = this.searchApp.bind(this);

    }


    componentWillMount(){
        this.getAppList_Axios(this.state.sortType, this.state.searchString);

    }
    
    getAppList_Axios(_sortType, _searchString){

        let params = {};
        let key = 'params';
        let value = {
            sortType : _sortType,
            searchString : _searchString
        }
        params[key] = value;
        Axios.get(serverURL.api + '/getAppList', params).then(
        response =>{
            if(response.status === 200)
            {
            const returnResult = response.data;

            if(returnResult.result)
            {
                this.setState({appList : returnResult.appList});
            }else
            {
                alert(returnResult.alert)
            }
            }else
            {
            alert('engResponseStatusNot200');
            }
        }
        ).catch((error) =>{
        alert('Unexpected Access, Need to refresh page getAppList');
        }
        );

    }

    searchApp(){
      this.getAppList_Axios(this.state.sortType, this.state.searchString);
      this.setState({searchString_Result : this.state.searchString})
      // this.setState({searchString : ""})
    }

    handleValueChange(e){
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);

    }


    filterClick_new(e){
        this.setState({listFilterPosition : null, listFilterShow : false, sortType : "new"});
        this.getAppList_Axios("new", this.state.searchString);
  
      }
  
      filterClick_view(e){
        this.setState({listFilterPosition : null, listFilterShow : false, sortType : "view" });
        this.getAppList_Axios("view", this.state.searchString);
  
      }
  
  
      listFilterClose(){
        this.setState({listFilterPosition : null, listFilterShow : false});
      }
  
  
      listFilterClick(e){
        this.setState({ listFilterPosition : e.currentTarget, listFilterShow : true});
      }

      moreApp() {
        this.setState({numAppCard : this.state.numAppCard + 4});
      }
    

   
   

  render(){
    const {classes} = this.props;
    // const appIcon = '/img/appIcon.png';
    // const imgKRCI = '/img/krCI.jpg';


    function App_Card(app ){
    
        function goApp(){
          //   window.open(news.url)
          document.location.href = '/App/' + app._id;
    
        }
        return (
          <Grid item xs={12} md={3} style = {{marginBottom: 10}}>
          <Card onClick = {goApp}  style = {{height: "100%",
          // margin : 5,
       // Provide some spacing between cards
       margin: 5,
       // Use flex layout with column direction for components in the card
       // (CardContent and CardActions)
      //  display: "flex",
      //  flexDirection: "column",
   
      //  // Justify the content so that CardContent will always be at the top of the card,
      //  // and CardActions will be at the bottom
      //  justifyContent: "space-between"
       
      
      
      }}>
          <CardActionArea>
          <CardHeader
          avatar={
              <Avatar style={{ height: '35px', width: '35px' }} src={app.icon} />
          }
          // title= {<div style ={{whiteSpace : 'pre-wrap'}}>{app.name}</div>}
          title= {app.name}
          subheader={app.company}
          action={
              <Fragment>
                  <IconButton aria-label="settings" size="large">
                  <img src= {app.companyCI} alt="Logo"   width = "50" height = "23.2"/>
                    {/* <MoreVertIcon onClick = {(e)=>_this.moreMenuClick(e,aboutDay.writerID, aboutDay._id, aboutDay.aboutDayDate)}/> */}
                  </IconButton>
              </Fragment>
              }
          />
          {/* <CardContent> */}
              {/* <div style= {{display: 'flex', flexDirection: "column"}}> */}

              {/* <Typography variant="body2" color="textSecondary" component="p"  style = {{minHeight : '18vw'}}> */}
              {/* <Typography variant="body2" color="textSecondary" component="p" style = {{height : '11vw', overflow: 'hidden'}}> */}
              {/* <Box component="div" whiteSpace="normal"> */}
              {/* <Typography variant="body2" color="textSecondary" component="p" style = {{justifyContent: 'space-between'}}> */}
              {/* <Typography variant="body2" color="textSecondary" component="p" style={{whiteSpace : 'pre-wrap', display: 'flex', flexDirection: 'column',}}> */}
              {/* <Typography variant="body2" color="textSecondary" component="p">
                  {app.description}
              </Typography> */}
              {/* </Box> */}
              {/* <div style= {{position : 'absolute', right: "0px", bottom: "0px"}}> */}
              {/* <div style={{  display: 'flex', justifyContent: 'flex-end', marginLeft: "auto"}} >
                  <img src= {app.companyCI} alt="Logo"   width = "125" height = "58"/>
              </div> */}
              {/* </div>

          </CardContent> */}
          </CardActionArea>
          {/* <CardActions disableSpacing>
          <div style={{  display: 'flex', justifyContent: 'flex-end', marginLeft: "auto"}} >
                  <img src= {app.companyCI} alt="Logo"   width = "125" height = "58"/>
              </div>
          </CardActions> */}

          </Card>
        </Grid>
        );
    
      };
    



  return (
    <div>
  <Header user = {this.state.user}/>
  <br/>
  <Container component="main">

      <div style={{width:'100%'}} align = 'center'  >
          <span className = {classes.HeadLine} >
              <StoreIcon color = "primary" fontSize = "large"/>Store
          </span>
          <span>
          Find App What you Need
          </span>
          <br/>
          <br/>
          <Paper component="form" className={classes.root}>
          {/* <IconButton className={classes.iconButton} aria-label="menu">
              <MenuIcon />
          </IconButton> */}
          <InputBase
              className={classes.input}
              placeholder="Search App"
              value = {this.state.searchString}
              name = "searchString"
              onChange = {this.handleValueChange}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  // Do code here
                  this.searchApp();
                  ev.preventDefault();
                }
              }}
              // inputProps={{ 'aria-label': 'search google maps' }}
          />
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick ={this.searchApp}
            size="large">
              <SearchIcon />
          </IconButton>
          {/* <Divider className={classes.divider} orientation="vertical" />
          <IconButton color="primary" className={classes.iconButton} aria-label="directions">
              <DirectionsIcon />
          </IconButton> */}
          </Paper>
          <br/>
      </div>
          <Grid container spacing={2}>
              
              <Grid item xs={12}>
              <Typography variant="h5" color="inherit" style={{ fontWeight: 600 }}>
                {
                  this.state.searchString_Result === ""
                  ?
                  "All Apps"
                  :
                  "Search : " + this.state.searchString_Result
                }
                  
              </Typography>
              </Grid>
              <Button onClick = {this.listFilterClick}><SortIcon/>Sort</Button>
              <Menu
              id="sortMenu"
              anchorEl={this.state.listFilterPosition}
              keepMounted
              open={this.state.listFilterShow}
              onClose={this.listFilterClose}
              TransitionComponent={Fade}
              >
              <MenuItem name = 'newFilter' onClick={this.filterClick_new}>Newest</MenuItem>
              <MenuItem name='viewFilter' onClick={this.filterClick_view}>Most View</MenuItem>
              </Menu>
              <Grid item container direction="row" >
              {
                this.state.appList === null
                ?

                  <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
                        <CircularProgress />
                      </div>
                    :
                    
                    this.state.appList.length !== 0 
                    ?
                  
                    //this.state.appList.slice(0, this.state.numAppCard).map(app=>{
                    this.state.appList.map(app=>{

                    return App_Card(app);
                    })

                    :
                    <span >
                      No List
                    </span>

                  }
                      </Grid>




              {/*
                this.state.appList ===null
                ?
                null
                :
              this.state.numAppCard >= this.state.appList.length
              ?
              null
              :
              <Button style = {{marginLeft: "auto",display: 'flex', justifyContent: 'flex-end'}} onClick ={this.moreApp}>
                  More...
              </Button>*/
          }



          </Grid>
       
  </Container>
  <Footer/>
  </div>
  );
}

}


export default withStyles(styles)((Store));
