import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';

// const footers = [
//     {
//       title: 'Company',
//       description: ['Team', 'History', 'Contact us', 'Locations'],
//     },
//     {
//       title: 'Features',
//       description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
//     },
//     {
//       title: 'Resources',
//       description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
//     },
//     {
//       title: 'Legal',
//       description: ['Privacy policy', 'Terms of use'],
//     },
//   ];
  

  const useStyles = makeStyles((theme) => ({
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
   
  }));


  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright '}
        <Link color="inherit" href="https://www.krs.co.kr/" target="_blank">
        {/* <Link color="inherit" href="/"> */}
          KR All rights reserved
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        <div>TEL: 82-070-8799-8576 e-mail: ictsolution@mail.krs.co.kr </div>
      </Typography>
    );
  }


  export default function Footer() {
    const classes = useStyles();
  
  
  
    return (
      <React.Fragment>
        {/* Footer */}
        <Container maxWidth="md" component="footer" className={classes.footer}>
          {/* <Grid container spacing={4} justify="space-evenly">
            {footers.map((footer) => (
              <Grid item xs={6} sm={3} key={footer.title}>
                <Typography variant="h6" color="textPrimary" gutterBottom>
                  {footer.title}
                </Typography>
                <ul>
                  {footer.description.map((item) => (
                    <li key={item}>
                      <Link href="#" variant="subtitle1" color="textSecondary">
                        {item}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid> */}
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
        {/* End footer */}
      </React.Fragment>
    );
  }
