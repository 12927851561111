import { List, ListItem, TextField,IconButton, Grid } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles';
import { RemoveCircle } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    TextField:{ 
        width: '90%',
    }
}));

function SubTextFieldObj(props) {
    const classes = useStyles(); 
    const {onChangeHandler, onDeleteHandler, object, objName} = props

    return (
        <div>
            <List  
                sx={{ width: '100%',   outline: "#d3d3d3 solid 1px" }}
                aria-labelledby="nested-list-subheader"
                component = "nav"
                dense ={true}
                disablePadding={true}>   
            {object?   
                Object.keys(object).map((keyName,objIndex)=>{ 
                    if(keyName!=="isTemp"){
                return(     
                                          
                    <ListItem key = {keyName} sx={{paddingLeft:"45px", outlineStyle:'auto'}} >
                        {/* <span>{keyName}</span> */}
                        <TextField 
                            onChange={(e)=>onChangeHandler(e,objName,keyName )}
                            id={keyName}
                            color="primary"
                            name= {keyName}
                            value ={object[keyName]}
                            variant="standard" 
                            label ={keyName}
                            
                            InputLabelProps={{color: "primary"}}
                            className = {classes.TextField}
                        />                                            
                    </ListItem>
            )}}):<></>}          
            </List>  
        </div>
    )
}

export default SubTextFieldObj
