import React, { Component } from 'react';
import Axios from 'axios';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import serverURL from '../serverURL.json';



const styles = theme =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  HeadLine : {
    fontSize: 'calc(1vw + 1.5vh)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace : 'pre-wrap'
  }

});

function checkIMG(strValue){
    if(strValue.includes('data:image/'))
    {
        return true
    }else
    {
        return false
    }
}


class AppInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
        managerID : this.props.managerID,
        appID : this.props.appID,
        isAccess : false,
        status : "",
        name_Temp : "",
        description_Temp : "",
        category_Temp : [],
        hwReq_Temp : {},
        feature_Temp : [],
        advantage_Temp : [], // #IMG# 
        function_Temp : [], // #IMG# 
        release_Temp : [], 
        price_Temp : [] ,

        companyCI_Temp  : "",
        company_Temp  : "",
        icon_Temp : "",

        viewCount : 0,



      }
    this.handleValueChange = this.handleValueChange.bind(this);
    }


    handleValueChange(e){
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    }

    componentWillMount(){
        let params = {};
        let key = 'params';
        let value = {
            appID : this.props.appID
        }
        params[key] = value;
        Axios.get( serverURL.api+'/getAppInfo_Temp', params).then(
        response =>{
            if(response.status === 200)
            {
            const returnResult = response.data;

            if(returnResult.result)
            {
                this.setState({
                  isAccess : returnResult.isAccess,
                  status : returnResult.status,
                  name_Temp : returnResult.name_Temp,
                  description_Temp : returnResult.description_Temp,
                  category_Temp : returnResult.category_Temp, 
                  hwReq_Temp : returnResult.hwReq_Temp, 
                  feature_Temp : returnResult.feature_Temp,
                  advantage_Temp : returnResult.advantage_Temp, // #IMG# 
                  function_Temp : returnResult.function_Temp, // #IMG# 
                  release_Temp : returnResult.release_Temp, 
                  price_Temp : returnResult.price_Temp ,
                  company_Temp : returnResult.company_Temp,
                  companyCI_Temp : returnResult.companyCI_Temp,
                  icon_Temp : returnResult.icon_Temp,
              
                });
             


            }else
            {
                alert(returnResult.alert)
            }
            }else
            {
            alert('engResponseStatusNot200');
            }
        }
        ).catch((error) =>{
        alert('Unexpected Access, Need to refresh page getAppInfo');
        }
        );





    }



   
   

  render(){
    const {classes} = this.props;

    function ShowFeature(strFea){
        return <Container style = {{margin : 10}}>
            <Typography variant="subtitle1" color="textSecondary" component="p">
            - {strFea}
            </Typography>
           
      </Container>

    }

    function ShowAdvantage(strAd){
        return <Container style = {{margin : 10}}>
            
            {
                checkIMG(strAd)
                ?
                <div align = 'center'>
               <Card >
                  <CardMedia
                  component='img' 
                  image= {strAd}
                  title="Advantage Picture"
                  />
                </Card>
                </div>
                :
                <Typography variant="subtitle1" color="textSecondary" component="p">
                - {strAd}
                </Typography>
            }
      </Container>
    }

    function ShowFunction(strFunc){
        return <Container style = {{margin : 10}}>
            
            {
                checkIMG(strFunc)
                ?
                <div align = 'center'>
               <Card >
                  <CardMedia
                  component='img' 
                  image= {strFunc}
                  title="Function Picture"
                  />
                </Card>
                </div>
                :
                <Typography variant="subtitle1" color="textSecondary" component="p">
                - {strFunc}
                </Typography>
            }
      </Container>
    }


    function ShowRelease(dicRel){
        return  <TableRow key={dicRel.version + dicRel.date +dicRel.content}>
        <TableCell component="th" scope="row">
        {dicRel.version}
        </TableCell>
        <TableCell align="right">{dicRel.date}</TableCell>
        <TableCell align="right">{dicRel.content}</TableCell>
      </TableRow>
    }

    

    
    function ShowPrice(dicPrice){
        return  <TableRow key={dicPrice.name + dicPrice.charge}>
        <TableCell component="th" scope="row">
        {dicPrice.name}
        </TableCell>
        <TableCell align="right">{dicPrice.charge}</TableCell>
      </TableRow>
    }

    function ShowCategory(strCat){
      return '#' + strCat + "ㅤ";


    }

  return (
      <div>
    <br/>
    <Container component="main">
   
    <Grid item container direction="row" >
        <Avatar style={{ height: '35px', width: '35px' }} src={this.state.icon_Temp} /> 
        ㅤ
        <Typography variant="h4" color="inherit" style={{ fontWeight: 600 }} color="primary">
        {this.state.name_Temp}
        </Typography>
    </Grid>


      <Container>
      <Typography variant="overline"  component="p">
      {
      this.state.category_Temp
      ?
      
      this.state.category_Temp.map((strCat)=>{

      return ShowCategory(strCat);
      })
      :
      <div>

      </div>
      }
    </Typography>
      <Typography variant="subtitle1" color="textSecondary" component="p">
        {this.state.description_Temp}
    </Typography>
      </Container>
      <br/>
      <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
        Feature
      </Typography>
        {
        this.state.feature_Temp
        ?
        
        this.state.feature_Temp.map(strFea=>{

        return ShowFeature(strFea);
        })
        :
        <div>

        </div>
        }
        <br/>
      <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
        Advantage
      </Typography>
      {
        this.state.advantage_Temp
        ?
        
        this.state.advantage_Temp.map(strAd=>{

        return ShowAdvantage(strAd);
        })
        :
        <div>

        </div>
        }
        <br/>
        <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
        Function
      </Typography>
      {
        this.state.function_Temp
        ?
        
        this.state.function_Temp.map(strFunc=>{

        return ShowFunction(strFunc);
        })
        :
        <div>

        </div>
        }
        <br/>
        <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
        Release
      </Typography>
      {
        this.state.release_Temp
        ?
        <TableContainer component={Paper} style = {{margin : 10}}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow style={{backgroundColor:'lightgrey', color: 'white',}}>
              <TableCell>Version</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Content</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            
            this.state.release_Temp.map(dicRel=>{

                return ShowRelease(dicRel);
                })
            }
          </TableBody>
        </Table>
      </TableContainer>
      
        :
        <div>

        </div>
        }
        <br/>
        <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
        Hardware Requirement
      </Typography>
      {
        this.state.hwReq_Temp
        ?
        <TableContainer component={Paper} style = {{margin : 10}}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow  style={{backgroundColor:'lightgrey', color: 'white',}}>
              <TableCell>Item</TableCell>
              <TableCell>Spec.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
        <TableRow key={"hwReq_OS"}>
          <TableCell component="th" scope="row">
          {"OS"}
          </TableCell>
          <TableCell component="th" scope="row">
          {this.state.hwReq_Temp['os']}
          </TableCell>
        </TableRow>
        <TableRow key={"hwReq_CPU"}>
          <TableCell component="th" scope="row">
          {"CPU"}
          </TableCell>
          <TableCell component="th" scope="row">
          {this.state.hwReq_Temp['cpu']}
          </TableCell>
        </TableRow>
        <TableRow key={"hwReq_Memory"}>
          <TableCell component="th" scope="row">
          {"Memory"}
          </TableCell>
          <TableCell component="th" scope="row">
          {this.state.hwReq_Temp['memory']}
          </TableCell>
        </TableRow>
        <TableRow key={"hwReq_Hdd"}>
          <TableCell component="th" scope="row">
          {"HDD Free Space"}
          </TableCell>
          <TableCell component="th" scope="row">
          {this.state.hwReq_Temp['capacity']}
          </TableCell>
        </TableRow>
        <TableRow key={"hwReq_Display"}>
          <TableCell component="th" scope="row">
          {"Display"}
          </TableCell>
          <TableCell component="th" scope="row">
          {this.state.hwReq_Temp['display']}
          </TableCell>
        </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      
        :
        <div>

        </div>
        }
        <br/>
        {
          this.state.price_Temp
          ?
          this.state.price_Temp.length !==0
          ?
          <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
          Price
         </Typography>
          :
          null
          :
          null
        }
      {
        this.state.price_Temp
        ?
        <TableContainer component={Paper} style = {{margin : 10}}>
        <Table className={classes.table} aria-label="simple table">
          {/* <TableHead>
              <div align = 'center'>
              <Typography variant="subtitle1" color="inherit" >
            Price Policy
        </Typography>
           
              </div>
          
          </TableHead> */}
          <TableBody>
            {
            
            this.state.price_Temp.map(dicPrice=>{

                return ShowPrice(dicPrice);
                })
            }
          </TableBody>
        </Table>
      </TableContainer>
        :
        <div>

        </div>
        }
        <br/>
        <Typography variant="h6" color="inherit" style={{ fontWeight: 600 }}>
            Provider
        </Typography>
        <Container>
        <Card  style = {{margin : 10}}>
            <CardMedia
            component='img' 
            image= {this.state.companyCI_Temp}
            title="Company CI"
            />
              <CardHeader
                // title= {this.state.company}
                subheader={this.state.company_Temp}
              />
              </Card>
        </Container>

    </Container>
    </div>
  );
}

}

export default withStyles(styles)((AppInfo));
