import React from 'react'
import {makeStyles} from '@mui/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography, Box } from '@mui/material';
import Link from '@mui/material/Link';
import Footer from '../../../Footer';

const useStyles = makeStyles((theme) => ({  
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    content:{ 
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      outline: '#d3d3d3 solid 1px',
      padding: '10px',
      outlineStyle: 'auto',
      
    }
  }));
  
function userTerm1() {
    const classes = useStyles;

    return (<>
        <Container component="main" maxWidth="lg">
   
        <CssBaseline/>
        <div className={classes.paper}>
            <Link href="/" variant="h3" underline="hover">
            {/*"SeaTrust"*/}
            </Link>
            <div className = {classes.content}>
            <br/><br/>

                <Typography variant = "h5"> 제 1 장 총칙 </Typography>
                <br/>
                <Typography variant = "h6">제 1조 </Typography>
                <Typography variant = "body1"> 
                본 약관은 한국선급 SeaTrust 인터넷사이트 (이하 "한국선급")가 제공하는 모든 서비스 (이하 "서비스")의 이용조건 및 절차, 이용자와 당 사이트의 권리, 의무 기타 필요한 사항을 규정함을 목적으로 합니다.                
                </Typography>
                <br/>
                <Typography variant = "h6"> 
                제 2 조 
                </Typography>
                <Typography variant = "body1" > 
                본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                </Typography>
                <ol>
                  <li>이용자 : 본 약관에 따라 한국선급이 제공하는 서비스를 받는 자.</li>
                  <li>이용계약 : 서비스 이용과 관련하여 한국선급과 이용자간에 체결하는 계약을 말합니다.</li>
                  <li>가입 : 한국선급이 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료시키는 행위</li>
                  <li>회원 : 한국선급에 회원가입에 필요한 개인정보를 제공하여 회원 등록을 한 자로서 당 사이트의 정보 및 서비스를 이용할 수 있는 자</li>
                  <li>회원 아이디(ID) : 이용자의 식별과 이용자가 서비스 이용을 위하여 이용자가 선정하고 한국선급이 부여하는 문자와 숫자의 조합을 말합니다.</li>
                  <li>비밀번호(PW) : 이용자가 등록회원과 동일인인지 신원을 확인하고 통신상의 자신의 개인정보보호를 위하여 이용자 자신이 선정한 문자와 숫자 등의 조합을 말합니다.</li>
                  <li>탈퇴 : 회원이 이용계약을 종료하는 것을 말합니다.</li>
                  <li>본 약관에서 정의하지 않은 용어는 개별서비스에 대한 별도 약관 및 이용규정에서 정의합니다.</li>
                </ol>
                <br/>
                <Typography variant = "h6"> 
                제 3 조 
                </Typography>
                <Typography variant = "body1" >                 
                이용자는 본 약관 이외에 한국선급이 제공하는 영문약관에 대하여도 동의함으로써 회원이 되며, 회원에게 영문약관이 적용될 사유가 있는 경우에는 영문약관이 본 이용약관에 우선합니다.
                </Typography>
                <br/>
                <Typography variant = "h6"> 
                제 4 조 
                </Typography>
                <Typography variant = "body1" >                 
                이용자는 본 약관 이외에 한국선급이 제공하는 영문약관에 대하여도 동의함으로써 회원이 되며, 회원에게 영문약관이 적용될 사유가 있는 경우에는 영문약관이 본 이용약관에 우선합니다.
                </Typography>
                <ol>
                  <li>한국선급은 본 약관의 내용과 주소지, 관리자의 성명, 개인정보보호 담당자의 성명, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가 알 수 있도록 당 사이트의 초기 서비스화면(전면)에 게시합니다.</li>
                  <li>한국선급은 약관의 규제에 관한 법률 등 관계 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
                  <li>한국선급이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 당 사이트의 초기화면에 그 적용일자 7일 이전부터 공지하며, 적용일에 효력이 발생합니다.</li>
                  <li>한국선급은 이용자가 본 약관 내용에 동의하는 것을 조건으로 이용자에게 서비스를 제공할 것이며, 이용자가 본 약관의 내용에 동의하는 경우, 당 사이트의 서비스 제공 행위 및 이용자의 서비스 사용 행위에는 본 약관이 우선적으로 적용될 것입니다.</li>
                  <li>회원은 개정된 약관에 동의하지 않을 경우 회원 탈퇴를 요청할 수 있으며, 개정된 약관의 효력 발생일로부터 7일 이내에 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 개정된 약관에 동의한 것으로 간주합니다.</li>
                </ol>
                <br/><br/>
                <Typography variant = "h5"> 제 2 장 이용계약의 성립 및 해지 </Typography>
                <br/>
                <Typography variant = "h6">제 5조 </Typography>
                <ol>
                  <li>이용계약은 회원이 본 이용약관 내용에 대한 동의와 이용신청에 대하여 한국선급의 이용승낙으로 성립합니다.</li>
                  <li>본 이용약관에 대한 동의는 이용신청 당시 당 사이트 해당 페이지의 ‘동의함’ 버튼을 누름으로써 의사표시를 합니다.</li>
                </ol>
                <br/>
                <Typography variant = "h6"> 
                제 6 조 
                </Typography>
                <Typography variant = "body1" >                 
                </Typography>
                <ol>
                  <li>회원가입은 이용자가 온라인으로 한국선급에서 제공하는 소정의 가입신청 양식에서 요구하는 사항을 기록하여 가입을 완료하는 것으로 성립됩니다.</li>
                  <li>한국선급은 다음 각 호에 해당하는 회원가입에 대하여는 가입을 취소할 수 있습니다.</li>
                  <ul>
                    <li> 다른 사람의 명의를 사용하여 신청하였을 때
                    </li>
                    <li>회원가입 신청서의 내용을 허위로 기재하였거나 신청하였을 때
                    </li>
                    <li>사회의 안녕 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때</li>
                    <li>다른 사람의 당 사이트 서비스 이용을 방해하거나 그 정보를 도용하는 등의 행위를 하였을 때</li>
                    <li>당 사이트를 이용하여 법령과 본 약관이 금지하는 행위를 하는 경우</li>
                    <li> 기타 한국선급이 정한 회원가입요건이 미비 되었을 때</li>
                  </ul>
                  <li>한국선급은 다음 각 항에 해당하는 경우 그 사유가 해소될 때까지 이용계약 성립을 유보할 수 있습니다.
                    <ul>
                      <li>서비스 관련 설비의 여유가 없는 경우</li>
                      <li>기술상 장애 사유가 있는 경우</li>
                    </ul>
                  </li>
                  <li>회원은 등록사항에 변경이 있는 경우, 즉시 회원정보 수정 등 기타 방법으로 한국선급에게 그 변경사항을 알려야 합니다.</li>
                  <li>회원은 회원가입 이후 당 사이트에서 제공하는 서비스를 제공받을 의사가 없는 등의 사유가 있을 경우에는 언제든지 탈퇴를 할 수 있습니다.</li>
                </ol>
                <br/>
                <Typography variant = "h6"> 
                제 7 조 
                </Typography>
                <Typography variant = "body1" >                 
                </Typography>
                <ol>
                  <li>한국선급은 약관에 정하는 바에 따라 회원 ID를 부여합니다.</li>
                  <li>회원ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 해당 ID를 해지하고 재가입해야 합니다.</li>
                  <li>당 사이트의 회원ID는 이용자 본인의 동의 하에 기타 정보제공 사이트의 이용자ID와 연결될 수 있습니다.</li>
                  <li>회원ID는 다음 각 호에 해당하는 경우에는 회원 또는 회사의 요청으로 변경할 수 있습니다.
                    <ul>
                      <li>회원ID가 이용자의 전화번호 또는 주민등록번호 등으로 등록되어 사생활 침해가 우려되는 경우</li>
                      <li>타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우</li><li>기타 합리적인 사유가 있는 경우</li>
                    </ul>
                  </li>
                  <li>회원ID 및 비밀번호의 관리 책임은 이용자에게 있습니다. 이를 소홀히 관리하여 발생하는 서비스 이용상의 손해 또는 제3자에 의한 부정이용 등에 대한 책임은 회원에게 있으며 한국선급은 그에 대한 책임을 지지 않습니다.</li>
                </ol>
                <br/>
                <Typography variant = "h6"> 
                제 8 조 
                </Typography>
                <Typography variant = "body1" >                 
                </Typography>
                <ol>
                  <li>한국선급의 회원 개인정보 보호는 개인정보보호법 등 관계 법령 및 『한국선급의 개인정보 보호처리방침』이 정하는 바에 따른다.2. 당 사이트의 회원 정보는 다음과 같이 수집, 사용, 관리, 보호됩니다.
                  <ul>
                      <li>개인정보의 수집 : 한국선급은 회원이 당 사이트 서비스 가입시 제공하는 정보를 통하여 회원의 정보를 수집합니다.</li>
                      <li>개인정보의 사용 : 한국선급은 당 사이트 서비스 제공과 관련해서 수집된 회원의 신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 정보통신윤리위원회의 요청이 있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우, 회원이 한국선급에 제공한 개인정보를 스스로 공개한 경우에는 그러하지 않습니다.</li>
                      <li>개인정보의 관리 : 회원은 개인정보의 보호 및 관리를 위하여 서비스의 개인정보관리에서 수시로 회원 개인정보를 수정/삭제할 수 있습니다. 수신되는 정보 중 불필요하다고 생각되는 부분도 변경할 수 있습니다</li>
                      <li>개인정보의 보호 : 회원의 개인정보는 오직 회원만이 열람/수정/삭제 할 수 있고, 이는 전적으로 회원의 ID와 비밀번호에 의해 관리되고 있습니다. 따라서 타인에게 회원 ID와 비밀번호를 알려주어서는 아니 되며, 작업 종료 시에는 반드시 로그아웃하고, 웹 브라우저의 창을 닫아야 합니다.</li>
                    </ul>
                  </li>
                  <li> 회원이 당 사이트에 본 약관에 따라 이용신청을 하는 것은 한국선급이 본 약관에 따라 신청서에 기재된 회원정보를 수집, 이용하는 것에 동의하는 것으로 간주됩니다.</li>
                </ol>
                <Typography variant = "h6"> 
                제 9 조 
                </Typography>
                <Typography variant = "body1" >                 
                </Typography>
                <ol>
                  <li>회원이 당 사이트 서비스 가입 절차를 완료하는 순간부터 회원은 입력한 정보의 비밀을 유지할 책임이 있습니다.</li>
                  <li> ID와 비밀번호 관리 책임은 회원에게 있으며, 회원 ID나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 한국선급에 신고하여야 합니다. 신고를 하지 않음으로 인해 발생하는 모든 책임은 회원 본인에게 있습니다.</li>
                  <li>회원은 당 사이트 서비스의 사용 종료 시 마다 정확히 로그아웃(Log-out)해야 하고, 로그아웃하지 아니하여 제3자가 회원 정보를 도용하는 등의 결과로 인해 발생하는 손해 에 대하여 한국선급은 책임을 부담하지 아니합니다.</li>
                </ol>
                <br/><br/>
                <Typography variant = "h5"> 제 3 장 서비스의 이용 </Typography>
                <br/>
                <Typography variant = "h6">제 10 조 </Typography>
                <ol>
                  <li>회원은 당 사이트 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태, 정전, 한국선급의 관리 범위 외의 서비스 설비 장애 및 기타 불가항력에 의하여 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 한국선급은 관련 책임을 부담하지 아니합니다.</li>
                  <li>한국선급이 정상적인 서비스 제공의 어려움으로 인하여 일시적으로 서비스를 중지하여야 할 경우에는 서비스 중지 1주일 전에 당 사이트에 서비스 중지사유 및 일시를 공지한 후 서비스를 중지할 수 있고, 이 기간 동안 회원이 공지내용을 인지하지 못한 데 대하여 한국선급은 책임을 부담하지 아니합니다. 부득이한 사정이 있을 경우 위 사전 공지기간은 감축되거나 생략될 수 있습니다. 또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신데이터의 손실이 있을 경우에 대하여도 당 사이트는 책임을 부담하지 아니합니다.</li>
                  <li>한국선급의 사정으로 서비스를 영구적으로 중단하여야 할 경우에는 제2항에 의거합니다. 다만, 이 경우 사전 공지기간은 1개월로 합니다.</li>
                  <li>한국선급은 사전 공지 후 서비스를 일시적으로 수정, 변경 및 중단할 수 있고, 이에 대하여 회원 또는 제3자에게 어떠한 책임도 부담하지 아니합니다.</li>
                  <li>한국선급은 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고 없이 일시적으로 서비스를 중단할 수 있고, 새로운 서비스로의 교체 등 한국선급이 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.</li>
                  <li>한국선급은 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만 이 경우 그 사유 및 기간 등을 이용자에게 사전 또는 사후에 공지합니다.</li>
                  <li>한국선급은 한국선급이 통제할 수 없는 사유로 인한 서비스 중단의 경우 (시스템관리자의 고의, 과실 없는 디스크장애, 시스템다운 등)에 사전통지가 불가능하며 타인(PC통신회사, 기간통신사업자 등)의 고의, 과실로 인한 시스템중단 등의 경우에는 통지하지 않을 수 있습니다.</li>
                  <li>한국선급은 서비스를 특정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만 이 경우 그 내용을 공지합니다.</li>
                  <li>한국선급은 회원이 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지하거나 귀하의 동의 없이 이용계약을 해지할 수 있습니다.</li>
                </ol>
                <br/>
                <Typography variant = "h6">제 11 조 </Typography>
                <ol>
                  <li>서비스 이용시간은 한국선급의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.</li>
                  <li>제1항의 이용시간 중 정기점검 등의 필요로 인하여 한국선급이 정한 날 또는 시간은 예외로 합니다.</li>
                </ol>
                <br/>
                <Typography variant = "h6">제 11 조 </Typography>
                <ol>
                  <li>서비스 이용시간은 한국선급의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.</li>
                  <li>제1항의 이용시간 중 정기점검 등의 필요로 인하여 한국선급이 정한 날 또는 시간은 예외로 합니다.</li>
                </ol>
                <Typography variant = "h6">제 12 조 </Typography>
                <ol>
                  <li>한국선급은 서비스를 운영함에 있어서 회원에게 각종 정보를 당 사이트에 게재하는 방법, 전자우편이나 서신우편 발송 등으로 제공할 수 있습니다.</li>
                  <li>한국선급은 서비스에 적절하다고 판단되거나 공익성이 있는 홍보물을 게재할 수 있습니다.</li>
                </ol>
                <br/>
                <Typography variant = "h6">제 13 조 </Typography>
                <ol>
                  <li>회원이 게시한 게시물의 내용에 대한 권리는 회원에게 있습니다.</li>
                  <li>한국선급은 게시된 내용을 사전 통지 없이 편집, 이동할 수 있는 권리를 보유하며, 다음의 경우 사전 통지 없이 삭제할 수 있습니다.
                    <ul>
                    <li>본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우</li> 
                    <li>다른 회원 또는 제3자를 비방하거나 중상 모략으로 명예를 손상시키는 내용인 경우</li>
                    <li>공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우</li>
                    <li>불법복제 또는 해킹을 조장하는 내용인 경우</li>
                    <li>영리를 목적으로 하는 광고일 경우</li>
                    <li>범죄와 결부된다고 객관적으로 인정되는 내용일 경우</li>
                    <li>다른 이용자 또는 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
                    <li>기타 관계 법령에 위배되는 경우</li>
                    </ul>
                  </li>
                  <li>회원의 게시물이 타인의 저작권을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 회원이 부담하여야 합니다.</li>
                  <li>한국선급은 게시물에 관련된 세부 이용지침을 별도로 정하여 시행할 수 있고, 회원은 그 지침에 따라 각종 게시물을 등록 또는 삭제하여야 합니다.</li>
                </ol>
                <br/><br/>
                <Typography variant = "h5"> 제 4 장 의무 및 책임 </Typography>
                <br/>
                <Typography variant = "h6">제 15 조 </Typography>
                <ol>
                  <li>한국선급은 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 지속적이고 안정적으로 서비스를 제공하기 위해 노력할 의무가 있습니다.</li>
                  <li>한국선급은 회원의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 다만, 관계법령에 의해 국가기관의 요구나 정보통신윤리위원회의 요청 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 아니합니다.</li>
                  <li>한국선급은 이용자가 안전하게 당 사이트 서비스를 이용할 수 있도록 이용자의 개인정보보호를 위한 보안시스템을 갖추어야 합니다.</li>
                  <li> 한국선급은 회원의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다.</li>
                </ol>
                <br/>
                <Typography variant = "h6">제 16 조 </Typography>
                <ol>
                  <li>회원 가입 시에 요구되는 정보는 사실에 근거하여 기입하여야 합니다. 또한 이미 제공된 회원에 대한 정보가 정확한 정보가 되도록 유지, 갱신하여야 하고, 회원은 자신의 ID 및 비밀번호를 제3자가 이용하게 해서는 안됩니다.</li>
                  <li>회원은 한국선급의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.</li>
                  <li>회원은 당 사이트 서비스를 이용하여 얻은 정보를 한국선급의 사전승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.</li>
                  <li> 회원은 당 사이트 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.
                    <ul>
                      <li>다른 회원의 비밀번호와 ID를 도용하여 부정 사용하는 행위                                                                                                         </li>
                      <li>저속, 음란, 모욕적, 위협적이거나 타인의 Privacy를 침해할 수 있는 내용을 전송, 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위                           </li>
                      <li>서비스를 통하여 전송된 내용의 출처를 위장하는 행위                                                                                                              </li>
                      <li>법률, 계약에 의해 이용할 수 없는 내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위                                                                 </li>
                      <li>타인의 특허, 상표, 영업비밀, 저작권, 기타 지식재산권을 침해하는 내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위                                  </li>
                      <li>한국선급의 승인을 받지 아니한 광고, 판촉물, 스팸메일, 행운의 편지, 피라미드 조직 기타 다른 형태의 권유를 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위</li>
                      <li>다른 사용자의 개인정보를 수집, 저장, 공개하는 행위                                                                                                              </li>
                      <li>범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위                                                                                                          </li>
                      <li>선량한 풍속, 기타 사회질서를 해하는 행위                                                                                                                        </li>
                      <li> 타인의 명예를 훼손하거나 모욕하는 행위                                                                                                                         </li>
                      <li> 타인의 지적재산권 등의 권리를 침해하는 행위                                                                                                                    </li>
                      <li> 해킹행위 또는 컴퓨터바이러스의 유포행위                                                                                                                        </li>
                      <li> 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위                                                                                     </li>
                      <li> 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위                                                                                              </li>
                      <li> 당 사이트에 게시된 정보의 변경                                                                                                                                 </li>
                    </ul>
                  </li>
                </ol>
           
                <br/><br/>
                <Typography variant = "h5"> 제 5 장 기타 </Typography>
                <br/>
                <Typography variant = "h6"> 
                제 17 조 
                </Typography>
                <Typography variant = "body1" >                 
                </Typography>
                <ol>
                  <li> 한국선급이 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는 한국선급에 소유권이 있습니다.                                                                                           </li>
                  <li> 회원은 한국선급이 명시적으로 승인한 경우를 제외하고는 전항의 소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스, 담보권 설정 행위, 상업적 이용 행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.</li>
                </ol>        
                <br/>    
                <Typography variant = "h6"> 
                제 18 조 
                </Typography>
                <Typography variant = "body1" >     
                회원이 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.            
                </Typography>
                <br/>    
                <Typography variant = "h6"> 
                제 19 조 
                </Typography>
                <Typography variant = "body1" >     
                한국선급은 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 한국선급이 고의로 행한 범죄행위를 제외하고는 이에 대하여 책임을 부담하지 아니합니다.
                </Typography>
                <br/>
                <Typography variant = "h6"> 
                제 20 조 
                </Typography>
                <Typography variant = "body1" >     
                
                </Typography>
                <ol>
                  <li> 한국선급은 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.                                                                                                                                                                                                                              </li>
                  <li> 한국선급은 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.                                                                                                                                                                                                                                          </li>
                  <li> 한국선급은 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.                                                                                                                                                                                                                                                        </li>
                  <li> 한국선급은 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.                                                                                                                                                                                                         </li>
                  <li> 한국선급은 서비스에 표출된 어떠한 의견이나 정보에 대해 확신이나 대표할 의무가 없고, 회원이나 제3자에 의해 표출된 의견을 승인하거나 반대하거나 수정하지 않습니다. 한국선급은 어떠한 경우라도 회원 서비스에 담긴 정보에 의존해 얻은 이득이나 입은 손해에 대해 책임이 없습니다.                                                                                          </li>
                  <li> 한국선급은 회원간 또는 회원과 제3자간에 서비스를 매개로 하여 물품거래 혹은 금전적 거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.                                                                                                                                                </li>
                  <li> 한국선급은 회원이 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해 및 회원이 본 서비스에 게재한 정보, 자료, 사실의 신뢰도 등 내용에 관하여는 책임을 지지 않습니다.                                                                                                                                                                           </li>
                  <li> 한국선급은 서비스 이용과 관련하여 회원에게 발생한 손해 중 회원의 고의, 과실에 의한 손해에 대하여 책임을 부담하지 아니합니다.                                                                                                                                                                                                                                          </li>
                  <li> 한국선급은 한국선급이 제공한 서비스가 아닌 가입자 또는 기타 유관기관이 제공하는 서비스의 내용상의 정확성, 완전성을 보장하지 않습니다. 따라서 한국선급은 회원이 위 내용의 정확성, 완전성을 신뢰하여 입게 되는손해에 대하여 책임을 부담하지 아니합니다. 또한 한국선급은 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는 정신적 피해에 대하여도 책임을 지지 않습니다.</li>
                </ol>
                <br/>
                <Typography variant = "h6"> 
                제 21 조 
                </Typography>
                <Typography variant = "body1" >            
                </Typography>
                <ol>
                <li> 회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.         </li>
<li> 제1항의 노력에도 불구하고 분쟁이 해결되지 않을 경우, 부산지방법원 서부지원을 관할 법원에 소를 제기할 수 있습니다.</li>

                </ol>
                </div>
        </div>

    <Box mt={5}>
      <Footer />
    </Box>
    </Container></>
    )
}

export default userTerm1
