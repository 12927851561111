import React, { Component } from 'react';
import Axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Footer from './Footer';
import Header from './Header'
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { withCookies } from 'react-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';


import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';


import './Messenger.css';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

import EditIcon from '@mui/icons-material/Edit';
import FormControlLabel from '@mui/material/FormControlLabel';

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import serverURL from '../serverURL.json';




const styles = theme =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  HeadLine : {
    fontSize: 'calc(1vw + 1.5vh)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace : 'pre-wrap'
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto'
  },

  container: {
    bottom: 0
    // position: "fixed" // remove this so we can apply flex design
  },
  bubbleContainer: {
    width: "100%",
    display: "flex" //new added flex so we can put div at left and right side
    //check style.css for left and right classnaeme based on your data
  },
  bubble: {
    border: "0.5px solid black",
    borderRadius: "10px",
    margin: "5px",
    padding: "10px",
    display: "inline-block"
  },
  labelPlacementStart: {
    display: "flex",
    justifyContent: 'space-between',
  },
  input: {
    width: 300,
  },

});

const messagesEndRef = React.createRef()

class Messenger extends Component {
  
  constructor(props) {

    super(props);
    const { cookies } = props;
    this.state = {
        user : cookies.get('user') ? cookies.get('user') : {_id: "", refToken :"", accToken:"", auth: false} ,

        messengerName : "",
        messengerID : "",
        messengerList : [],

        conversation: null,

        message : "",

        imgBase64 : "",

        dlgEditName : false,
        strEditName : ""

      }
    this.handleValueChange = this.handleValueChange.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.sendPhoto = this.sendPhoto.bind(this);

    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.handleShowIMGDialog = this.handleShowIMGDialog.bind(this);
    this.closeShowIMG = this.closeShowIMG.bind(this);
    this.editName = this.editName.bind(this);
    this.closeEditName  = this.closeEditName.bind(this);
    this.editNameChange = this.editNameChange.bind(this)
    }
    editNameChange(e){
      let _editName = e.target.value;
      this.setState({strEditName : _editName});

    }

    closeEditName(e){
        if(e.currentTarget.name === 'apply'){

          let params = new URLSearchParams();
          params.append('userID', this.state.user._id);
          params.append('refToken', this.state.user.refToken);
          params.append('accToken', this.state.user.accToken);
          params.append('messengerID', this.state.messengerID);
          params.append('editName', this.state.strEditName);
          Axios.post(serverURL.api + '/editMessengerName', params).then(
              response =>{
              if(response.status === 200)
              {
                  const returnResult = response.data;
      
                  if(returnResult.result)
                  {
                    window.location.reload(true);
                  }else
                  {
                      alert(returnResult.alert)
                  }
              }else
              {
                  alert('engResponseStatusNot200');
              }
              }
          ).catch((error) =>{
                  alert('Unexpected Access, Need to refresh page addAppViewCount');
  
              }
          );

        }
      this.setState({dlgEditName : false})


    }

    editName(){
      this.setState({dlgEditName : true})
    }

    scrollToBottom = () => {
      // alert(this.messagesEndRef)
      if(messagesEndRef.current)
      {
        
        // messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
        messagesEndRef.current.scrollIntoView({ block: "end", inline: "nearest"})

      }
    }

    handleShowIMGDialog(_imgBase64){
      this.setState({dlgShowIMG : true, imgBase64 :_imgBase64 })
    }
    

    sendMessage(){
      if(this.state.message !== "")
      {
        let params = new URLSearchParams();
        params.append('userID', this.state.user._id);
        params.append('refToken', this.state.user.refToken);
        params.append('accToken', this.state.user.accToken);
        params.append('messengerID', this.state.messengerID);
        params.append('message', this.state.message);
        Axios.post(serverURL.api + '/sendMessage', params).then(
            response =>{
            if(response.status === 200)
            {
                const returnResult = response.data;
    
                if(returnResult.result)
                {
                  this.setConversation(this.state.messengerID)
                  this.setState({message : ""})
                }else
                {
                    alert(returnResult.alert)
                }
            }else
            {
                alert('engResponseStatusNot200');
            }
            }
        ).catch((error) =>{
                alert('Unexpected Access, Need to refresh page addAppViewCount');

            }
        );
      }
    }

    sendPhoto(event){
      var file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // const format_All = ((file.type).split("/"))
      // const foramtName = format_All[format_All.length-1];

      reader.onloadend = function(e){
        let picBase64 = [reader.result][0];

        let params = new URLSearchParams();
        params.append('userID', this.state.user._id);
        params.append('refToken', this.state.user.refToken);
        params.append('accToken', this.state.user.accToken);
        params.append('messengerID', this.state.messengerID);
        params.append('message', picBase64);
        Axios.post(serverURL.api + '/sendMessage', params).then(
          response =>{
          if(response.status === 200)
          {
              const returnResult = response.data;
  
              if(returnResult.result)
              {
                this.setConversation(this.state.messengerID)
                this.setState({message : ""})
              }else
              {
                  alert(returnResult.alert)
              }
          }else
          {
              alert('engResponseStatusNot200');
          }
          }
      ).catch((error) =>{
              alert('Unexpected Access, Need to refresh page addAppViewCount');

          }
      );
       




      }.bind(this)

    }


    handleValueChange(e){
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    }


    componentWillMount(){

            let params = {};
            let key = 'params';
            let value = {
                userID : this.state.user._id,
                refToken : this.state.user.refToken,
                accToken : this.state.user.accToken,
            }
            params[key] = value;
            Axios.get(serverURL.api + '/getMessengerList', params).then(
                response =>{
                    if(response.status === 200)
                    {
                    const returnResult = response.data;

                    if(returnResult.result)
                    {
                        const _messengerList = returnResult.messengerList;
                        this.setState({messengerList : _messengerList});
                        if(_messengerList.length !==0)
                        {
                            this.setState({messengerName : _messengerList[0]['name'],  messengerID : _messengerList[0]['messengerID']});
                            this.setConversation(_messengerList[0]['messengerID'])
                        }else
                        {
                          this.setState({conversation : []});
                        }
                    }else
                    {
                        alert(returnResult.alert)
                    }
                    }else
                    {
                    alert('engResponseStatusNot200');
                    }
                }
            ).catch((error) =>{
            alert('Unexpected Access, Need to refresh page getAppList');
            });


    }

    componentDidMount(){
      // this.scrollToBottom()
      setInterval(
        this.setConversation(this.state.messengerID)
      ,3000);

    }
    getMessengerContent(_messengerName, _messengerID){
        this.setState({messengerName : _messengerName, 'left': false, messengerID :_messengerID });
        this.setConversation(_messengerID)
    }

    setConversation(_messengerID){
      if(_messengerID !=="")
      {
        let params = {};
        let key = 'params';
        let value = {
            userID : this.state.user._id,
            refToken : this.state.user.refToken,
            accToken : this.state.user.accToken,
            messengerID : _messengerID
        }
        params[key] = value;
        Axios.get(serverURL.api + '/getConversation', params).then(
            response =>{
                if(response.status === 200)
                {
                const returnResult = response.data;

                if(returnResult.result)
                {
                    const _conversation = returnResult.conversation;
                    this.setState({conversation : _conversation});
                    this.scrollToBottom()
                }else
                {
                    alert(returnResult.alert)
                }
                }else
                {
                alert('engResponseStatusNot200');
                }
            }
        ).catch((error) =>{
        alert('Unexpected Access, Need to refresh page getAppList');
        });



      }
        
    }
    closeShowIMG(){
      this.setState({dlgShowIMG : false})
    }
   

  render(){
    const {classes} = this.props;

    const ShowEditNameDialog = (
      <Dialog
      open={this.state.dlgEditName}
      onClose={this.closeEditName}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Edit Name</DialogTitle>
    <DialogContent>
    <FormControlLabel
        labelPlacement="start"
        classes = {{
            labelPlacementStart : classes.labelPlacementStart
        }}
        control={
            <TextField
            className={classes.input}
            value= {this.state.strEditName}
            margin="dense"
            onChange={this.editNameChange }

        />
        }
        // label="Name"
    />
    </DialogContent>

     <DialogActions>
     <Button name ='apply' onClick={this.closeEditName} color="primary" >
          Apply
      </Button> 
      <Button name ='close' onClick={this.closeEditName} color="primary" >
          Close
      </Button>
      </DialogActions>

  </Dialog>
  );


    const ShowIMGDialog = (
      <Dialog
      open={this.state.dlgShowIMG}
      onClose={this.closeShowIMG}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
  >
  <img src={this.state.imgBase64} alt="" />

     <DialogActions>
           
      <Button name ='close' onClick={this.closeShowIMG} color="primary" >
          Close
      </Button>
      </DialogActions>

  </Dialog>
  );


    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        this.setState({ ...this.state, [anchor]: open });
      };
    


      const list = (anchor) => (
        <div
          className={clsx(styles.list, {
            [styles.fullList]: anchor === 'top' || anchor === 'bottom',
          })}
          role="presentation"
        >
        
          <List>
          <ListItem key="List">
              <ListItemIcon><SpeakerNotesIcon/></ListItemIcon>
              <ListItemText primary={"Messenger List"} />
            </ListItem>
            {
              this.state.messengerList
              ?
              this.state.messengerList.length ===0
              ?
              <div style = {{ display: 'flex',  alignItems: 'center', justifyContent: 'center'}}>
                No List
              </div>
              :
              this.state.messengerList.map(c=>{
                return <ListItem button onClick = {() => this.getMessengerContent(c.name, c.messengerID)}>
                <ListItemText primary={c.name} />
              </ListItem>
                })
            :
            null
            }

         


          </List>
          
        </div>
      );

      function Conv_Item(conv, _userID, _this ){

        let date = "";
        let getDate = (new Date(parseInt((conv._id).substring(0, 8), 16) * 1000));
        // date  =  moment(getDate).format('YYYY-MM-DD');
        date  = moment(conv._id).format('YYYY-MM-DD HH:mm:ss');
    
        // let userName = "";
        // let params = {};
        // let key = 'params';
        // let value = {
        //     userID : conv.userID
        // }
        // params[key] = value;
        // Axios.get('http://localhost:5000/api' + '/getUserName', params).then(
        //     response =>{
        //         if(response.status === 200)
        //         {
        //         const returnResult = response.data;

        //         if(returnResult.result)
        //         {
        //           userName = returnResult.name;
        //           console.log(userName)
        //         }else
        //         {
        //             // alert(returnResult.alert)
        //         }
        //         }else
        //         {
        //         // alert('engResponseStatusNot200');
        //         }
        //     }
        // ).catch((error) =>{
        // // alert('Unexpected Access, Need to refresh page getAppList');
        // });
        
        
        // function goApp(){
        //   document.location.href = '/App/' + app._id;
    
        // }
        
// return  <div className={`${classes.bubbleContainer}`} key={conv._id}>
// <div key={conv._id} className={classes.bubble}>
//   <div className={classes.button}>{conv.message}</div>
// </div>
// </div>
    //     return <ListItem key={conv._id}>
    //     <Grid container>
    //         <Grid item xs={12}>
    //             <ListItemText align="right" primary="Cool. i am good, let's catch up!"></ListItemText>
    //         </Grid>
    //     </Grid>
    // </ListItem>

    let bubbleClass = 'me';
    let bubbleDirection = 'bubble-direction-reverse';

    if(conv.userID !== _userID){
      bubbleClass = 'you';
      bubbleDirection = "";
    }

return <ListItem key={conv._id}>
<Grid container>
    <Grid item xs={12}>
    <div className={`bubble-container ${bubbleDirection}`} >
          {conv.name}
    </div>
    <div className={`bubble-container ${bubbleDirection}`} key={conv._id}>
          {
            (conv.profilePic) === 'data:image/jpg;base64,'
            ?
            <Avatar/>
            :
            <img className={`img-circle`} src={conv.profilePic} />
          }
          {/* <img className={`img-circle`} src={(conv.profilePic).includes('data:image') ? "" : conv.profilePic} /> */}
          {/* <div className={`bubble ${bubbleClass}`}>{conv.message}</div> */}
          {
            (conv.message).includes('data:image')
            ?
           <div className={`bubble ${bubbleClass}`}>
           <img src={conv.message} alt=""  width="100"  onClick={()=>_this.handleShowIMGDialog(conv.message)}/>
           </div>
            :
           <div className={`bubble ${bubbleClass}`}>{conv.message}</div>

          }
          
        </div>
        <div className={`bubble-container ${bubbleDirection}`} >
          {date}
    </div>
    </Grid>
</Grid>
</ListItem>


    
      };


  return (
    <div>
  <Header user = {this.state.user}/>
  <br/>
  <Container component="main">
  <div style={{width:'100%'}} align = 'center'  >
          <span className = {classes.HeadLine} >
              <QuestionAnswerIcon color = "primary" fontSize = "large"/>Messenger
          </span>
          <span >
          You can chat with users here.
          </span>
          <br/>
          <br/>
      </div>
  <IconButton color="primary" aria-label="Open drawer" size="large" onClick ={toggleDrawer('left', true)} >
      <MenuIcon />
      <Drawer anchor= 'left' open={this.state['left']} onClose={toggleDrawer('left', false)}>
      {list('left')}
      </Drawer>
  </IconButton>
  {
      this.state.conversation === null
      ?
      this.state.messengerID ===""
      ?
      <span >
      {"No List"}
      </span>
      :
      null
      :
  <span >
      {this.state.messengerName}
  </span>
}
  {
      this.state.conversation === null
      ?
      null
      :
      this.state.messengerID ===""
      ?
      null
      :
  <IconButton onClick = {this.editName} size="large"><EditIcon/></IconButton>
}
  {
      this.state.conversation === null
      ?
      <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center'}}>
          <CircularProgress />
      </div>
      :
    //   this.state.conversation.length ===0
    //   ?
    //  "No Conversation"
    //   :
      <List className={classes.messageArea}>
        <div>
          {
              this.state.conversation.map(conv=>{
                  return Conv_Item(conv, this.state.user._id, this);
                  })


          }
    <div ref={messagesEndRef} />
  </div>
          </List>
  }
  
  {
    this.state.conversation === null
    ?
    null
    :
    this.state.messengerID ===""
    ?
    null
    :
    <Grid container spacing={2}>
    <Grid item xs={9} sm={10} md={11}>
      <TextField id="message" name = "message" variant="outlined" value = {this.state.message} fullWidth onChange={this.handleValueChange}/>
    </Grid>
    <Grid item xs={3} sm={2} md ={1}>
    <div style ={{display : 'flex', alignItems : 'center', justifyContent : ' center', height : '100%'}}>

    <Button onClick ={this.sendMessage}> 
        <SendIcon color="primary"/>
      </Button>
      {/* <Button > */}
      
      <Button
          component="label"
      
      >
        <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            style={{display: 'none'}}
            onChange={this.sendPhoto}
            /> 
          <InsertPhotoIcon/>
      </Button>
      </div>
    </Grid>
  </Grid>
  }
  

  {ShowEditNameDialog}
  {ShowIMGDialog}
  
  
  
  </Container>
  <Footer/>
  </div>
  );
}

}

export default withStyles(styles)(withCookies(Messenger));
